

export class Storage {
  storage = window.localStorage;
  constructor() { }

  set(key: string, value: string) {
    this.storage.setItem(key, value);
  }

  get(key: string): string | null {
    return this.storage.getItem(key);
  }
  remove(key: string) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }
}
const storeService = new Storage();

export default storeService