import authStore from './modules/authStore';
import appStore from './modules/appStore';
import permissionStore from './modules/permissionStore'
const modules = {
    authStore,
    appStore,
    permissionStore,
};

export default modules;


