import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "ricent-viewer-container" }
const _hoisted_2 = { ref: "imgs" }
const _hoisted_3 = ["data-uniqueId", "alt", "data-index"]
const _hoisted_4 = {
  ref: "remove",
  class: "viewer-remove"
}
const _hoisted_5 = {
  ref: "download",
  class: "viewer-download"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_CloudDownloadOutlined = _resolveComponent("CloudDownloadOutlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (it, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: it.id
        }, [
          _createElementVNode("img", {
            "data-uniqueId": it.uniqueId,
            alt: _ctx.getAlt(it),
            "data-index": index
          }, null, 8, _hoisted_3)
        ]))
      }), 128))
    ], 512),
    _createElementVNode("li", _hoisted_4, [
      _createVNode(_component_DeleteOutlined)
    ], 512),
    _createElementVNode("li", _hoisted_5, [
      _createVNode(_component_CloudDownloadOutlined)
    ], 512)
  ]))
}