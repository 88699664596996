
import { Modal } from "ant-design-vue";
import { LogoutOutlined, CaretDownOutlined } from "@ant-design/icons-vue";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { authService } from "@/auth/authService";
@Options({
  components: {
    LogoutOutlined,
    CaretDownOutlined,
  },
})
export default class AvatarDropdown extends Vue {
  @Prop()
  public currentUser!: any;
  @Prop()
  public menu!: any;

  public orgName: string = "";

  public get firstName() {
    if (!this.currentUser) return "";
    return this.currentUser.name.substr(0, 1);
  }

  public get orgs() {
    return this.$auth.orgs;
  }

  setOrgName() {
    let org = authService.getCurrentOrg();
    if (!org) return "";
    this.orgName = org.name || "";
  }

  created() {
    this.setOrgName();
  }

  @Watch("$route")
  listenRouter(newer: any, older: any) {
    this.setOrgName();
  }

  swichOrg(item: any, evt: any) {
    let me = this;
    if (
      !this.$route.matched.some((_) => _.path.startsWith("/organize/:orgId")) ||
      item.id == this.$route.params.orgId
    ) {
      return;
    }
    let ls: any = this.$route.path.split("/").filter((_) => !!_);
    ls[1] = item.id;
    delete item.meuns;
    this.$router.push({
      path: `/organize/${item.id}/home`,
    });
  }

  public handleLogout() {
  
    Modal.confirm({
      title: this.$t("layouts.usermenu.dialog.title"),
      content: this.$t("layouts.usermenu.dialog.content"),
      onOk: () => {
        this.$auth.clear();
        this.$router.push({ name: "login" });
      },
      onCancel() {},
    });
  }
}
