
import { Options, Vue } from "vue-class-component";
import RightContent from "../components/GlobalHeader/RightContent.vue";
import {
  HeartOutlined,
  SmileOutlined,
  LoadingOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DownOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons-vue";
import RcBreadcrumb from "@/components/RcBreadcrumb/RcBreadcrumb.vue";
import { Ricent } from "@/core/ricent";
import { Watch } from "vue-property-decorator";
import { h } from "vue";
import { ricentBus } from "@/core/bus";
import appConfig from "@/config/defaultSettings";
import { OGRCHANGE, OGRENTERPRIESES } from "@/core/globalEvent";
declare var window: any;
import szcRouterPlus from "../router/czcRouterPlus";

@Options({
  components: {
    RightContent,
    HeartOutlined,
    SmileOutlined,
    RcBreadcrumb,
    LoadingOutlined,
    ArrowRightOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    DownOutlined,
  },
})
export default class BasicLayout extends Vue {
  // 获取enterprises信息
  public logo: string = "../assets/img/logo.png";
  public collapsed: boolean = false;
  isLoading: boolean = false;
  org: any = null;
  //成则城组织下的企业
  enterprises: any[] = [];
  get enterprise() {
    return this.$auth.enterprise;
  }
  public get appName() {
    return Ricent.appName;
  }
  events: any[] = [];
  indicator: any = null;
  // public

  _selectedKeys: string[] | null = null;
  get selectedKeys() {
    // if (!this._selectedKeys) {
    //   return this.getNodekeys();
    // }
    return this._selectedKeys;
  }
  set selectedKeys(value) {
    this._selectedKeys = value;
  }

  get fontUrl() {
    return appConfig.iconfontUrl;
  }

  _openKeys: string[] | null = null;
  set openKeys(value) {
    this._openKeys = value;
  }
  get openKeys() {
    return this._openKeys;
  }

  goAdmin() {
    let targetUri = process.env.VUE_APP_PC_V4;
    if (targetUri) {
      let token = this.$auth?.tokenInfo?.token! || "";
      let host = encodeURIComponent(
        window.location.protocol + "//" + window.location.host
      );
      let path = encodeURIComponent(`/organize/${this.org.id}/home`);
      window.location.href = `${process.env.VUE_APP_PC_V4}?sassToken=${token}&host=${host}&path=${path}`;
    }
  }

  unmounted() {
    this.events.forEach((fn) => {
      fn();
    });
  }

  enterpriseChange(item: any) {
    this.$auth.enterprise = item;
    szcRouterPlus.generator(item);
    if (this.org) {
      this.$router.push({
        path: `/organize/${this.org.id}/home`,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }

  mounted() {}

  created() {
    this.selectedKeys = this.getNodekeys();
    this.openKeys = this.getNodekeys();
    let me = this;
    this.org = this.getOrg();
    this.events = [
      ricentBus.on(
        "micro:app:created",
        () => {
          me.isLoading = true;
        },
        this
      ),
      ricentBus.on(
        "micro:app:mounted",
        () => {
          me.isLoading = false;
        },
        this
      ),
      ricentBus.on(
        OGRCHANGE,
        (org: any) => {
          me.org = org;
          me.enterprises = [];
          me.$auth.enterprise = null;
        },
        this
      ),
      ricentBus.on(
        OGRENTERPRIESES,
        (enterprises: any) => {
          if (enterprises.length) {
            me.enterprises = enterprises;
            if (enterprises && enterprises.length) {
              if (!me.$auth.enterprise) {
                me.$auth.enterprise = enterprises[0];
              } else {
                let find: any = enterprises.find(
                  (_: any) => _.id == me.$auth.enterprise.id
                );
                me.$auth.enterprise = find;
              }
            } else {
              me.$auth.enterprise = null;
            }
          }
        },
        this
      ),
    ];
    this.indicator = h(LoadingOutlined, {
      style: {
        fontSize: "36px",
      },
      spin: true,
    });
  }

  getNodekeys() {
    let it = this.$route.matched
      .filter((_) => {
        return _.path != "/home";
      })
      .map((_) => _.meta.key as string);
    return it;
  }

  @Watch("$route")
  listenRouter(newer: any, older: any) {
    this.selectedKeys = this.getNodekeys();
    this.openKeys = this.getNodekeys();
  }

  getOrg() {
    let orgId: any = this.$route.params.orgId;
    let org = this.$auth.getCurrentOrg(orgId);
    return org;
  }

  getOrgName(item: any) {
    if (item.name == "home") {
      if (this.$auth.enterprise) {
        return this.$auth.enterprise.name;
      }
      let org = this.getOrg();
      if (org != null) {
        let name = org.name;
        return name;
      }
    }
    return item.meta.title;
  }

  meunClick(item: any) {
    this.$router.push({
      name: item.name,
    });
  }

  public get menuData() {
    let routers = this.$store.getters["permissionStore/getAddRouters"] || [];
    let meuns: any[] = [];
    this.meunItems(routers, meuns);
    return meuns;
  }

  meunItemsChild(cp: any) {
    if (cp.children) {
      cp.children.forEach((_: any) => {
        this.meunItemsChild(_);
      });
      cp.children = cp.children.filter((_: any) => {
        return _.meta && _.meta.isMeun;
      });
    }
  }

  public meunItems(routers: any[], meuns: any = []) {
    routers.forEach((_) => {
      if (_.meta && _.meta.isMeun) {
        let cp = Ricent.copy(_, true);
        this.meunItemsChild(cp);
        meuns.push(cp);
      } else {
        if (_.children) {
          this.meunItems(_.children, meuns);
        }
      }
    });
  }

  public get breadcrumb() {
    let breadcrumbs = this.$router.currentRoute.value.matched
      .concat()
      .map((item) => {
        return {
          path: item.path,
          breadcrumbName: item.meta.title || "",
        };
      })
      .filter((_) => {
        return _.breadcrumbName;
      });
    return breadcrumbs;
  }

  public proConfig = {
    layout: "side", //'side' | 'top' | 'mix'
    primaryColor: "#1890ff",
    contentWidth: "Fluid", //
    fixedHeader: false,
    fixSiderbar: false,
    splitMenus: true,
    navTheme: "dark",
    headerTheme: "dark",
  };
}
