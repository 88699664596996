import { Observable, Subscription } from "rxjs";
import eventPlus, { ricentBus } from "./bus";


export const TASK_QUEUEN_START = "task_queuen_start";
export const TASK_QUEUEN_COMPLETE = "task_queuen_complete";

export const TASK_START = "task_start";
export const TASK_COMPLETE = "task_complete";

export class Task<T>{

    status: "awaiting" | "running" | "done" | "error" = "awaiting";

    state?: T;

    runner!: () => Observable<T>

    errorStopTasks: boolean = false;

    public context?: Tasks<T>

    successCallback?: (data: T, context?: Tasks<T>) => Promise<boolean>;
    progress?: (data: { loaded: any, total: any, percent: any }) => void;
    errorCallback?: (error: any) => void;


    constructor(public id: string) {

    }
}


export class Tasks<T>{

    isRuning: boolean = false;

    queuen: Task<T>[] = [];


    get enableTask() {
        return this.queuen.filter(_ => _.status == "awaiting" && _.runner)
    }

    addTask(
        task: Task<T>,
        position?: number,
    ) {
        task.status = "awaiting";
        if (!position) {
            if (position === 0) {
                this.queuen.unshift(task);
            } else {
                this.queuen.push(task);
            }
        } else {
            this.queuen.splice(position, 0, task);
        }
    }

    removeTask(task: Task<T>) {
        let fd = this.queuen.findIndex(_ => _.id == task.id);
        if (fd > -1) {
            this.queuen.splice(fd);
        }
    }


    run(innerRuning: boolean = false, popTip?: boolean) {
        //队列已经在运行了
        if (!innerRuning && this.isRuning) {
            return;
        }
        let queuen = this.enableTask;
        let complete = () => {
            this.isRuning = false;
            ricentBus.emit(TASK_QUEUEN_COMPLETE, {
                context: this,
                popTip: popTip
            });
        }
        if (!queuen.length) {
            complete();
            return
        }
        let me = this;
        //队列开始
        this.isRuning = true;
        ricentBus.emit(TASK_QUEUEN_START, {
            context: this,
            popTip: popTip
        });
        let runner = queuen[0];
        ricentBus.emit(TASK_START, {
            current: runner,
            queuen: this.queuen
        });
        runner.status = "running";
        let curent = runner.runner();
        let subscription: Subscription;
        subscription = curent.subscribe(_ => {
            runner.status = "done";
            let call: any = runner.successCallback;
            let done = (stop: boolean = false) => {
                ricentBus.emit(TASK_COMPLETE, {
                    current: runner,
                    queuen: this.queuen
                });
                if (!stop) {
                    me.run(true);
                } else {
                    complete();
                }
            }
            if (call != null) {
                call(_).then((r: any) => {
                    if (r !== false) {
                        done();
                    } else {
                        done(true);
                    }
                })
            } else {
                done();
            }
        }, err => {
            runner.status = "error"
            if (runner.errorCallback) {
                runner.errorCallback(err);
            }
            if (!runner.errorStopTasks) {
                this.run(true);
            } else {
                complete();
            }
        }, () => {
            subscription && subscription.unsubscribe();
        })

    }

    appendAndrun(tasks: any[], popTip: boolean = true) {
        tasks.forEach(item => {
            this.addTask(item);
        });
        this.run(false, popTip);
    }

}