//java -jar cli.jar generate -i http://localhost:9001/docs/v2/api.json -o api -l typescript-angular -t ./ng2
import  filesInstance,{ FilesService }  from './files.service';
import { ApiClient } from '../../apiService';

declare module '../../apiService' {
  export interface ApiClient{

    files:FilesService,
  }
}


const apiClient={

  files:filesInstance,
}
export default apiClient

