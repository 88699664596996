import { apiClient } from "@/api/src/apis";
import { ricentBus } from "@/core/bus";
import { OGRCHANGE, UNAUTHORIZED_401 } from "@/core/globalEvent";
import store from "@/store";
import { TokenModel } from "@/store/modules/dto";
import { App } from "vue";
import { ProfileDto, ProfileDtoRicResponse } from "@/api/src/userCenter/model/models";
import { OrgDto } from "@/api/src/org/model/models";
import router from "@/router";
export class AuthService {

    public get userInfo(): ProfileDto | null {
        return store.getters["authStore/getUser"];
    }
    public set userInfo(userInfo: ProfileDto | null) {
        store.commit("authStore/setUser", userInfo!);
    }

    public get tokenInfo(): TokenModel | null {
        return store.getters["authStore/getToken"];
    }
    public set tokenInfo(token: TokenModel | null) {
        store.commit("authStore/setToken", token!);
    }

    public _orgId: string | null = store.getters["authStore/getOrgId"];
    public get orgId() {
        return this._orgId;
    }
    public set orgId(value) {
        if (value && this._orgId != value) {
            // registerRouter()
            let org = this.getCurrentOrg(value);
            ricentBus.emit(OGRCHANGE, org)
        }
        this._orgId = value;
        store.commit("authStore/setOrgId", value!);
    }
    
    public get enterprise(): any {
        return store.getters["authStore/getEnterprise"];
    }
    public set enterprise(ent: any) {
        if (ent) {
            let org = this.getCurrentOrg();
            if (ent.enterpriseSource == 1 && org) {
                ent.name = org.name;
            }
        }
        store.commit("authStore/setEnterprise", ent);
    }

    //用户当前组织
    // public orgId!: string | null;
    //用户组织
    public get orgs(): OrgDto[] | null {
        let orgs = store.getters["authStore/getOrgs"];
        return orgs!;
    }

    public set orgs(ogs: OrgDto[] | null) {
        store.commit("authStore/setOrgs", ogs!);
    }

    public clear() {
        this.orgId = null;
        this.tokenInfo = null;
        this.userInfo = null;
        this.tokenInfo = null;
        this.orgs = null;
        this.enterprise = null;
        store.commit("authStore/clear");
    }

    getCurrentOrg(orgId?: string | null) {
        !orgId && (orgId = this.orgId);
        if (orgId && this.orgs) {
            return this.orgs.find(_ => _.id == orgId);
        }
        return null;
    }

    public profile(): Promise<ProfileDtoRicResponse> {
        return apiClient.userCenter.profile().then(_ => {
            this.userInfo = _.data || null;
            return _;
        });
    }

    public getOrgs(): Promise<Array<OrgDto>> {
        return apiClient.org.queryMeOrg().then(_ => {
            let orgs = _.data || [];
            this.orgs = orgs;
            return orgs;
        });
    }



}

export const authService = new AuthService()

ricentBus.on(UNAUTHORIZED_401, (arg) => {
    authService.clear();
    router.push({ name: 'login' })
}, "global")

const authPlus = {
    install(app: App) {
        (store.state);
        app.config.globalProperties.$auth = authService;
    }
};

export default authPlus;