import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!
  const _component_RcUploadDialogTip = _resolveComponent("RcUploadDialogTip")!
  const _component_ViewerJS = _resolveComponent("ViewerJS")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_config_provider, { locale: _ctx.locale }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }, 8, ["locale"]),
    _createVNode(_component_RcUploadDialogTip),
    _createVNode(_component_ViewerJS, {
      ref: "viewer",
      files: _ctx.data,
      "onFile:remove": _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeFile($event)))
    }, null, 8, ["files"])
  ], 64))
}