import { FileContainerDtoListRicResponse } from '../model/fileContainerDtoListRicResponse';
import { FileContainerDtoRicResponse } from '../model/fileContainerDtoRicResponse';
import { FileCopyInput } from '../model/fileCopyInput';
import { FileExtraDtoListRicResponse } from '../model/fileExtraDtoListRicResponse';
import { FileExtraDtoRicResponse } from '../model/fileExtraDtoRicResponse';
import { FileExtraPatchInput } from '../model/fileExtraPatchInput';
import { FileGroupDeleteInput } from '../model/fileGroupDeleteInput';
import { MoveDto } from '../model/moveDto';
import { PatchFileGroupInput } from '../model/patchFileGroupInput';
import { PatchFileInput } from '../model/patchFileInput';
import { UploadFileInput } from '../model/uploadFileInput';
import { ZipExportInput } from '../model/zipExportInput';
import { Api } from '../../api';
import { HttpOption} from "@/core/http";

/* tslint:disable:no-unused-variable member-ordering */



export class FilesService  extends Api  {
  constructor() {
    super();
  }
    /**
     * 删除文件|delete
     * 
     * @param id 文件Id
     
     **/
    public _delete(
        id:string,
        
        options:HttpOption={},
   ): Promise<unknown> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/file/api/v5/files/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 根据uniqueId获取的文件|content
     * 
     * @param uniqueId 文件Id
     * @param queryParams:{
          * @param fileName 
          * @param outFormat 
          * @param size 
          * @param inline 
       },
     **/
    public content(
        uniqueId:string,
        queryParams?:{
          fileName?: string,
          outFormat?: string,
          size?: number,
          inline?: boolean,
        },
        options:HttpOption={},
   ): Promise<Blob> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['fileName'])) {
                params['fileName']=<any>queryParams['fileName']
        }
        if (queryParams&&!this.ce(queryParams['outFormat'])) {
                params['outFormat']=<any>queryParams['outFormat']
        }
        if (queryParams&&!this.ce(queryParams['size'])) {
                params['size']=<any>queryParams['size']
        }
        if (queryParams&&!this.ce(queryParams['inline'])) {
                params['inline']=<any>queryParams['inline']
        }
        let path = `/file/api/v5/files/${encodeURIComponent(String(uniqueId))}/content`;
	    path = this.url(path, { uniqueId:uniqueId });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 根据uniqueId获取的文件|content
     * 
     * @param uniqueId 文件Id
     * @param queryParams:{
          * @param fileName 
          * @param outFormat 
          * @param size 
          * @param inline 
       },
     **/
    public content_1(
        uniqueId:string,
        queryParams?:{
          fileName?: string,
          outFormat?: string,
          size?: number,
          inline?: boolean,
        },
        options:HttpOption={},
   ): Promise<Blob> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['fileName'])) {
                params['fileName']=<any>queryParams['fileName']
        }
        if (queryParams&&!this.ce(queryParams['outFormat'])) {
                params['outFormat']=<any>queryParams['outFormat']
        }
        if (queryParams&&!this.ce(queryParams['size'])) {
                params['size']=<any>queryParams['size']
        }
        if (queryParams&&!this.ce(queryParams['inline'])) {
                params['inline']=<any>queryParams['inline']
        }
        let path = `/file/api/v5/files/${encodeURIComponent(String(uniqueId))}/content`;
	    path = this.url(path, { uniqueId:uniqueId });
        return this.request(
            'head',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 根据uniqueId获取的文件|content
     * 
     * @param uniqueId 文件Id
     * @param fileName 
     * @param queryParams:{
          * @param outFormat 
          * @param size 
          * @param inline 
       },
     **/
    public content_2(
        uniqueId:string,
        fileName:string,
        queryParams?:{
          outFormat?: string,
          size?: number,
          inline?: boolean,
        },
        options:HttpOption={},
   ): Promise<Blob> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['outFormat'])) {
                params['outFormat']=<any>queryParams['outFormat']
        }
        if (queryParams&&!this.ce(queryParams['size'])) {
                params['size']=<any>queryParams['size']
        }
        if (queryParams&&!this.ce(queryParams['inline'])) {
                params['inline']=<any>queryParams['inline']
        }
        let path = `/file/api/v5/files/${encodeURIComponent(String(uniqueId))}/content/${encodeURIComponent(String(fileName))}`;
	    path = this.url(path, { uniqueId:uniqueId,fileName:fileName });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 根据uniqueId获取的文件|content
     * 
     * @param uniqueId 文件Id
     * @param fileName 
     * @param queryParams:{
          * @param outFormat 
          * @param size 
          * @param inline 
       },
     **/
    public content_3(
        uniqueId:string,
        fileName:string,
        queryParams?:{
          outFormat?: string,
          size?: number,
          inline?: boolean,
        },
        options:HttpOption={},
   ): Promise<Blob> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['outFormat'])) {
                params['outFormat']=<any>queryParams['outFormat']
        }
        if (queryParams&&!this.ce(queryParams['size'])) {
                params['size']=<any>queryParams['size']
        }
        if (queryParams&&!this.ce(queryParams['inline'])) {
                params['inline']=<any>queryParams['inline']
        }
        let path = `/file/api/v5/files/${encodeURIComponent(String(uniqueId))}/content/${encodeURIComponent(String(fileName))}`;
	    path = this.url(path, { uniqueId:uniqueId,fileName:fileName });
        return this.request(
            'head',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 获取图片文件切片|content
     * 
     * @param uniqueId 
     * @param x 
     * @param y 
     * @param z 
     
     **/
    public content_4(
        uniqueId:string,
        x:number,
        y:number,
        z:number,
        
        options:HttpOption={},
   ): Promise<Blob> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/file/api/v5/files/${encodeURIComponent(String(uniqueId))}/content/tile/${encodeURIComponent(String(x))}/${encodeURIComponent(String(y))}/${encodeURIComponent(String(z))}`;
	    path = this.url(path, { uniqueId:uniqueId,x:x,y:y,z:z });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 获取图片文件切片|content
     * 
     * @param uniqueId 
     * @param x 
     * @param y 
     * @param z 
     
     **/
    public content_5(
        uniqueId:string,
        x:number,
        y:number,
        z:number,
        
        options:HttpOption={},
   ): Promise<Blob> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/file/api/v5/files/${encodeURIComponent(String(uniqueId))}/content/tile/${encodeURIComponent(String(x))}/${encodeURIComponent(String(y))}/${encodeURIComponent(String(z))}.png`;
	    path = this.url(path, { uniqueId:uniqueId,x:x,y:y,z:z });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 获取图片文件切片后的原图|content
     * 
     * @param uniqueId 
     * @param queryParams:{
          * @param size 
          * @param flag 
       },
     **/
    public content_6(
        uniqueId:string,
        queryParams?:{
          size?: number,
          flag?: number,
        },
        options:HttpOption={},
   ): Promise<Blob> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['size'])) {
                params['size']=<any>queryParams['size']
        }
        if (queryParams&&!this.ce(queryParams['flag'])) {
                params['flag']=<any>queryParams['flag']
        }
        let path = `/file/api/v5/files/${encodeURIComponent(String(uniqueId))}/content/tile.png`;
	    path = this.url(path, { uniqueId:uniqueId });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 复制文件根据文件组|copy
     * 
     * @param queryParams:{
          * @param startWith 
       },* @param body 复制文件输入
     **/
    public copy(
        queryParams?:{
          startWith?: boolean,
        },body?:FileCopyInput,
        options:HttpOption={},
   ): Promise<FileContainerDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['startWith'])) {
                params['startWith']=<any>queryParams['startWith']
        }
        let path = `/file/api/v5/files/copy`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 复制文件根据文件组(批处理)|copyBatch
     * 
     * @param queryParams:{
          * @param startWith 
       },* @param body 复制文件输入
     **/
    public copyBatch(
        queryParams?:{
          startWith?: boolean,
        },body?:Array<FileCopyInput>,
        options:HttpOption={},
   ): Promise<FileContainerDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['startWith'])) {
                params['startWith']=<any>queryParams['startWith']
        }
        let path = `/file/api/v5/files/copy/batch`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 批量删除文件|deleteBatch
     * 
     * @param body 文件Ids
     **/
    public deleteBatch(
        body?:Array<string>,
        options:HttpOption={},
   ): Promise<unknown> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/file/api/v5/files`;
	    path = this.url(path);
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 删除文件扩展|deleteExtra
     * 
     * @param id 
     
     **/
    public deleteExtra(
        id:string,
        
        options:HttpOption={},
   ): Promise<unknown> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/file/api/v5/files-extra/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 批量删除文件|deleteGroup
     * 
     * @param group 
     * @param body 文件Ids
     **/
    public deleteGroup(
        group:string,
        body?:FileGroupDeleteInput,
        options:HttpOption={},
   ): Promise<unknown> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/file/api/v5/files/group/${encodeURIComponent(String(group))}`;
	    path = this.url(path, { group:group });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 获取指定Id的信息|get
     * 
     * @param id 文件Id
     
     **/
    public get(
        id:string,
        
        options:HttpOption={},
   ): Promise<FileContainerDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/file/api/v5/files/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 获取文件扩展|getExtra
     * 
     * @param id 文件Id
     
     **/
    public getExtra(
        id:string,
        
        options:HttpOption={},
   ): Promise<FileExtraDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/file/api/v5/files-extra/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 替换文件|modify
     * 
     * @param id 
     * @param body 上传文件输入
     **/
    public modify(
        id:string,
        body?:UploadFileInput,
        options:HttpOption={},
   ): Promise<unknown> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/file/api/v5/files/${encodeURIComponent(String(id))}/modify`;
	    path = this.url(path, { id:id });
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 移动文件|move
     * 
     * @param id 
     * @param body 
     **/
    public move(
        id:string,
        body?:MoveDto,
        options:HttpOption={},
   ): Promise<unknown> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/file/api/v5/files/${encodeURIComponent(String(id))}/move`;
	    path = this.url(path, { id:id });
        return this.request(
            'put',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 修改文件信息|patch
     * 
     * @param id 文件Id
     * @param body 
     **/
    public patch(
        id:string,
        body?:PatchFileInput,
        options:HttpOption={},
   ): Promise<FileContainerDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/file/api/v5/files/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 修改文件扩展|patchExtra
     * 
     * @param id 
     * @param body 
     **/
    public patchExtra(
        id:string,
        body?:FileExtraPatchInput,
        options:HttpOption={},
   ): Promise<FileExtraDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/file/api/v5/files-extra/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 修改文件Group信息|patchGroup
     * 
     * @param body 
     **/
    public patchGroup(
        body?:PatchFileGroupInput,
        options:HttpOption={},
   ): Promise<unknown> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/file/api/v5/files/group`;
	    path = this.url(path);
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * POST查询接口,根据多个ID返回|postQuery
     * 
     * @param body 文件Id
     **/
    public postQuery(
        body?:Array<string>,
        options:HttpOption={},
   ): Promise<FileContainerDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/file/api/v5/files/query`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 查询列表|query
     * 
     * @param queryParams:{
          * @param group 
          * @param startWith 
          * @param orderBy 
          * @param top 
       },
     **/
    public query(
        queryParams?:{
          group?: string,
          startWith?: boolean,
          orderBy?: boolean,
          top?: number,
        },
        options:HttpOption={},
   ): Promise<FileContainerDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['group'])) {
                params['group']=<any>queryParams['group']
        }
        if (queryParams&&!this.ce(queryParams['startWith'])) {
                params['startWith']=<any>queryParams['startWith']
        }
        if (queryParams&&!this.ce(queryParams['orderBy'])) {
                params['orderBy']=<any>queryParams['orderBy']
        }
        if (queryParams&&!this.ce(queryParams['top'])) {
                params['top']=<any>queryParams['top']
        }
        let path = `/file/api/v5/files`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 查询列表|queryBatch
     * 
     * @param queryParams:{
          * @param group 
          * @param startWith 
          * @param orderBy 
          * @param top 
          * @param groupLenth 
       },
     **/
    public queryBatch(
        queryParams?:{
          group?: string,
          startWith?: boolean,
          orderBy?: boolean,
          top?: number,
          groupLenth?: number,
        },
        options:HttpOption={},
   ): Promise<FileContainerDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['group'])) {
                params['group']=<any>queryParams['group']
        }
        if (queryParams&&!this.ce(queryParams['startWith'])) {
                params['startWith']=<any>queryParams['startWith']
        }
        if (queryParams&&!this.ce(queryParams['orderBy'])) {
                params['orderBy']=<any>queryParams['orderBy']
        }
        if (queryParams&&!this.ce(queryParams['top'])) {
                params['top']=<any>queryParams['top']
        }
        if (queryParams&&!this.ce(queryParams['groupLenth'])) {
                params['groupLenth']=<any>queryParams['groupLenth']
        }
        let path = `/file/api/v5/files/batch`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 查询文件扩展|QueryExtra
     * 
     * @param queryParams:{
          * @param group 
          * @param options 
          * @param flags 
          * @param startWith 
          * @param top 
       },
     **/
    public queryExtra(
        queryParams?:{
          group?: string,
          options?: string,
          flags?: string,
          startWith?: boolean,
          top?: number,
        },
        options:HttpOption={},
   ): Promise<FileExtraDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['group'])) {
                params['group']=<any>queryParams['group']
        }
        if (queryParams&&!this.ce(queryParams['options'])) {
                params['options']=<any>queryParams['options']
        }
        if (queryParams&&!this.ce(queryParams['flags'])) {
                params['flags']=<any>queryParams['flags']
        }
        if (queryParams&&!this.ce(queryParams['startWith'])) {
                params['startWith']=<any>queryParams['startWith']
        }
        if (queryParams&&!this.ce(queryParams['top'])) {
                params['top']=<any>queryParams['top']
        }
        let path = `/file/api/v5/files-extra`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 获取图片缩略图|thumb
     * 
     * @param uniqueId 文件Id
     * @param queryParams:{
          * @param mode 模式
          * @param size 最大宽高
          * @param w 宽
          * @param h 高
          * @param locksize 是否锁定大小,如果true 返回的图片大小就是入参的大小4:3 不满的地方空白填充
       },
     **/
    public thumb(
        uniqueId:string,
        queryParams?:{
          mode?: number,
          size?: number,
          w?: number,
          h?: number,
          locksize?: boolean,
        },
        options:HttpOption={},
   ): Promise<Blob> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['mode'])) {
                params['mode']=<any>queryParams['mode']
        }
        if (queryParams&&!this.ce(queryParams['size'])) {
                params['size']=<any>queryParams['size']
        }
        if (queryParams&&!this.ce(queryParams['w'])) {
                params['w']=<any>queryParams['w']
        }
        if (queryParams&&!this.ce(queryParams['h'])) {
                params['h']=<any>queryParams['h']
        }
        if (queryParams&&!this.ce(queryParams['locksize'])) {
                params['locksize']=<any>queryParams['locksize']
        }
        let path = `/file/api/v5/files/${encodeURIComponent(String(uniqueId))}/thumb`;
	    path = this.url(path, { uniqueId:uniqueId });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 文件上传|Upload
     * 
     
     **/
    public upload(
        
        options:HttpOption={},
   ): Promise<unknown> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/file/api/v5/files`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * ZIP打包文件下载|zipExport
     * 
     * @param body 
     **/
    public zipExport(
        body?:ZipExportInput,
        options:HttpOption={},
   ): Promise<FileContainerDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/file/api/v5/files/zip-export`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

}

const filesInstance= new FilesService()
export default filesInstance
