
import { ref, reactive, toRaw, computed, watch, onMounted, toRefs } from "vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import { apiClient } from "@/api/src/apis";
import { ProjectDto } from "@/api/src/org/model/projectDto";
import ProjectItem from "./ProjectItem.vue";
import RcSearch from "@/components/RcSearch/index.vue";
export default {
  name: "Project",
  components: {
    SearchOutlined,
    ProjectItem,
    RcSearch,
  },
  setup() {
    let keyword = ref<string>("");
    let checkedList = ref<number[]>([]);
    let projectVisible = ref<boolean>(false);
    let formRef = ref();
    let loading = ref<boolean>(false);
    let projectState = reactive({
      projectList: Array<ProjectDto>(),
      formState: {
        title: "",
        principal: [],
        status: "1", // 1：未动工 2：进行中 4：已完工
      },
    });

    // 模糊查找项目
    let showList = computed(() => {
      if (keyword) {
        return projectState.projectList.filter((item) => {
          return item.name!.indexOf(keyword.value) >= 0;
        });
      }
      return projectState.projectList;
    });

    // 表单验证规则
    const formRules = {
      title: [{ required: true, message: "项目名称不能为空", trigger: "blur" }],
      principal: [
        {
          type: "array",
          required: true,
          message: "项目负责人不能为空",
          trigger: ["change", "blur"],
        },
      ],
      status: [
        { required: true, message: "项目状态不能为空", trigger: "change" },
      ],
    };

    // 生命周期函数
    onMounted(() => {
      queryProject();
    });

    let showModal = () => {
      projectVisible.value = true;
    };

    // todo 可改为前端逻辑查找不走接口
    watch(checkedList, () => {
      queryProject();
    });

    // 确认创建
    const handleOk = (e: any) => {
      formRef.value
        .validate()
        .then(() => {
          const tmp = JSON.parse(JSON.stringify(toRaw(projectState.formState)));
          createProject(tmp);
        })
        .catch((err: any) => {
        });
    };

    //// ------- 接口事件 ------///////
    // 新建项目
    const createProject = (obj: any) => {
      apiClient.region
        .postProject({
          name: obj.title,
          status: parseInt(obj.status),
          projectMemberIds: obj.principal,
        })
        .then((res) => {
          queryProject();
          projectVisible.value = false;
        })
        .catch((err) => {
        });
    };

    // 查询项目list
    const queryProject = () => {
      loading.value = true;
      let status =
        checkedList.value.length === 0
          ? 7
          : checkedList.value.reduce((pre, next) => pre + next);
      apiClient.region
        .queryProject({
          key: keyword.value,
          status: status,
          fields: "member",
        })
        .then((res) => {
          loading.value = false;
          let tmp = res.data as ProjectDto[];
          projectState.projectList = tmp;
        })
        .catch((err) => {
          loading.value = false;
        });
    };

    return {
      keyword,
      checkedList,
      loading,
      projectVisible,
      ...toRefs(projectState),
      labelCol: { style: { width: "90px", color: "#555B61" } },
      formRules,
      formRef,
      showList,
      showModal,
      handleOk,
    };
  },
};
