
import { Modal } from "ant-design-vue";
import { Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

export default class DraingList extends Vue {
  @Prop()
  data!: any;
  selectedRowKeys: any[] = [];
  rowSelection!: {
    selectedRowKeys?: any[];
    onChange?: (selectedRowKeys: string[], selectedRows: any[]) => void;
  };

  columns = [
    {
      title: "文件名",
      width: 280,
      dataIndex: "name",
      slots: { customRender: "name" },
      key: "name",
      ellipsis: true,
    },
    {
      title: "格式",
      key: "ext",
      dataIndex: "ext",
      width: 80,
    },
    {
      title: "大小",
      dataIndex: "sizeMb",
      key: "sizeMb",
      width: 80,
    },
    {
      title: "修改人",
      dataIndex: "lastOperatorUserName",
      key: "lastOperatorUserName",
      width: 120,
    },
    {
      title: "修改时间",
      dataIndex: "date",
      key: "date",
      width: 140,
    },
    {
      title: "操作",
      dataIndex: "operation",
      slots: { customRender: "action" },
      width: 120,
    },
  ];

  onchange(selectedRowKeys: string[], selectedRows: any[]) {
    this.selectedRowKeys = selectedRowKeys;
    this.data.forEach((e: any) => {
      if (this.selectedRowKeys.some((_: any) => _ == e.id)) {
        e.checked = true;
      } else {
        e.checked = false;
      }
    });
  }

  viewer(item: any) {
    this.$emit("file:view", item);
  }

  mounted() {
    console.log("mounted");
    this.selectedRowKeys = [];
    this.data?.forEach((_: any) => {
      if (_.checked) {
        this.selectedRowKeys.push(_.id);
      }
    });
  }

  deleteItem(it: any) {
    Modal.confirm({
      title: "提示",
      content: "您确定要删除吗？",
      onOk: () => {
        this.$emit("file:remove", it);
      },
      onCancel() {},
    });
  }
  rename(it: any) {
    this.$emit("file:rename", it);
  }
}
