

import { ricentBus } from "@/core/bus";
import { OGRCHANGE } from "@/core/globalEvent";
import { Vue } from "vue-class-component";
export default class Home extends Vue {
  protected margin!: string;
  org: any = null;
  events: any[] = [];

  mounted() {
    let styles = window.getComputedStyle(this.$el.parentElement);
    this.margin = styles.getPropertyValue("margin");
    this.$el.parentElement.style.margin = "12px 0px 0px 0px";
    let me = this;
    this.org = this.getOrg();
    this.events = [
      ricentBus.on(
        OGRCHANGE,
        (org: any) => {
          me.org = org;
        },
        this
      ),
    ];
  }

  getOrg() {
    let orgId: any = this.$route.params.orgId;
    let org = this.$auth.getCurrentOrg(orgId);
    return org;
  }

  unmounted() {
    this.events.forEach((fn) => {
      fn();
    });
  }
  beforeUnmount() {
    this.$el.parentElement.style.margin = this.margin;
  }
}
