<template>
  <div :class="wrpCls" class="header">
    <avatar-dropdown
      :menu="showMenu"
      :current-user="currentUser"
      :class="prefixCls"
    />
  </div>
</template>

<script>
// import store from "@/store";
import AvatarDropdown from "./AvatarDropdown";
import { GlobalOutlined } from "@ant-design/icons-vue";

// import SelectLang from '@/components/SelectLang'

export default {
  name: "RightContent",
  components: {
    AvatarDropdown,
    GlobalOutlined,
  },
  watch: {
    company: {
      handler() {},
      deep: true,
    },
  },
  props: {
    enterprises: {
      type: [],
      default: () => [],
    },
    prefixCls: {
      type: String,
      default: "ant-pro-global-header-index-action",
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
    topMenu: {
      type: Boolean,
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showMenu: true,
      currentUser: {},
      company: {},
      drawerVisible: false,
      modelVisible: false,
    };
  },
  computed: {
    wrpCls() {
      return {
        "ant-pro-global-header-index-right": true,
        [`ant-pro-global-header-index-${
          this.isMobile || !this.topMenu ? "light" : this.theme
        }`]: true,
      };
    },
  },
  methods: {
    // 打开抽屉弹框
    // 关闭抽屉弹框
    closeDrawer() {
      this.currentUser = this.$store.getters["authStore/getUser"];
    },
  },
  mounted() {
    this.currentUser = this.$store.getters["authStore/getUser"];
  },
};
</script>
<style lang="less" scoped>
.header {
  display: flex;
}
.company {
  margin-left: 15px;
}
.company-title {
  margin-left: 5px;
}
.company:hover {
  color: #5c92ff;
  cursor: pointer;
}
</style>
