
export interface Route {
  path: string;
  breadcrumbName: string;
  children?: Array<{
    path: string;
    breadcrumbName: string;
  }>;
}
import { Vue } from "vue-class-component";
export default class RcBreadcrumb extends Vue {
  public routes: Route[] = [];

  _initRouter(to: any) {
    let cur: any = to;
    let metas = cur.matched.filter((_: any) => _.meta && _.meta.breadcrumbName);
    let it = metas.map((_: any) => {
      let path = _.path;
      Object.keys(cur.params).forEach((key) => {
        path = path.replace(`:${key}`, cur.params[key]);
      });
      return {
        path: path,
        breadcrumbName: _.meta.breadcrumbName,
      };
    });
    this.routes = it;
  }

  created() {
    this.$router.afterEach((to, from) => {
      this._initRouter(to);
    });
    this.$bus.on(
      "from:micro:router:afterEach",
      (params: any) => {
        this._initRouter(params.data.to);
      },
      this
    );
  }

  mounted() {
    let me = this;
    setTimeout(() => {
      if (!me.routes.length) {
        me._initRouter(me.$router.currentRoute.value);
      }
    }, 0);
  }
}
