
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { authService } from "@/auth/authService";

import { constantRouterMap, asyncRouterMap } from '@/config/routerSettings';
import { permissionService } from '@/desktop/permission';
import { Ricent } from '@/core/ricent';
import microApp from '@micro-zoe/micro-app';
import { microAppEnvConfig } from '@/config/microAppSettings';
import { routerPlus } from './routerPlus';
declare var window: any;

//组织切换事件
//子应用监听基应用传递的数据
if (microAppEnvConfig.__MICRO_APP_ENVIRONMENT__) {
  window.microApp.addGlobalDataListener((params: {
    type: string,
    data: any;
  }) => {
    // 当基座下发跳转指令时进行跳转
    if (params.type == "from:base:router:afterEach") {
      if (params.data.to.path) {
        router.push(params.data.to.fullPath);
      }
    }
  })
}
//解决子应用go(-1),goback()后主应用路由不响应
window.addEventListener('popstate', (event: any) => {
  if (!event.state.current) {
    event.state.current = router.currentRoute.value.fullPath;
  }
});


const Whitelists = ['login', "wating"] // no redirect allowList
export const eachItems = function (items: RouteRecordRaw[]) {
  items.forEach((element) => {
    if (!element.meta) element.meta = {};
    element.meta.key = element.path;
    let appName: any = Ricent.uuid();
    //微应用的appName
    if (Object.prototype.toString.call(element.name) == "[object String]") {
      appName = element.name;
    }
    element.meta.app = appName;
    if (element.children) {
      eachItems(element.children);
    }
  });
  return items;
}

const router = window.rcRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    ...eachItems(constantRouterMap),
    ...eachItems(asyncRouterMap),
  ]
})


export function registerMicroRouter(routers?: any) {
  if (!routers) {
    routers = [];
  }
  if (!Array.isArray(routers)) {
    routers = [
      routers
    ];
  }
  let root!: RouteRecordRaw;
  function findPlaceholder(routerArr: RouteRecordRaw[]) {
    if (root) return;
    routerArr.some(_ => {
      if (_.name == "orgId") {
        root = _;
        return true;
      } else if (_.children) {
        findPlaceholder(_.children);
      }
    })
  }
  findPlaceholder(asyncRouterMap);
  if (root) {
    root.children = [
      root.children![0],
      ...routers,
    ]
  }
  router.removeRoute("orgId");
  router.addRoute("organize", root);
}




router.beforeEach((to, from, next) => {

  if (Whitelists.some(_ => _ == to.name)) {
    if (to.name == "login") {
      authService.clear();
    }
    next();
  } else if (to.path == "/organize" || to.path == "/organize/") {
    let orgId = authService.orgId;
    if (!authService.orgs) {
      authService.getOrgs().then(_ => {
        next({
          path: `/organize/${orgId || authService.orgs![0].id}`
        })
      })
    } else if (!authService.orgs.length) {
      next({
        path: "/error",
        query: {
          msg: "用户不属于任何组织"
        }
      })
    } else {
      next({
        path: `/organize/${orgId || authService.orgs[0].id}`
      })
    }
  }
  else {
    if (to.path.startsWith("/organize")) {
      let orgId = to.path.split("/")[2];
      let org: any = orgId && authService.getCurrentOrg(orgId);
      if (org && !org.meuns) {
        routerPlus(org, from.path).then((router: any) => {
          if (!router) {
            router = [];
          }
          if (!Array.isArray(router)) {
            router = [
              router
            ];
          }
          eachItems(router);
          org.meuns = router;
          permissionService.generateMenus();
          next({
            path: to.path,
            params: to.params,
          });
        })
      } else {
        next();
      }
    } else {
      next();
    }
  }
})

router.afterEach((to, from) => {
  if ([
    "/organize",
    ...constantRouterMap.map(_ => _.path)
  ].some(_ => to.path.startsWith(_))) {//取消loading
    setTimeout(() => {
      let wrap = document.getElementById("overlay_wrap");
      wrap!.style.display = "none";
    }, 0);
  }
  //基应用向子应用发信息
  if (!microAppEnvConfig.__MICRO_APP_ENVIRONMENT__) {
    if (!Whitelists.some(_ => _ == to.name) && to.name != "home") {
      microApp.setGlobalData({
        type: 'from:base:router:afterEach', data: {
          from: from,
          to: to,
        }
      })
    }
  } else {
    //子应用向基应用发信息
    window.microApp.dispatch({
      type: 'from:micro:router:afterEach', data: {
        from: from,
        to: to,
      }
    })
  }

  if (to.params["orgId"]) {
    authService.orgId = <any>to.params["orgId"];
  }
  if (to.name == "home" && from.path.indexOf("CZCJT/CZCJT") > -1) {
    window.location.reload();
  }

})

export default router
