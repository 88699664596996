export const menu_enterprise = [
    {
        meta: {
            title: '组织管理',
            breadcrumbName: "组织管理",
            icon: 'icon-horn',
            isMeun: true,
        },
        name: "organizationmanage",
        path: "organizationmanage/:page*",
        component: "Empty",
    },
    {
        meta: {
            title: '成员管理',
            breadcrumbName: "成员管理",
            icon: 'icon-horn',
            isMeun: true,
        },
        name: "usermanage",
        path: "usermanage/:page*",
        component: "Empty",
    },
    {
        meta: {
            title: '客户管理',
            breadcrumbName: "客户管理",
            icon: 'icon-horn',
            isMeun: true,
        },
        name: "customerInfo",
        path: "customerInfo/:page*",
        component: "Empty",
    },
    {
        meta: {
            title: '标签管理',
            breadcrumbName: "标签管理",
            icon: 'icon-horn',
            isMeun: true,
        },
        name: "tagsManage",
        path: "tagsManage/:page*",
        component: "Empty",
    },
    {
        meta: {
            title: '合作组织管理',
            breadcrumbName: "合作组织管理",
            icon: 'icon-horn',
            isMeun: true,
        },
        name: "supplierManagement",
        path: "supplierManagement/:page*",
        component: "Empty",
    },
];

export const menu_evaluationPlan = [
    {
        meta: {
            title: '评估计划',
            breadcrumbName: "评估计划",
            icon: 'icon-horn',
            isMeun: true,
        },
        name: "assessmentPlan",
        path: "assessmentPlan/:page*",
        component: "Empty",
    },
    {
        meta: {
            title: '任务分配',
            breadcrumbName: "任务分配",
            icon: 'icon-horn',
            isMeun: true,
        },
        name: "assessmentPlan/taskDistribution",
        path: "assessmentPlan/taskDistribution/:page*",
        component: "Empty",
    },
    {
        meta: {
            title: '我的任务',
            breadcrumbName: "我的任务",
            icon: 'icon-horn',
            isMeun: true,
        },
        name: "planTask/assessmentPlan",
        path: "planTask/assessmentPlan/:page*",
        component: "Empty",
    },
    {
        meta: {
            title: '问题申诉',
            breadcrumbName: "问题申诉",
            icon: 'icon-horn',
            isMeun: true,
        },
        name: "problemAppeal/editionC/appeal",
        path: "problemAppeal/editionC/appeal/:page*",
        component: "Empty",
    },
    {
        meta: {
            title: '评估销项',
            breadcrumbName: "评估销项",
            icon: 'icon-horn',
            isMeun: true,
        },
        name: "problemDestory/editionC/distory",
        path: "problemDestory/editionC/distory/:page*",
        component: "Empty",
    },
];

export const menu_assesmentReport = [
    {
        meta: {
            title: '战力排名表',
            breadcrumbName: "战力排名表",
            icon: 'icon-horn',
            isMeun: true,
        },
        name: "punch-ranking-report",
        path: "punch-ranking-report/:page*",
        component: "Empty",
    },
    {
        meta: {
            title: '打卡记录表',
            breadcrumbName: "我的任务",
            icon: 'icon-horn',
            isMeun: true,
        },
        name: "punch-record-report",
        path: "punch-record-report/:page*",
        component: "Empty",
    },
    {
        meta: {
            title: '任务一览表',
            breadcrumbName: "任务一览表",
            icon: 'icon-horn',
            isMeun: true,
        },
        name: "planItem-report",
        path: "planItem-report/:page*",
        component: "Empty",
    },
    {
        meta: {
            title: '问题一览表',
            breadcrumbName: "问题一览表",
            icon: 'icon-horn',
            isMeun: true,
        },
        name: "problem-report",
        path: "problem-report/:page*",
        component: "Empty",
    },
    {
        meta: {
            title: '申述一览表',
            breadcrumbName: "申述一览表",
            icon: 'icon-horn',
            isMeun: true,
        },
        name: "problem-appeal-report",
        path: "problem-appeal-report/:page*",
        component: "Empty",
    },
];
