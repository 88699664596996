import Vue, { App } from 'vue'
import { createI18n } from 'vue-i18n'
import moment from 'moment'
import enUS from './lang/en-US'
import zhCN from './lang/zh-CN'
import storage from '@/core/storage'


export const defaultLang = 'zh-cn'

export const i18n: any = createI18n({
  locale: defaultLang,
  messages: {
    'en-US': {
      ...enUS
    },
    'zh-cn': {
      ...zhCN
    }
  },
})


function setI18nLanguage(lang: any) {
  i18n.global.locale = lang
  return lang
}

export function loadLanguageAsync(lang = defaultLang) {
  return new Promise(resolve => {
    // 缓存语言设置
    storage.set('lang', lang)
    if (i18n.global.locale !== lang) {
      return resolve(setI18nLanguage(lang))
    }
    return resolve(lang)
  })
}

export function i18nRender(key: string) {
  return i18n.global.t(key);
}


export default i18n;