import { ProfessionCopyInput } from '../model/professionCopyInput';
import { ProfessionDtoListRicResponse } from '../model/professionDtoListRicResponse';
import { ProfessionDtoRicResponse } from '../model/professionDtoRicResponse';
import { ProfessionPatchInput } from '../model/professionPatchInput';
import { ProfessionPostInput } from '../model/professionPostInput';
import { RicNullRicResponse } from '../model/ricNullRicResponse';
import { SortInput } from '../model/sortInput';
import { Api } from '../../api';
import { HttpOption} from "@/core/http";

/* tslint:disable:no-unused-variable member-ordering */



export class ProfessionService  extends Api  {
  constructor() {
    super();
  }
    /**
     * 删除专业|delete
     * 
     * @param id 
     
     **/
    public _delete(
        id:string,
        
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/profession/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 复制组织体系|copyOrg
     * 
     * @param body 
     **/
    public copyOrg(
        body?:ProfessionCopyInput,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/profession/copy/org`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 获取单条专业|get
     * 
     * @param id 
     
     **/
    public get(
        id:string,
        
        options:HttpOption={},
   ): Promise<ProfessionDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/profession/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 修改专业|patch
     * 
     * @param id 
     * @param body 
     **/
    public patch(
        id:string,
        body?:ProfessionPatchInput,
        options:HttpOption={},
   ): Promise<ProfessionDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/profession/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 新增专业|post
     * 
     * @param body 
     **/
    public post(
        body?:ProfessionPostInput,
        options:HttpOption={},
   ): Promise<ProfessionDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/profession`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 专业查询|query
     * 
     * @param queryParams:{
          * @param key 关键字
          * @param parentId 父级id
          * @param state data state
          * @param tree 是否获取子集
       },
     **/
    public query(
        queryParams?:{
          key?: string,
          parentId?: string,
          state?: string,
          tree?: boolean,
        },
        options:HttpOption={},
   ): Promise<ProfessionDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['key'])) {
                params['Key']=<any>queryParams['key']
        }
        if (queryParams&&!this.ce(queryParams['parentId'])) {
                params['ParentId']=<any>queryParams['parentId']
        }
        if (queryParams&&!this.ce(queryParams['state'])) {
                params['State']=<any>queryParams['state']
        }
        if (queryParams&&!this.ce(queryParams['tree'])) {
                params['Tree']=<any>queryParams['tree']
        }
        let path = `/common/api/v5/profession`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 更新排序|sort
     * 
     * @param body 
     **/
    public sort(
        body?:Array<SortInput>,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/profession/sort`;
	    path = this.url(path);
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

}

const professionInstance= new ProfessionService()
export default professionInstance
