import { ProfileDto } from "@/api/src/userCenter/model/models";
import Storage from "@/core/storage";


const authPersistence = (store: any) => {
    // 当 store 初始化后调用
    store.subscribe((mutation: { type: string, payload: any }, state: any) => {
        // 每次 mutation 之后调用
        switch (mutation.type) {
            case "authStore/setToken":
                if (mutation.payload) {
                    Storage.set("micro_rc_token", JSON.stringify(mutation.payload));
                } else {
                    Storage.remove("micro_rc_token");
                }
                break;
            case "authStore/setUser":
                if (mutation.payload) {
                    const user = mutation.payload as ProfileDto;
                    Storage.set("micro_rc_user", JSON.stringify(user));
                } else {
                    Storage.remove("micro_rc_user");
                }
                break;
            case "authStore/setOrgId":
                if (mutation.payload) {
                    const orgId = mutation.payload;
                    Storage.set("micro_rc_orgId", orgId);
                } else {
                    Storage.remove("micro_rc_orgId");
                }
                break;
            case "authStore/setOrgs":
                if (mutation.payload) {
                    Storage.set("micro_rc_orgs", JSON.stringify(mutation.payload));
                } else {
                    Storage.remove("micro_rc_orgs");
                }
                break;
            case "authStore/setProject":
                if (mutation.payload) {
                    Storage.set("micro_rc_project", JSON.stringify(mutation.payload));
                } else {
                    Storage.remove("micro_rc_project");
                }
                break;
            case "authStore/setEnterprise":
                if (mutation.payload) {
                    Storage.set("micro_rc_enterprise", JSON.stringify(mutation.payload));
                } else {
                    Storage.remove("micro_rc_enterprise");
                }
                break;
            case "authStore/clear":
                Storage.remove("micro_rc_token");
                Storage.remove("micro_rc_user");
                Storage.remove("micro_rc_orgs");
                Storage.remove("micro_rc_project");
                Storage.remove("micro_rc_orgId");
                break;

        }

    })
}

export default authPersistence;