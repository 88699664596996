
import BasicLayout from '../desktop/BasicLayout.vue';
import BlackLayout from '../desktop/BlackLayout.vue';
import MicroLayout from '../desktop/MicroLayout.vue';
import VueApp from "../views/VueApp.vue";
import ProjectInit from "@/ricent/project/index.vue";
import projectInitDetail from "@/ricent/project/details/index.vue";
import versionUpdate from "@/ricent/system/version/index.vue";
import Empty from "../views/Empty.vue";

export const name2Component: Record<string, any> = {
    "BasicLayout": BasicLayout,
    "BlackLayout": BlackLayout,
    "MicroLayout": MicroLayout,
    "VueApp": VueApp,
    "projectInit": ProjectInit,
    "projectInitDetail": projectInitDetail,
    "Empty":Empty
};