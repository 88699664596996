
import { RouteRecordRaw } from 'vue-router';
import BasicLayout from '../desktop/BasicLayout.vue';
import BlackLayout from '../desktop/BlackLayout.vue';
import VueApp from "@/views/VueApp.vue";
import { microAppEnvConfig } from './microAppSettings';
import Home from "../ricent/home/home.vue";

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
    {
        path: '/login',
        name: "login",
        component: () => import(/* webpackChunkName: "user" */ '@/auth/login.vue'),
    },
]

export function componentSwith(microComponet: any, normalComponet: any) {
    const isMicro = microAppEnvConfig.__MICRO_APP_ENVIRONMENT__;
    if (isMicro) {
        return microComponet;
    }
    return normalComponet;
}

export const asyncRouterMap: RouteRecordRaw[] = [
    {
        path: '/',
        redirect: () => ({ name: 'organize' }),
    },
    {
        path: '/organize',
        name: 'organize',
        meta: { title: '欢迎', icon: 'icon-antdesign' },
        component: BlackLayout,
        children: [
            {
                path: ':orgId',
                name: "orgId",
                redirect: () => ({ name: 'home' }),
                meta: { icon: 'icon-icon-test' },
                component: componentSwith(BlackLayout, BasicLayout),
                children: [
                    {
                        meta: {
                            title: '',
                            icon: 'icon-home',
                            isMeun: true,
                        },
                        path: 'home',
                        name: 'home',
                        component: Home
                    },
                ]
            },
        ],
    },
];



