<template>
  <router-view :key="key" />
</template>

<script>
export default {
  name: "MicroLayout",
  computed: {
    key() {
      return this.$route.name !== undefined
        ? this.$route.name + new Date()
        : this.$route + new Date();
    },
  },
};
</script>

<style scoped>
</style>
