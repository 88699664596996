import { constantRouterMap } from "@/config/routerSettings";
import { PermissionState } from "./dto";


const state: PermissionState = {
    routers: constantRouterMap,
    addRouters: null,
};

export default {
    namespaced: true,
    state,
    getters: {
        getAddRouters: (permissionState: PermissionState) => {
            return permissionState.addRouters;
        },
        getRouters: (permissionState: PermissionState) => {
            return permissionState.routers;
        },
    },
    mutations: {
        SET_ROUTERS: (state: Record<string, any>, routers: any[]) => {
            state.addRouters = routers
            state.routers = constantRouterMap.concat(routers)
        }
    }
};
