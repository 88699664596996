import { GetPhoneDtoRicResponse } from '../model/getPhoneDtoRicResponse';
import { LoginInput } from '../model/loginInput';
import { OpcodeCheckInput } from '../model/opcodeCheckInput';
import { OpcodeCreateInput } from '../model/opcodeCreateInput';
import { OpcodeDtoRicResponse } from '../model/opcodeDtoRicResponse';
import { OpcodeValidateInput } from '../model/opcodeValidateInput';
import { OpcodeValidateOutputRicResponse } from '../model/opcodeValidateOutputRicResponse';
import { ProfileDtoRicResponse } from '../model/profileDtoRicResponse';
import { PutPasswordInput } from '../model/putPasswordInput';
import { PutPhoneInput } from '../model/putPhoneInput';
import { RicNullRicResponse } from '../model/ricNullRicResponse';
import { ThirdPartyDtoListRicResponse } from '../model/thirdPartyDtoListRicResponse';
import { ThirdPartyDtoRicResponse } from '../model/thirdPartyDtoRicResponse';
import { ThirdPartyPutInput } from '../model/thirdPartyPutInput';
import { UpdateUserInput } from '../model/updateUserInput';
import { UserCancelInput } from '../model/userCancelInput';
import { UserDtoListRicResponse } from '../model/userDtoListRicResponse';
import { UserDtoRicResponse } from '../model/userDtoRicResponse';
import { UserPostInput } from '../model/userPostInput';
import { UserQueryByIdsInput } from '../model/userQueryByIdsInput';
import { WeChatBindingInput } from '../model/weChatBindingInput';
import { WeChatPostInput } from '../model/weChatPostInput';
import { WeChatValidateInput } from '../model/weChatValidateInput';
import { WeChatValidateOutputRicResponse } from '../model/weChatValidateOutputRicResponse';
import { Api } from '../../api';
import { HttpOption} from "@/core/http";

/* tslint:disable:no-unused-variable member-ordering */



export class UserCenterService  extends Api  {
  constructor() {
    super();
  }
    /**
     * 删除第三方关联|deleteThirdParty
     * 
     * @param id 
     
     **/
    public deleteThirdParty(
        id:string,
        
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/third-party/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 获取|get
     * 
     * @param id 
     
     **/
    public get(
        id:string,
        
        options:HttpOption={},
   ): Promise<UserDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 验证账号密码|getByPhone
     * 
     * @param phone 
     
     **/
    public getByPhone(
        phone:string,
        
        options:HttpOption={},
   ): Promise<UserDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/phone/${encodeURIComponent(String(phone))}`;
	    path = this.url(path, { phone:phone });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 查看手机号|getPhone
     * 
     * @param id 
     
     **/
    public getPhone(
        id:string,
        
        options:HttpOption={},
   ): Promise<GetPhoneDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/${encodeURIComponent(String(id))}/phone`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * (微信)获取用户|getWeChat
     * 
     * @param id 
     
     **/
    public getWeChat(
        id:string,
        
        options:HttpOption={},
   ): Promise<ThirdPartyDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/wechat/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 检查授权|opcodeCheck
     * 
     * @param body 
     **/
    public opcodeCheck(
        body?:OpcodeCheckInput,
        options:HttpOption={},
   ): Promise<OpcodeDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/opcode/check`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 发送短信|opcodeCreate
     * 
     * @param body 
     **/
    public opcodeCreate(
        body?:OpcodeCreateInput,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/opcode`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 验证手机验证码|opcodeValidate
     * 
     * @param body 
     **/
    public opcodeValidate(
        body?:OpcodeValidateInput,
        options:HttpOption={},
   ): Promise<OpcodeValidateOutputRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/opcode/validate`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 修改手机号|patchPhone
     * 
     * @param id 
     * @param body 
     **/
    public patchPhone(
        id:string,
        body?:PutPhoneInput,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/${encodeURIComponent(String(id))}/phone`;
	    path = this.url(path, { id:id });
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 创建用户|post
     * 
     * @param body 
     **/
    public post(
        body?:UserPostInput,
        options:HttpOption={},
   ): Promise<UserDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * (微信)创建用户|postWeChat
     * 
     * @param body 
     **/
    public postWeChat(
        body?:WeChatPostInput,
        options:HttpOption={},
   ): Promise<ThirdPartyDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/wechat`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * (微信)绑定账号 指定第三方信息绑定  只会在用户登录时使用|postWeChatBinding
     * 
     * @param body 
     **/
    public postWeChatBinding(
        body?:WeChatBindingInput,
        options:HttpOption={},
   ): Promise<UserDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/wechat/binding`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * (微信)验证账号|postWeChatValidate
     * 
     * @param body 
     **/
    public postWeChatValidate(
        body?:WeChatValidateInput,
        options:HttpOption={},
   ): Promise<WeChatValidateOutputRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/wechat/validate`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 获取个人信息|profile
     * 
     
     **/
    public profile(
        
        options:HttpOption={},
   ): Promise<ProfileDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/profile`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 用户注销|profile
     * 
     * @param body 
     **/
    public profile_1(
        body?:UserCancelInput,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/cancel`;
	    path = this.url(path);
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 全量更新|put
     * 
     * @param id 
     * @param body 
     **/
    public put(
        id:string,
        body?:UpdateUserInput,
        options:HttpOption={},
   ): Promise<UserDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'put',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 修改密码|putPassword
     * 
     * @param id 
     * @param body 
     **/
    public putPassword(
        id:string,
        body?:PutPasswordInput,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/${encodeURIComponent(String(id))}/password`;
	    path = this.url(path, { id:id });
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 修改第三方关联|putThirdParty
     * 
     * @param body 
     **/
    public putThirdParty(
        body?:ThirdPartyPutInput,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/third-party`;
	    path = this.url(path);
        return this.request(
            'put',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 局部更新|put
     * 
     * @param id 
     * @param body 
     **/
    public put_2(
        id:string,
        body?:UpdateUserInput,
        options:HttpOption={},
   ): Promise<UserDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 查询|query
     * 
     * @param queryParams:{
          * @param key 关键字
       },
     **/
    public query(
        queryParams?:{
          key?: string,
        },
        options:HttpOption={},
   ): Promise<UserDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['key'])) {
                params['Key']=<any>queryParams['key']
        }
        let path = `/user-center/api/v5/users`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 查询根据Ids|queryByIds
     * 
     * @param body 
     **/
    public queryByIds(
        body?:UserQueryByIdsInput,
        options:HttpOption={},
   ): Promise<UserDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/ids/query`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 查询|queryThirdParty
     * 
     
     **/
    public queryThirdParty(
        
        options:HttpOption={},
   ): Promise<ThirdPartyDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/third-party`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 验证账号密码|validate
     * 
     * @param body 
     **/
    public validate(
        body?:LoginInput,
        options:HttpOption={},
   ): Promise<UserDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/users/validate`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

}

const userCenterInstance= new UserCenterService()
export default userCenterInstance
