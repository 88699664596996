import { AuthCodeDtoRicResponse } from '../model/authCodeDtoRicResponse';
import { AuthCodeInput } from '../model/authCodeInput';
import { RicNullRicResponse } from '../model/ricNullRicResponse';
import { Api } from '../../api';
import { HttpOption} from "@/core/http";

/* tslint:disable:no-unused-variable member-ordering */



export class AuthCodeService  extends Api  {
  constructor() {
    super();
  }
    /**
     * PC轮询检查授权码|get
     * 
     * @param authCode 授权码
     
     **/
    public get(
        authCode:string,
        
        options:HttpOption={},
   ): Promise<AuthCodeDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/auth-code/${encodeURIComponent(String(authCode))}/check`;
	    path = this.url(path, { authCode:authCode });
        return this.request(
            'post',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * APP确认进行登录|login
     * 
     * @param authCode 
     
     **/
    public login(
        authCode:string,
        
        options:HttpOption={},
   ): Promise<AuthCodeDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/auth-code/${encodeURIComponent(String(authCode))}/login`;
	    path = this.url(path, { authCode:authCode });
        return this.request(
            'post',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * PC创建授权码|post
     * 
     * @param body 
     **/
    public post(
        body?:AuthCodeInput,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/auth-code`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * APP设置授权码|scan
     * 
     * @param authCode 
     
     **/
    public scan(
        authCode:string,
        
        options:HttpOption={},
   ): Promise<AuthCodeDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/user-center/api/v5/auth-code/${encodeURIComponent(String(authCode))}/scan`;
	    path = this.url(path, { authCode:authCode });
        return this.request(
            'post',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

}

const authCodeInstance= new AuthCodeService()
export default authCodeInstance
