// vue3 编写
<template>
  <a-card>
    <div class="project-item" @click="goDetail(1)">
      <a-space direction="vertical" :size="8">
        <div class="project-title">{{ item.name }}</div>
        <div class="project-principal">{{ principal }}</div>
        <div class="project-status">
          <a-tag :color="status.color">{{ status.title }}</a-tag>
        </div>
      </a-space>
      <div class="project-setting">
        <span class="item" @click.stop.prevent="goDetail(2)">楼栋配置</span>
        <span class="dividing-line"></span>
        <span class="item" @click.stop.prevent="goDetail(3)">图纸管理</span>
        <span class="dividing-line"></span>
        <span class="item" @click.stop.prevent="goDetail(4)">参与成员</span>
      </div>
    </div>
  </a-card>
</template>

<script>
import { computed } from "vue";
import router from "@/router";
import store from "@/store";
export default {
  name: "ProjectItem",
  props: {
    item: Object,
  },
  setup(props) {
    let item = props.item;

    let status = computed(() => {
      return changeStatus(item.status);
    });

    let principal = computed(() => {
      return item.members.map((i) => i.name).join("、");
    });

    const changeStatus = (status) => {
      let tmp = {
        color: null,
        title: null,
      };
      switch (status) {
        case 2:
          tmp.color = "processing";
          tmp.title = "在建";
          break;
        case 1:
          tmp.color = "warning";
          tmp.title = "未动工";
          break;
        case 4:
          tmp.color = "success";
          tmp.title = "已交付";
        default:
          tmp.color = "warning";
          tmp.title = "未动工";
          break;
      }
      return tmp;
    };

    const goDetail = (tabIndex) => {
      store.commit("authStore/setProject", item);
      router.push({
        name: "details",
        query: {
          defautIndex: tabIndex,
        },
      });
    };
    return {
      item,
      principal,
      status,
      goDetail,
    };
  },
};
</script>

<style scoped lang='less'>
@import "../../style/layout/ricent.less";
@import "../../global.less";
/deep/ .ant-card-body {
  padding: 12px;
}
.ant-space {
  width: 100%;
}
.ant-card {
  margin-right: @spacing-tight;
}
.project-item {
  width: 224px;
  height: 128px;
  position: relative;
  .project-title {
    // width: 205px;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .project-principal {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    max-height: 32px;
    font-family: PingFangSC-Regular;
    font-size: @font-size-regular;
    font-weight: 0;
    line-height: 16px;
    /* 铅灰/铅灰--次要--400 */
    color: #888d92;
  }
  .project-setting {
    position: absolute;
    width: 213px;
    bottom: 0px;
    // margin-top: 16px;
    display: flex;
    justify-content: space-between;
    .item {
      font-family: PingFangSC-Regular;
      font-size: @font-size-regular;
      font-weight: 0;
      line-height: 16px;
      /* 主色/蓝--500 */
      color: @primary-6;
      &:hover {
        cursor: pointer;
      }
    }
    .dividing-line {
      width: 1px;
      height: 16px;
      background: #e6e8ea;
    }
  }
  // &:hover {
  //   cursor: pointer;
  //   box-shadow: inset 0 0 1px 1px #ccc;
  // }
}
</style>