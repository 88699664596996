import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bfe21c12"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createBlock(_component_a_table, {
    "row-selection": {
      selectedRowKeys: _ctx.selectedRowKeys,
      onChange: _ctx.onchange,
    },
    columns: _ctx.columns,
    "data-source": _ctx.data,
    rowKey: "id"
  }, {
    name: _withCtx(({ record }) => [
      _createElementVNode("a", {
        onClick: ($event: any) => (_ctx.viewer(record))
      }, _toDisplayString(record.name), 9, _hoisted_1)
    ]),
    action: _withCtx(({ record }) => [
      _createVNode(_component_a_space, null, {
        default: _withCtx(() => [
          _createElementVNode("a", {
            onClick: ($event: any) => (_ctx.rename(record))
          }, "重命名", 8, _hoisted_2),
          _createElementVNode("a", {
            onClick: ($event: any) => (_ctx.deleteItem(record))
          }, "删除", 8, _hoisted_3)
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["row-selection", "columns", "data-source"]))
}