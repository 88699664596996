
import { message } from "ant-design-vue";
import { Vue } from "vue-class-component";
// import "tui-image-editor/dist/tui-image-editor.css";
// import "tui-color-picker/dist/tui-color-picker.css";
// import ImageEditor from "tui-image-editor";

export default class Home extends Vue {
  handleClick() {
    this.$api.userCenter.profile();
    message.info("BackHome button clicked!");
  }
}
