import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_upload_dragger = _resolveComponent("a-upload-dragger")!

  return (_openBlock(), _createBlock(_component_a_upload_dragger, {
    "file-list": _ctx.fileList,
    "before-upload": _ctx.beforeUpload,
    multiple: true,
    accept: ".jpg,.png,.jpeg,.pdf"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["file-list", "before-upload"]))
}