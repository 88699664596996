import { LoginOutputRicResponse } from '../model/loginOutputRicResponse';
import { PhoneVerificationInput } from '../model/phoneVerificationInput';
import { RicNullRicResponse } from '../model/ricNullRicResponse';
import { SmsSendInput } from '../model/smsSendInput';
import { SmsValidateInput } from '../model/smsValidateInput';
import { WeChatBindingInput } from '../model/weChatBindingInput';
import { WeChatLoginInput } from '../model/weChatLoginInput';
import { WeChatLoginOutputRicResponse } from '../model/weChatLoginOutputRicResponse';
import { WechatSignatureOutputRicResponse } from '../model/wechatSignatureOutputRicResponse';
import { Api } from '../../api';
import { HttpOption} from "@/core/http";

/* tslint:disable:no-unused-variable member-ordering */



export class AuthService  extends Api  {
  constructor() {
    super();
  }
    /**
     * 登录授权|login
     * 
     * @param userName 
     * @param grantType 
     * @param password 
     
     **/
    public login(
        userName?:string,   
        grantType?:string,   
        password?:string,   
        
        options:HttpOption={},
   ): Promise<LoginOutputRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        const formParams = new URLSearchParams();
        headers['Content-Type']='application/x-www-form-urlencoded'
        if (userName !== undefined) {
                formParams.append('UserName',<any>userName)
        }
        if (grantType !== undefined) {
                formParams.append('Grant_Type',<any>grantType)
        }
        if (password !== undefined) {
                formParams.append('Password',<any>password)
        }
        let path = `/auth/api/v5/login`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: formParams.toString(),
                headers,
                params
           }),
        );
    }

    /**
     * 手机号验证登录|phoneVerification
     * 
     * @param body 
     **/
    public phoneVerification(
        body?:PhoneVerificationInput,
        options:HttpOption={},
   ): Promise<LoginOutputRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/auth/api/v5/phone-verification`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 发送短信|smsSend
     * 
     * @param body 
     **/
    public smsSend(
        body?:SmsSendInput,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/auth/api/v5/sms/send`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 手机号验证登录|smsValidate
     * 
     * @param body 
     **/
    public smsValidate(
        body?:SmsValidateInput,
        options:HttpOption={},
   ): Promise<LoginOutputRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/auth/api/v5/sms/validate`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 微信绑定|wechatBinding
     * 
     * @param body 
     **/
    public wechatBinding(
        body?:WeChatBindingInput,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/auth/api/v5/wechat/binding`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 微信登录|wechatLogin
     * 
     * @param body 
     **/
    public wechatLogin(
        body?:WeChatLoginInput,
        options:HttpOption={},
   ): Promise<WeChatLoginOutputRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/auth/api/v5/wechat`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 获取微信签名|wechatSignature
     * 
     * @param queryParams:{
          * @param url 
       },
     **/
    public wechatSignature(
        queryParams?:{
          url?: string,
        },
        options:HttpOption={},
   ): Promise<WechatSignatureOutputRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['url'])) {
                params['url']=<any>queryParams['url']
        }
        let path = `/auth/api/v5/wechat/signature`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

}

const authInstance= new AuthService()
export default authInstance
