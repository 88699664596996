import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_result = _resolveComponent("a-result")!

  return (_openBlock(), _createBlock(_component_a_result, {
    status: "404",
    style: {
      height: '100%',
    },
    title: "页面正在构建中、请保持耐心...",
    "sub-title": "我们暂时还没有实现这个页面."
  }, {
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}