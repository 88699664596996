//token 用户从未登入过token丢失问题
export const UNAUTHORIZED_401 = "unauthorized_401";
export const ROUTER_CHANGE = "unauthorized_401";
export const OGRCHANGE = "org:change";
export const OGRENTERPRIESES = "org:enterprises"
export interface HttpResponseError {
    status: number,
    message?: string,
    stack?: string,
    config?: any,
    response: any,
}