
import { Ricent } from "@/core/ricent";
import { BehaviorSubject } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
export default class RcFileUploadDrag extends Vue {
  public fileList: any = [];
  public fileEvent: BehaviorSubject<any> = new BehaviorSubject(null);
  @Prop()
  groupId!: string;
  @Prop()
  onlyDrag!: boolean;

  mounted() {
    setTimeout(() => {
      if (this.onlyDrag) {
        let file = this.$el.querySelector('input[type="file"]');
        file?.setAttribute("disabled", "true");
      }
      let sp = this.$el.querySelector(".ant-upload-btn");
      sp.style.padding = "0px";
      sp.style.display = "block";
      sp.style.overflow = "auto";
    }, 0);
  }
  created() {
    this.fileEvent
      .pipe(
        debounceTime(100),
        filter((_) => !!_)
      )
      .subscribe((_) => {
        let files = this.fileList.map((_: any) => {
          _.id = _.uniqueId = Ricent.uuid();
          _.file = _;
          _.group = this.groupId;
          _.status = "uploading";
          return _;
        });
        this.$emit("file:select", files);
        this.fileList = [];
      });
  }

  beforeUpload(file: any) {
    this.fileList = [...this.fileList, file];
    this.fileEvent.next(Date.now);
    return false;
  }
}
