
import { createApp } from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import authPlus, { authService } from './auth/authService'
import eventPlus from './core/bus'
import lazyLoadPlus from './core/lazyload'
import Antd, { notification } from 'ant-design-vue';
import './global.less' // global style
import ProLayout, { PageContainer } from '@ant-design-vue/pro-layout';
import i18nPlus from './locales'
import apiPlus from './api/src/apiService'
// 导入图标css
import './assets/icons/iconfont.css';
import './assets/css/viewer.min.css';
import { microAppEnvConfig } from './config/microAppSettings'
import lazyPlugin from 'vue3-lazy'
import { Ricent } from './core/ricent'
import fileTaskLoadPlus from './core/fileTask'
import { createFromIconfontCN } from '@ant-design/icons-vue';
import appConfig from '@/config/defaultSettings'
const IconFont = createFromIconfontCN({
    scriptUrl: appConfig.iconfontUrl,
});
const app = createApp(App);
app.component('IconFont', IconFont);
app
    .use(store)
    .use(router)
    .use(eventPlus)
    .use(lazyLoadPlus)
    .use(apiPlus)
    .use(authPlus)
    .use(i18nPlus)
    .use(Antd)
    .use(fileTaskLoadPlus)
    .use(ProLayout)
    .use(PageContainer).use(lazyPlugin, {
        loading: '/img/loading.gif',
    })
    .mount('#app')


Ricent.set(
    process.env.VUE_APP_Name
)

if (!microAppEnvConfig.__MICRO_APP_ENVIRONMENT__) {
    authService
        .profile()
        .then((_) => {
            return authService.getOrgs();
        });

}


