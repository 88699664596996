import { ApiClient } from './apiService';
import auth  from './auth/api/api';
import common  from './common/api/api';
import files  from './files/api/api';
import org  from './org/api/api';
import userCenter  from './userCenter/api/api';


export const apiClient: ApiClient = {
   ...auth,
   ...common,
   ...files,
   ...org,
   ...userCenter,
}
