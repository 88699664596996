
import { microAppEnvConfig } from "@/config/microAppSettings";
import { name2Component } from "@/router/name2Component";
import { registerMicroRouter } from "@/router";

export class BeaverRouterPlusgenerater {


    tmplate: any = {
        meta: {
            //微应用名称
            appName: "microApp",
            //微应用地址
            appUrl: process.env.VUE_APP_MiCRO_APPURL || "",
        },
        path: "micro/:page*",
        name: "micro",
        component: "BlackLayout",
        //todo 只有在children里面的路由菜单才会渲染
        children: [
            {
                meta: {
                    title: '项目',
                    breadcrumbName: "项目初始化",
                    icon: 'icon-clique',
                    isMeun: true,
                },
                path: 'project',
                name: 'project',
                children: [
                    {
                        meta: {
                            title: '项目初始化',
                            icon: 'icon-horn',
                            isMeun: true,
                        },
                        path: 'index',
                        name: 'index',
                        component: "projectInit",
                    },
                    {
                        meta: {
                            title: '项目详情',
                            icon: 'icon-horn',
                            breadcrumbName: "项目详情",
                        },
                        path: 'details',
                        name: 'details',
                        component: "projectInitDetail",
                    },
                ],
                component: "BlackLayout",
            },
        ]
    };

    useMicro(isRoot: boolean): boolean {
        // let isBaseApp: boolean = !microAppEnvConfig.__MICRO_APP_ENVIRONMENT__;
        // let isDebug = window.location.href.indexOf("localhost") > -1
        // return isBaseApp && isRoot && !isDebug;
        return false;
    }

    pruning(router: any, isRoot: boolean) {
        //布局组件
        let layoutKeys = ["BlackLayout", "BasicLayout", "MicroLayout"];
        if (!router.component || layoutKeys.some((_: string) => _ == router.component)) {
            if (router.children && router.children.length) {
                router.redirect = () => ({ name: router.children[0].name })
            }
        }
        let paths = router.path.split("/");
        let path: string = paths.pop();
        if (this.useMicro(isRoot)) {
            router.component = name2Component["VueApp"];
            if (!/^:.*\*$/.test(path)) {
                paths.push(":page*")
                router.path = paths.join("/");
            }

        } else {
            if ((Object.prototype.toString.call(router.component) == "[object String]")) {
                router.component = name2Component[router.component];
            }
            if (/^:.*\*$/.test(path)) {
                router.path = paths.join("/");
            }
        }
        if (router.children) {
            router.children.forEach((item: any) => this.pruning(item, false));
        }
    }


    getTempate() {
        return Promise.resolve(this.tmplate);
    }

    generator(): Promise<any> {
        return this.getTempate().then((tp: any) => {
            this.pruning(tp, true);
            registerMicroRouter(tp);
            return tp;
        })
    }
}




export default {
    generator: function () {
        return new BeaverRouterPlusgenerater().generator();
    }
}