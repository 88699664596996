//java -jar cli.jar generate -i http://localhost:9001/docs/v2/api.json -o api -l typescript-angular -t ./ng2
import  authCodeInstance,{ AuthCodeService }  from './authCode.service';
import  userCenterInstance,{ UserCenterService }  from './userCenter.service';
import { ApiClient } from '../../apiService';

declare module '../../apiService' {
  export interface ApiClient{

    authCode:AuthCodeService,

    userCenter:UserCenterService,
  }
}


const apiClient={

  authCode:authCodeInstance,

  userCenter:userCenterInstance,
}
export default apiClient

