
import { Api } from '@/api/src/api';
import { Ricent } from '@/core/ricent';
import { name2Component } from "@/router/name2Component";
import { registerMicroRouter } from "@/router";
import { menu_assesmentReport, menu_enterprise, menu_evaluationPlan } from './czcMenu';
import { ricentBus } from '@/core/bus';
import { OGRENTERPRIESES } from '@/core/globalEvent';
import { authService } from '@/auth/authService';
const isDebug = false;
const api: Api = new Api();
const definedCfgOb: any = { has: 1, add: 2, edit: 4, del: 8, down: 16, copy: 32 }
class BaseConfigMap {
    value?: string | number;
    has?: boolean;
    add?: boolean;
    del?: boolean;
    edit?: boolean;
    down?: boolean;
    copy?: boolean;

    constructor(cfg: any, role: any, definedCfgOb: any) {
        this.value = cfg || null;
        if (cfg && role) {
            const _that: any = this;
            const cfgarr = Object.keys(cfg);
            Object.keys(definedCfgOb).forEach(cf => {
                if (cfgarr.some(it => !!(Number(it) & role) && !!(definedCfgOb[cf] & cfg[it]))) {
                    _that[cf] = true
                }
            })
        }
    }
}
interface CodeMap {
    value?: any;
    config?: any;
    [key: string]: BaseConfigMap | CodeMap | string;
}
/**
 * 基础
 */
const settings = {
    default: {  // 在paramters-config-default里配置的数据无需在这里添加，但数据需要加上link对应的属性
        superadminaccounts: {
            remark: '超级管理员手机号',
            value: "'13800000000,15902059380,13207321362'"
        }
    }
}

/**
 * 组织
 */
const organization = {
    default: {
        config: {
            '2': 'all',
        }
    }
}
/**
 * 用户管理
 */
const userManage = {
    default: {
        config: {
            '2': 'all',
            '8': 'all',
        },
    }
}

/**
 * 产品类型
 */
const productType = {
    default: {
        remark: '产品类型',
        config: {
            '1': 'all',
        },
    },
    ridge: {
        config: {
            '2': 'all',
            '16': 1,
            '64': 1,
        },
        // jianBaoMoBan: {
        //     remark: '简报模板配置',
        //     config: {
        //         '1': 'all',
        //     },

        // }
    }
}
/**
 * 客户供应商
 */
const partenerManage = {
    default: {
        config: {
            '2': 'all',
            '8': 'all',
        },
    }
}

/**
 * 标准库
 */
const standardManage = {
    default: {
        config: {
            '2': 'all',
        },
    }
}

/**
 * 评估模板管理
 */
const assessmentModuleManage = {
    default: {
        config: {
            '2': 'all',
        },
    }
}

/**
 * 工艺工法
 */
const craftManage = {
    default: {
        config: {
            '2': 'all',
        },
    }
}

/**
 * 工艺工法
 */
const assessmentHelp = {
    default: {
        remark: '工艺工法',
        config: {
        },
    },
    ridge: {
        config: {
            '2': 'all',
        },
    },

}

/**
 * 客户管理
 */
const customerManage = {
    default: {
        remark: '客户管理',
        config: {
            '2': 'all',
            '8': 'all',
            '64': 1,
        },

        customerSystem: {
            remark: '客户系统管理（客户人员管理，接口参数配置）',
            config: {
                '2': 'all',
                '8': 'all',
            },
        },

        customerInfo: {
            remark: '客户信息管理（项目标段）',
            config: {
                '2': 'all',
                '8': 'all',
                '16': 'all',
                '64': 1,
            },
        },
    }
}

/**assessment.pc.system_config.version.config.edit
 * 客户评估体系配置
 */
const system_config = {
    default: {
        remark: '客户评估体系配置 - 客户管理',
        config: {
            '2': 'all',
            '8': 'all',
            '16': 'all',
            '64': 1,
        },
        version: {
            remark: '评估体系版本配置',
            config: {
                '2': 'all',
                '8': 'all',
                '16': 'all',
                '64': 1,
            },


        },
        systemItem: {
            remark: '评估分部、分项、检查项检查项配置',
            config: {
                '2': 'all',
                '8': 'all',
                '16': 'all',
                '64': 1,
            },
        },

        briefingTemplate: {
            remark: '简报模板管理',
            config: {
                '1': 'all',
            },
        },
        briefing: {
            remark: '简报模板选择',
            config: {
                '1': 'all',
                '2': 1 | 4,
            },

        },


    },
    ridge: {
        remark: '客户评估体系配置 - 客户管理',
        config: {
            '2': 'all',
            '8': 'all',
            '16': 'all',
            '64': 1,
        },
        version: {
            remark: '评估体系版本配置',
            config: {
                '2': 'all',
                '8': 'all',
                '16': 'all',
                '64': 1,
            },

            systemItemManage: {
                remark: '评估分部、分项、检查项检查项配置',
                config: {
                    '2': 'all',
                    '8': 'all',
                    '16': 'all',
                    '64': 1,
                },
            }

        },

        briefingModuleManage: {
            remark: '简报模板管理',
            config: {
                '1': 'all',
            },
            jianBaoMoBan: {
                remark: '简报模板选择',
                config: {
                    // '1': 'all',
                    // '2': (1 | 2 | 4 | 8),
                    '2': 1 | 4,
                    '8': 1 | 4,
                    '16': 1 | 4,
                },

            }
        }


    }
}

/**
 * 评估计划管理
 */
const plan_manage = {
    default: {
        remark: '评估计划管理',
        config: {
            '2': 'all',
            '8': (1 | 2 | 4 | 16 | 32),
            '16': 'all',
            '64': 1,
        },
        query_all_item: {
            remark: '查询所有计划，没有权限的只查当前层级的计划，开启后subjectId = null',
            config: {
                '2': 1,
            },
        },
        planReport: {
            remark: '评估任务 和 评估总报告切换tab',
            config: {
                '2': 1,
                '8': 1,
            },
        },
        asyncPlan: {
            remark: '同步计划',
            config: {
                '2': 1,
                '8': 1,
                '16': 1,
            },
        },
        summaryData: {
            remark: '汇总数据下载',
            config: {
                '2': 16,
                '8': 16,
                '16': 16,
                '64': 16,
                '128': 16,
            },
        },

    }
}

/**
 * 评估管理
 */
const planItemManage = {
    default: {
        remark: '评估任务管理',
        config: {
            '2': 'all',
            '8': (1 | 2 | 4 | 8 | 16 | 32),
            '16': (1 | 2 | 4 | 16 | 32),
            '64': 1,
            '128': 1,
        },
        chouCeQu: {
            remark: '抽取测区',
            config: {
                '2': 'all',
                '8': 'all',
                '16': 1,
            },
        },
        handleMore: {
            remark: '更多操作',
            config: {
                '2': 'all',
                '8': 'all',
                '16': 'all',
            },
        },
        bianGengZuYuan: {
            remark: '变更组员',
            config: {
                '2': 'all',
                '8': 'all',
                '16': 'all',
            },
        },
        xinxiBianJi: {
            remark: '评估任务信息编辑',
            config: {
                '2': 'all',
                '8': 'all',
                '16': 'all',
            },
        },
        shuiYinBianJi: {
            remark: '水印编辑',
            config: {
                '2': 'all',
                '8': 'all',
            },
        },
        // zongJieHUI: {
        //     remark: '总结会',
        //     config: {
        //         '8': 'all',
        //     },
        // },
        jianBao: {
            remark: '简报下载',
            config: {
                '2': 16,
                '8': 16,
                '16': 16,
            },
        },
        jianBaoMake: {
            remark: '手写简报管理（上传、删除、下载）',
            config: {
                '2': 'all',
                '8': 'all',
            },
            value: '简报/数据表'
        },
        problemPhoto: {
            remark: '问题照片下载',
            config: {
                '2': 16,
                '8': 16,
                '16': 16,
            },
        },
    },
}


/**
 * 评估问题
 */
const problem_arbitration = {
    default: {  // 在paramters-config-default里配置的数据无需在这里添加，但数据需要加上link对应的属性
    }
}
/**
 * 邮件模板管理
 */
const emailTemplate = {
    default: {  // 在paramters-config-default里配置的数据无需在这里添加，但数据需要加上link对应的属性
        config: {
            '1': 'all',
        },
    }
}
const permission: any = {
    settings,                   // 基础
    organization,               // 组织
    userManage,                 // 用户管理
    productType,                // 产品类型
    partenerManage,             // 客户供应商
    standardManage,             // 标准库
    assessmentModuleManage,     // 评估模板管理
    craftManage,                // 工艺工法
    assessmentHelp,             // 帮助
    customerManage,             // 客户管理
    system_config,              // 评估体系配置
    plan_manage,                // 评估计划管理
    planItemManage,             // 评估任务管理
    emailTemplate,              // 邮件模板管理
    problem_arbitration
}


const initPermission = (role: any, customer: any = null): CodeMap => {
    if (Array.isArray(role)) {
        role = role.reduce((tol, r) => tol + Number(r), 0)
    }
    const roleTotal = Object.keys(definedCfgOb).reduce((tol, role) => tol + definedCfgOb[role], 0);
    const codemap: any = {};
    const fn = (config: any, role: any): BaseConfigMap => {
        config && Object.keys(config).forEach(it => {
            let v: any = null;
            if (config[it] == 'all' || !!(role & 1)) {
                v = roleTotal;
                if (!!(role & 1)) {
                    config['1'] = v;
                }
            } else {
                try {
                    try {
                        v = window.eval('(' + config[it] + ')');
                    } catch (ex1) {
                        v = config[it];
                    }
                } catch (e) {
                    console.log('error:', config[it]);
                }
            }
            config[it] = v;
        })
        return new BaseConfigMap(config, role, definedCfgOb)
    }

    Object.keys(permission).forEach(it => {
        codemap[it] = customer ? permission[it][customer.toLocaleLowerCase()] || permission[it]['default'] || null : permission[it]['default'] || null
    })
    const recursionFn = (ob: any) => {
        Object.keys(ob).forEach(it => {
            switch (it) {
                case 'value':
                case 'remark':
                    break;
                case 'config':
                    ob[it] = fn(ob[it], role);
                    break;
                default:
                    recursionFn(ob[it])
                    break;
            }
        })
    }
    // const copycodemap = { assessment: JSON.parse(JSON.stringify(codemap)) };
    const copycodemap = JSON.parse(JSON.stringify(codemap));
    recursionFn(copycodemap)
    return copycodemap
}


export class CzcRoutergenerater {
    _codes: CodeMap = {};

    customer!: {
        id: string;
        type: number,
        userId: string,
        userName: string,
        customs?: any,
        enterpriseSource?: number
    }

    get codes() {
        return this._codes;
    }

    template = {};

    setTemplate(customer: any = this.customer) {
        const source = customer.enterpriseSource || 1;
        return {
            meta: {
                //微应用名称
                appName: "CZCJT",
                //微应用地址
                appUrl: process.env.VUE_APP_MiCRO_CZC_APPURL || "",
            },
            path: source == 1 ? `CZCJT/${customer.enterpriseId || customer.id}/:page*` : `CZCJT/${customer.enterpriseId || customer.id}/partners/${customer.id}:page*`,
            name: "CZCJT",
            component: "VueApp",
            children: [
                {
                    name: "enterprise",
                    path: "enterprise",
                    meta: {
                        title: '信息配置',
                        breadcrumbName: "信息配置",
                        icon: 'icon-coordinate',
                        isMeun: true,
                    },
                    component: "Empty",
                    children: menu_enterprise
                },

                {
                    name: "evaluationPlan",
                    path: "evaluationPlan",
                    meta: {
                        title: '评估管理',
                        breadcrumbName: "评估管理",
                        icon: 'icon-index',
                        isMeun: true,
                    },
                    component: "MicroLayout",
                    children: menu_evaluationPlan,
                },

                {
                    meta: {
                        title: '评估任务',
                        breadcrumbName: "我的任务",
                        icon: 'icon-coordinate',
                        isMeun: true,
                    },
                    name: "assessmentPlan_sub",
                    path: "planTask/assessmentPlan/:page*",
                    component: "Empty",
                },

                {
                    name: "assesmentReport/report",
                    path: "assesmentReport/report",
                    meta: {
                        title: '报表中心',
                        breadcrumbName: "报表中心",
                        icon: 'icon-log',
                        isMeun: true,
                    },
                    component: "MicroLayout",
                    children: menu_assesmentReport
                },
            ]
        }
    }

    can(code: string): boolean {
        if (code) {
            const keyVs = code.split(/(==|!=)/g);
            const keyArr = keyVs[0].trim().split('.');
            const op = keyVs[1];
            const last = keyArr[keyArr.length - 1];
            let codeItem: any = this.codes;
            let flag = true;
            for (const key of keyArr) {
                if (
                    !codeItem ||
                    (flag &&
                        codeItem &&
                        (!codeItem.hasOwnProperty(key) ||
                            (key === last &&
                                op &&
                                keyVs[2] &&
                                !window.eval(`(${codeItem[key]}${op}${keyVs[2]})`))))
                ) {
                    flag = false;
                    break;  // 跳出循环
                } else {
                    codeItem = codeItem[key];
                }
            }
            if (
                codeItem &&
                typeof codeItem === 'object' &&
                !codeItem.hasOwnProperty('value')
            ) {
                return false;
            }
            return flag;
        }
        return false;
    }



    _request(enterpriseId?: string) {
        let url = location.href.indexOf("localhost") > -1 ? "https://pg.cloud.ricent.com" : process.env.VUE_APP_MiCRO_CZC_APPURL;
        return api.request<any>("get", "/api/enterprise/v3/customs/me", {
            baseURL: url
        }).then(_ => {
            if (_ && _.customs && _.customs[0]) {
                return api.request<any>("get", `/api/enterprise/v3/${_.customs[0].id}/enterprises/me`, {
                    baseURL: url
                }).then(ents => {
                    const entperpriseOb: Record<string, any> = {};
                    ents = ents.reverse().forEach((e: any) => {
                        entperpriseOb[e.id] = e
                    });

                    ents = Object.values(entperpriseOb)
                    setTimeout(() => {
                        ricentBus.emit(OGRENTERPRIESES, ents);
                    }, 50);
                    let eid = enterpriseId || authService?.enterprise?.id || _.customs[0].id;
                    let ent = ents.find((r: any) => r.id == eid);
                    !ent && (ent = ents.find((r: any) => r.id.startsWith(eid)));
                    if (ent) {
                        return this.customer = Object.assign(_, ent);
                    }

                })
            }
        })
    }

    _getUserRoles() {
        return Ricent.transNumberCell(this.customer.type);
    }

    _hasPermiss(type: number, router: any, customer: any = this.customer): boolean {
        let name = router.name;
        let has: boolean = false;
        const source = (customer || {}).enterpriseSource || 1;
        switch (name) {
            case "enterprise":
                has = false;
                break;
            case "organizationmanage":
                has = this.can('organization.config.has') && source == 1;
                break;
            case "usermanage":
                has = this.can('userManage.config.has') && source == 1;
                break;
            case "customerInfo":
                has = this.can('customerManage.config.has') && source == 1;
                break;
            case "tagsManage":
                has = (type & 2) != 0 && source == 1
                break;
            case "supplierManagement":
                has = !!(type & (2 | 8)) && source == 1;
                break;
            case "evaluationPlan":
                has = false;
                break;
            case "assessmentPlan":
                has = (type & (2 | 8 | 16 | 64)) != 0 && source == 1
                break;
            case "assessmentPlan/taskDistribution":
                has = (type & 16) != 0 && source == 1
                break;
            case "planTask/assessmentPlan":
                has = source == 1;
                break;
            case "problemAppeal/editionC/appeal":
                has = true;
                break;
            case "problemDestory/editionC/distory":
                has = true;
                break;
            case "assessmentPlan_sub":
                has = source == 2;
                break;
            case "assesmentReport/report":
                has = false;
                break;
            case "punch-ranking-report":
            case "problem-report":
            case "problem-appeal-report":
                has = source == 1;
                break;
            case "planItem-report":
                has = true;
                break;
            case "punch-record-report":
                has = !!(type & 18) && source == 1;
                break;
        }
        if (!has && router.children) {
            has = router.children.some((_: any) => this._hasPermiss(type, _));
        }
        return has;
    }

    pruning(router: any, type: number,) {
        if (Object.prototype.toString.call(router.component) == "[object String]") {
            router.component = (name2Component[router.component]);
        }
        if (router.children) {
            router.children.forEach((item: any) => this.pruning(item, type));
            router.children = router.children.filter((_: any) => this._hasPermiss(type, _))
        }
    }


    generator(enterprise?: any): Promise<any> {
        return this._request((enterprise || {}).id).then(_ => {
            if (!_ || !_.type) return
            let type = _.type || 0;
            this._codes = initPermission(this._getUserRoles(), _.id);
            this.template = this.setTemplate(this.customer);
            this.pruning(this.template, type);
            registerMicroRouter(this.template);
            return this.template;
        })
    }
}




export default {
    generator: function (enterprise?: any) {
        return new CzcRoutergenerater().generator(enterprise);
    }
}