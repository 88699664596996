import { ModelObject } from '../model/modelObject';
import { ParametersDtoListRicResponse } from '../model/parametersDtoListRicResponse';
import { ParametersDtoRicResponse } from '../model/parametersDtoRicResponse';
import { ParametersInput } from '../model/parametersInput';
import { RicNullRicResponse } from '../model/ricNullRicResponse';
import { Api } from '../../api';
import { HttpOption} from "@/core/http";

/* tslint:disable:no-unused-variable member-ordering */



export class ParametersService  extends Api  {
  constructor() {
    super();
  }
    /**
     * 删除专业|delete
     * 
     * @param id 
     
     **/
    public _delete(
        id:string,
        
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/parameters/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 新增|post
     * 
     * @param body 
     **/
    public post(
        body?:ParametersInput,
        options:HttpOption={},
   ): Promise<ParametersDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/parameters`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 查询|query
     * 
     * @param queryParams:{
          * @param parameterName 键
          * @param parameterValue 值
          * @param state 状态
          * @param orderNo 排序
          * @param remark 备注
          * @param extraProperties 扩展参数
       },
     **/
    public query(
        queryParams?:{
          parameterName?: string,
          parameterValue?: string,
          state?: number,
          orderNo?: number,
          remark?: string,
          extraProperties?: { [key: string]: ModelObject; },
        },
        options:HttpOption={},
   ): Promise<ParametersDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['parameterName'])) {
                params['ParameterName']=<any>queryParams['parameterName']
        }
        if (queryParams&&!this.ce(queryParams['parameterValue'])) {
                params['ParameterValue']=<any>queryParams['parameterValue']
        }
        if (queryParams&&!this.ce(queryParams['state'])) {
                params['State']=<any>queryParams['state']
        }
        if (queryParams&&!this.ce(queryParams['orderNo'])) {
                params['OrderNo']=<any>queryParams['orderNo']
        }
        if (queryParams&&!this.ce(queryParams['remark'])) {
                params['Remark']=<any>queryParams['remark']
        }
        if (queryParams&&!this.ce(queryParams['extraProperties'])) {
                params['ExtraProperties']=<any>queryParams['extraProperties']
        }
        let path = `/common/api/v5/parameters`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

}

const parametersInstance= new ParametersService()
export default parametersInstance
