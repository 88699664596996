import { authService } from "@/auth/authService";
import { Observable } from "rxjs";
import { App } from "vue";
import { ricentBus } from "./bus";
import { Ricent } from "./ricent";
import { Task, Tasks, TASK_COMPLETE, TASK_START } from "./task";



export interface FileDto {
    [x: string]: any;
    id: string;
    group: string;
    uid: string;
    name?: string;
    status: "error" | "success" | "done" | "uploading" | "removed";
    percent?: number;
    thumbUrl?: string;
    url?: string;
    file?: any;
}

const apiUrl: string = `${process.env.VUE_APP_SERVER_URL}/file/api/v5/files`;
export const FILESTATUSCHANGE = "file_status_change"

const DEFAULTSUCCESS: (data: FileDto, context?: Tasks<FileDto>) => Promise<boolean> = (data, context) => Promise.resolve(true);

export class FileTask extends Task<FileDto> {

    static from(
        file: FileDto,
        success?: (data: FileDto, context?: Tasks<FileDto>) => Promise<boolean>,
        progress?: (data: { loaded: any, total: any, percent: any }) => void,
        error?: (error: any) => void,
        errorStopTasks: boolean = false,
    ): FileTask {
        let task = new FileTask(file.uid);
        task.state = file;
        task.successCallback = success || DEFAULTSUCCESS;
        task.errorCallback = error;
        task.progress = progress;
        task.errorStopTasks = errorStopTasks;
        task.runner = task.ceateRunner;
        return task;
    }

    retry() {
        let file: any = this.state;
        ricentBus.emit(TASK_START, this);
        let me = this;
        let runner = this.ceateRunner();
        runner.subscribe((_: any) => {
            ricentBus.emit(TASK_COMPLETE, {
                current: this,
            });
            file.status = "done";
            if (this.successCallback != null) {
                this.successCallback(file);
            }

        }, (err: any) => {
            file.status = "error";
            if (this.errorCallback != null) {
                this.errorCallback(err);
            }

        })
    }

    ceateRunner() {
        let me = this;
        return Observable.create((observe: any) => {
            const xhr = new XMLHttpRequest();
            xhr.upload.addEventListener(
                "progress",
                (e: ProgressEvent) => {
                    if (e.lengthComputable) {
                        let sp = {
                            loaded: this.formatSizeUnits(e.loaded),
                            total: this.formatSizeUnits(e.total),
                            percent: Math.round((e.loaded / e.total) * 100),
                        };
                        me.state && (me.state.percent = sp.percent);
                        ricentBus.emit(FILESTATUSCHANGE, me.state)
                        setTimeout(() => {
                            me.progress && me.progress(
                                sp,
                            );
                        }, 0);

                    }
                },
                false
            );
            xhr.addEventListener(
                "load",
                (e: Event) => {
                    me.state && (me.state.status = "done");
                    ricentBus.emit(FILESTATUSCHANGE, me.state)
                    setTimeout(() => {
                        observe.next(me.state);
                        observe.complete();
                    }, 0);

                },
                false
            );
            xhr.addEventListener(
                "error",
                (e) => {
                    me.state && (me.state.status = "error");
                    ricentBus.emit(FILESTATUSCHANGE, me.state)
                    setTimeout(() => {
                        observe.error(me.state);
                        observe.complete();
                    }, 0);
                },
                false
            );
            xhr.addEventListener(
                "abort",
                (e) => {
                    me.state && (me.state.status = "error");
                    ricentBus.emit(FILESTATUSCHANGE, me.state)
                    setTimeout(() => {
                        observe.error(me.state);
                        observe.complete();
                    }, 0);

                },
                false
            );
            let file: any = this.state;
            let formData = new FormData();
            if (file.id) formData.append("id", file.id);
            formData.append("group", file.group);
            formData.append(<any>file.name, file.file);
            xhr.open("POST", apiUrl);
            const token = authService?.tokenInfo?.token! || "";
            const orgId = authService.orgId;
            const org = authService.getCurrentOrg();
            let memberId = org?.members && org.members[0] && org.members[0].id || null
            if (orgId) {
                xhr.setRequestHeader("OrgId", orgId)
            }
            if (memberId) {
                xhr.setRequestHeader("MemberId", memberId)
            }
            xhr.setRequestHeader("Authorization", `Bearer ${token}`)
            xhr.send(formData);

        })
    }


    formatSizeUnits(bytes: number, dm: number = 1): string {
        if (!bytes || bytes === 0) {
            return "0 B";
        }
        const k = 1000,
            sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }




}


export class FileTasks extends Tasks<FileDto>{

}


export const fileTask = new FileTasks();


const fileTaskLoadPlus = {
    install(app: App) {
        app.config.globalProperties.$fileTask = fileTask;
    }
};
export default fileTaskLoadPlus


