export class Ricent {

    static orgId: string;
    static enterpriseId: string;
    static appName: string = "河狸智造";

    static set(
        appName?: string,
        orgId?: string,
        enterpriseId?: string,
    ) {
        if (appName) {
            Ricent.appName = appName!;
        }
        if (orgId) {
            Ricent.orgId = orgId!;
        }
        if (enterpriseId) {
            Ricent.enterpriseId = enterpriseId!;
        }
    }

    static uuid() {
        let d = new Date().getTime();
        if (window.performance && typeof window.performance.now === 'function') {
            d += performance.now();
        }
        const _uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                const r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
            }
        );
        return _uuid;
    }


    //  转换数字
    static parseInt(num: string) {
        try {
            return parseInt(num, 10);
        } catch (e) {
            return 0;
        }
    }


    static thumb(uniqueId: string, thumb?: number|null, format?: string) {
        let baseUrl: string = process.env.VUE_APP_SERVER_URL || "";
        let url = baseUrl + '/file/api/v5/files/' + uniqueId + (thumb ? '/thumb?size=' + thumb : '/content');
        if (format) {
            if (url.indexOf("?") == -1) {
                url += "?";
            } else {
                url += "&";
            }
            url += `outFormat=${format}`;
        }

        return url;
    }

    static prefixZero(num: string, n: number) {
        if (num.length < n) {
            return (Array(n).join('0') + num).slice(-n);
        }
        return num;
    }

    static dateToYMD(date: string | number) {
        function mx(time: string) {
            return Ricent.prefixZero(time, 2);
        }
        return new Date(date).toLocaleDateString().split('/').map(mx).join('-');
    }


    static copy(data: any, copy: boolean = true): any {
        if (!copy) return data;
        if (data && typeof data === 'object') {
            let type = Object.prototype.toString.call(data);
            if (type === '[object Date]') return new Date(data.getTime());
            let newObj: any = type === '[object Array]' ? [] : {};
            for (var i in data) {
                newObj[i] = this.copy(data[i], copy);
            }
            return newObj;
        }
        return data;
    }


    static toChinesNum(input: any) {
        let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
        let unit = ["", "十", "百", "千", "万"];
        let num = parseInt(input);
        let getWan = (temp: string | number) => {
            let strArr: any = temp.toString().split("").reverse();
            let newNum = "";
            for (var i = 0; i < strArr.length; i++) {
                newNum = (i == 0 && strArr[i] == "0" ? "" : (i > 0 && strArr[i] == "0" && strArr[i - 1] == "0" ? "" : changeNum[strArr[i]] + (strArr[i] == "0" ? unit[0] : unit[i]))) + newNum;
            }
            return newNum;
        }
        let overWan = Math.floor(num / 10000);
        let noWan = `${num % 10000}`;
        if (noWan.toString().length < 4) {
            noWan = "0" + noWan;
        }
        return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
    }

    static transNumberCell(num:any): number[] {
        const n = Number(num);
        if (n) {
          return n.toString(2).split('').reverse().map((l, i) => Number(l) ? Math.pow(2, i) : 0).filter(z => z);
        } else {
          return []
        }
      }

      static downLoadFile(url:string){
        // "/file/api/v5/files/1545221879936061286/content";
        window.open(url,"_blank")
      }
}
