
import { reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import store from "@/store";
import DrawingManagement from "./DrawingManagement.vue";
import { ProjectDto } from "@/api/src/org/model/projectDto";
import Home from "@/views/page1.vue";
export default {
  name: "ProjectDetails",
  components: {
    DrawingManagement,
    Home,
  },
  setup() {
    const menuList = [
      { id: "1", name: "项目基础配置" },
      { id: "2", name: "楼栋配置" },
      { id: "3", name: "图纸管理" },
      { id: "4", name: "参与成员" },
    ];
    const router = useRouter();
    const tabIndex: any = router.currentRoute.value.query.defautIndex || "1";
    const map: any = {
      "1": Home,
      "2": Home,
      "3": DrawingManagement,
      "4": Home,
    };

    let selectedKeys = ref([`${tabIndex}`]);
    let projectName = ref();
    let currentCmp = ref(map[tabIndex]);

    const handleSelect = (item: any) => {
      selectedKeys.value = item.selectedKeys;
      currentCmp.value = map[item.key];
    };

    onMounted(() => {
      const projectDto: ProjectDto = store.getters["authStore/getProject"];
      projectName.value = projectDto.name;
    });
    return {
      menuList,
      projectName,
      currentCmp,
      selectedKeys,
      handleSelect,
    };
  },
};
