import { GenerateQrCodeInput } from '../model/generateQrCodeInput';
import { Api } from '../../api';
import { HttpOption} from "@/core/http";

/* tslint:disable:no-unused-variable member-ordering */



export class QrCodeService  extends Api  {
  constructor() {
    super();
  }
    /**
     * 生成二维码|generate
     * 
     * @param body 
     **/
    public generate(
        body?:GenerateQrCodeInput,
        options:HttpOption={},
   ): Promise<Blob> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/qr-code/generate`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

}

const qrCodeInstance= new QrCodeService()
export default qrCodeInstance
