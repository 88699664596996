import { MemberDtoListRicResponse } from '../model/memberDtoListRicResponse';
import { MemberDtoRicResponse } from '../model/memberDtoRicResponse';
import { MemberPatchInput } from '../model/memberPatchInput';
import { MemberPostInput } from '../model/memberPostInput';
import { MemberPutInput } from '../model/memberPutInput';
import { MemberQueryByIdsInput } from '../model/memberQueryByIdsInput';
import { MemberQueryByTagsInput } from '../model/memberQueryByTagsInput';
import { MemberRolePatchInput } from '../model/memberRolePatchInput';
import { RicNullRicResponse } from '../model/ricNullRicResponse';
import { StringRicResponse } from '../model/stringRicResponse';
import { Api } from '../../api';
import { HttpOption} from "@/core/http";

/* tslint:disable:no-unused-variable member-ordering */



export class MemberService  extends Api  {
  constructor() {
    super();
  }
    /**
     * 删除人员|delete
     * 
     * @param id 
     
     **/
    public _delete(
        id:string,
        
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/members/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 删除用户相关人员|deleteUserMember
     * 
     * @param userId 
     
     **/
    public deleteUserMember(
        userId:string,
        
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/members/user/${encodeURIComponent(String(userId))}`;
	    path = this.url(path, { userId:userId });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 获取人员信息|get
     * 
     * @param id 
     
     **/
    public get(
        id:string,
        
        options:HttpOption={},
   ): Promise<MemberDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/members/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 获取手机号 对应人员|getPhoneNumber
     * 
     * @param id 
     
     **/
    public getPhoneNumber(
        id:string,
        
        options:HttpOption={},
   ): Promise<StringRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/members/${encodeURIComponent(String(id))}/phoneNumber`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 局部更新人员信息|patch
     * 
     * @param id 
     * @param body 
     **/
    public patch(
        id:string,
        body?:MemberPatchInput,
        options:HttpOption={},
   ): Promise<MemberDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/members/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 批量修改人员权限|patchMemberRole
     * 
     * @param body 
     **/
    public patchMemberRole(
        body?:MemberRolePatchInput,
        options:HttpOption={},
   ): Promise<MemberDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/members/role`;
	    path = this.url(path);
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 添加人员信息|post
     * 
     * @param queryParams:{
          * @param orgId 
       },* @param body 
     **/
    public post(
        queryParams?:{
          orgId?: string,
        },body?:MemberPostInput,
        options:HttpOption={},
   ): Promise<MemberDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['orgId'])) {
                params['orgId']=<any>queryParams['orgId']
        }
        let path = `/org/api/v5/members`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 更新人员|put
     * 
     * @param id 
     * @param body 
     **/
    public put(
        id:string,
        body?:MemberPutInput,
        options:HttpOption={},
   ): Promise<MemberDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/members/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'put',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 分页查询人员列表|query
     * 
     * @param queryParams:{
          * @param key 关键字查询
          * @param state 数据状态
          * @param skip 跳过数量
          * @param limit 限制数量
          * @param fields 1：:Partners  2:Regions
          * @param regionId 区域Id
          * @param partnerId 合作伙伴Id
          * @param internal 内部成员 传true 只查询内部成员 传false 查询外部成员 不传查询全部
       },
     **/
    public query(
        queryParams?:{
          key?: string,
          state?: number,
          skip?: number,
          limit?: number,
          fields?: string,
          regionId?: string,
          partnerId?: string,
          internal?: boolean,
        },
        options:HttpOption={},
   ): Promise<MemberDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['key'])) {
                params['Key']=<any>queryParams['key']
        }
        if (queryParams&&!this.ce(queryParams['state'])) {
                params['State']=<any>queryParams['state']
        }
        if (queryParams&&!this.ce(queryParams['skip'])) {
                params['Skip']=<any>queryParams['skip']
        }
        if (queryParams&&!this.ce(queryParams['limit'])) {
                params['Limit']=<any>queryParams['limit']
        }
        if (queryParams&&!this.ce(queryParams['fields'])) {
                params['Fields']=<any>queryParams['fields']
        }
        if (queryParams&&!this.ce(queryParams['regionId'])) {
                params['RegionId']=<any>queryParams['regionId']
        }
        if (queryParams&&!this.ce(queryParams['partnerId'])) {
                params['PartnerId']=<any>queryParams['partnerId']
        }
        if (queryParams&&!this.ce(queryParams['internal'])) {
                params['Internal']=<any>queryParams['internal']
        }
        let path = `/org/api/v5/members`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 通过ids集合 获取人员列表|queryByIds
     * 
     * @param body 
     **/
    public queryByIds(
        body?:MemberQueryByIdsInput,
        options:HttpOption={},
   ): Promise<MemberDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/members/ids/query`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 根据标签查询人员|queryMemberByTags
     * 
     * @param body 
     **/
    public queryMemberByTags(
        body?:MemberQueryByTagsInput,
        options:HttpOption={},
   ): Promise<MemberDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/members/tags-query`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

}

const memberInstance= new MemberService()
export default memberInstance
