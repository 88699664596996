import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!

  return (_openBlock(), _createBlock(_component_a_breadcrumb, { routes: _ctx.routes }, {
    itemRender: _withCtx(({ route }) => [
      (_ctx.routes.indexOf(route) === _ctx.routes.length - 1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(route.breadcrumbName), 1))
        : (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: route.path
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(route.breadcrumbName), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
    ]),
    _: 1
  }, 8, ["routes"]))
}