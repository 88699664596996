import microApp from "@micro-zoe/micro-app";

declare var window: any

export const microAppEnvConfig = {
    appName: "microApp",  //微应用名称
    __MICRO_APP_ENVIRONMENT__: window.__MICRO_APP_ENVIRONMENT__, //是否微应用
    MICRO_APP_BASE_APPLICATION: window.__MICRO_APP_BASE_APPLICATION__, //是否基应用
    __MICRO_APP_NAME__: window.__MICRO_APP_NAME__,   //app名称
}

// if (!window.__MICRO_APP_ENVIRONMENT__) {
//     microApp.start();
// }
