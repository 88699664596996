import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8baaf96e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-name" }
const _hoisted_2 = { class: "phone" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchOutlined = _resolveComponent("SearchOutlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_v_nodes = _resolveComponent("v-nodes")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[4] || (_cache[4] = 
      (e) => {
        e.preventDefault();
        $setup.selectOpen = !$setup.selectOpen;
      }
    )
  }, [
    _createVNode(_component_a_select, {
      value: $setup.selectValue,
      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (($setup.selectValue) = $event)),
      placeholder: "请选择",
      mode: "multiple",
      "option-label-prop": "label",
      open: $setup.selectOpen,
      showSearch: false,
      onBlur: $setup.blurEvent
    }, {
      dropdownRender: _withCtx(({ menuNode: menu }) => [
        _createElementVNode("div", {
          onMousedown: _cache[1] || (_cache[1] = 
            (e) => {
              e.preventDefault();
            }
          ),
          onClick: _cache[2] || (_cache[2] = (e) => e.target.focus()),
          class: "search"
        }, [
          _createVNode(_component_a_input, {
            value: $setup.filterKey,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.filterKey) = $event)),
            placeholder: "搜索",
            onBlur: $setup.blurEvent,
            class: "inputSearch"
          }, {
            prefix: _withCtx(() => [
              _createVNode(_component_SearchOutlined)
            ]),
            _: 1
          }, 8, ["value", "onBlur"])
        ], 32),
        _createVNode(_component_v_nodes, { vnodes: menu }, null, 8, ["vnodes"])
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.userList, (item) => {
          return (_openBlock(), _createBlock(_component_a_select_option, {
            key: item.id,
            value: item.id,
            label: item.name,
            class: "underline"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_1, _toDisplayString(item.name), 1),
                _createElementVNode("span", _hoisted_2, _toDisplayString(item.phone), 1)
              ])
            ]),
            _: 2
          }, 1032, ["value", "label"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "open", "onBlur"])
  ]))
}