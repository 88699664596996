import storage from "@/core/storage"
import { loadLanguageAsync } from "@/locales"
import { AppState, APP_LANGUAGE, SIDEBAR_TYPE, TOGGLE_COLOR, TOGGLE_CONTENT_WIDTH, TOGGLE_FIXED_HEADER, TOGGLE_FIXED_SIDEBAR, TOGGLE_HIDE_HEADER, TOGGLE_LAYOUT, TOGGLE_MOBILE_TYPE, TOGGLE_MULTI_TAB, TOGGLE_NAV_THEME, TOGGLE_WEAK } from "./dto";
import { defaultLang } from '@/locales/index'
const state: AppState = {
    sideCollapsed: false,
    isMobile: false,
    theme: 'dark',
    layout: '',
    contentWidth: '',
    fixedHeader: false,
    fixedSidebar: false,
    autoHideHeader: false,
    color: '',
    weak: false,
    multiTab: true,
    lang: defaultLang,
    _antLocale: {}
}

export default {
    namespaced: true,
    state,
    getters: {
        [SIDEBAR_TYPE]: (state: AppState) => {
            return state.sideCollapsed;
        },
        [TOGGLE_MOBILE_TYPE]: (state: AppState) => {
            return state.isMobile;
        },
        [TOGGLE_NAV_THEME]: (state: AppState) => {
            return state.theme;
        },
        [TOGGLE_LAYOUT]: (state: AppState) => {
            return state.layout;
        },
        [TOGGLE_FIXED_HEADER]: (state: AppState) => {
            return state.fixedHeader;
        },
        [TOGGLE_FIXED_SIDEBAR]: (state: AppState) => {
            return state.fixedSidebar;
        },
        [TOGGLE_CONTENT_WIDTH]: (state: AppState) => {
            return state.contentWidth;
        },
        [TOGGLE_HIDE_HEADER]: (state: AppState) => {
            return state.autoHideHeader;
        },
        [TOGGLE_COLOR]: (state: AppState) => {
            return state.color;
        },
        [TOGGLE_WEAK]: (state: AppState) => {
            return state.weak;
        },
        [APP_LANGUAGE]: (state: AppState) => {
            return state.lang;
        },
        [TOGGLE_MULTI_TAB]: (state: AppState) => {
            return state.multiTab;
        }
    },
    mutations: {
        [SIDEBAR_TYPE]: (state: AppState, type: boolean) => {
            state.sideCollapsed = type
        },
        [TOGGLE_MOBILE_TYPE]: (state: AppState, isMobile: boolean) => {
            state.isMobile = isMobile
        },
        [TOGGLE_NAV_THEME]: (state: AppState, theme: string) => {
            state.theme = theme
        },
        [TOGGLE_LAYOUT]: (state: AppState, mode: string) => {
            state.layout = mode
        },
        [TOGGLE_FIXED_HEADER]: (state: AppState, mode: boolean) => {
            state.fixedHeader = mode
        },
        [TOGGLE_FIXED_SIDEBAR]: (state: AppState, mode: boolean) => {
            state.fixedSidebar = mode
        },
        [TOGGLE_CONTENT_WIDTH]: (state: AppState, type: string) => {
            state.contentWidth = type
        },
        [TOGGLE_HIDE_HEADER]: (state: AppState, type: boolean) => {
            state.autoHideHeader = type
        },
        [TOGGLE_COLOR]: (state: AppState, color: string) => {
            state.color = color
        },
        [TOGGLE_WEAK]: (state: AppState, mode: boolean) => {
            state.weak = mode
        },
        [APP_LANGUAGE]: (state: AppState, lang: string, antd = {}) => {
            state.lang = lang
            state._antLocale = antd
        },
        [TOGGLE_MULTI_TAB]: (state: AppState, bool: boolean) => {
            state.multiTab = bool
        }
    },
    actions: {
        setLang(store: any, lang: string) {
            return new Promise((resolve, reject) => {
                store.commit(APP_LANGUAGE, lang)
                loadLanguageAsync(lang).then(() => {
                    resolve(lang);
                }).catch((e) => {
                    reject(e)
                })
            })
        }
    },
};