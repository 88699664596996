import { App } from "vue";
export class EventBus {
  static innerEvents: any = {};
  static keys: string[] = [];

  constructor() { }

  public emit(event: string, args?: any) {
    EventBus.keys.forEach((k) => {
      if (event.substr(0, k.length) === k) {
        const e = EventBus.innerEvents[k];
        if (e && e.fn) {
          e.fn.forEach((f: any) => {
            f.fn.call(f.scope, args);
          });
        }
      }
    });
  }

  public on(
    name: string,
    fn: (args: any) => void,
    scope: any,
    single?: boolean
  ) {
    if (EventBus.keys.indexOf(name) === -1) {
      EventBus.keys.push(name);
    }
    const e = (EventBus.innerEvents[name] = EventBus.innerEvents[
      name
    ] || { fn: [] });
    if (e.fn.find((a: any) => a.scope === scope)) {
      return;
    }
    if (single === true) {
      e.fn = [];
    }
    const event = {
      scope,
      fn,
    };
    e.fn.push(event);

    return () => {
      const i = e.fn.indexOf(event);
      e.fn.splice(i, 1);
    };
  }
  
  public un(name: string, fn: (args: any) => void, scope: any = null) {
    const e = EventBus.innerEvents[name];
    if (e) {
      if (!fn) {
        EventBus.innerEvents[name] = null;
      } else {
        const index = e.fn.findIndex((f: any) => {
          return f.fn === fn && (!scope || f.scope === scope);
        });
        if (index !== -1) {
          e.fn.splice(index, 1);
        }
      }
    }
  }

  public has(name: string) {
    return !!EventBus.innerEvents[name];
  }
}


export const ricentBus = new EventBus();


const eventPlus = {
  install(app: App) {
    app.config.globalProperties.$bus = ricentBus;
  }
};

export default eventPlus;