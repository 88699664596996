
import { computed ,ref ,onMounted } from "vue";
import { apiClient } from '@/api/src/apis';
import { MemberDto } from '@/api/src/org/model/memberDto';
export default {
  name: "RcSearch",
  props: {
    modelValue: {
      type: String,
    },
  },
  components:{
    VNodes: (_:any, { attrs }:any) => {
      return attrs.vnodes;
    },
  },
  setup(props: any, { emit }: any) {
    let filterKey = ref<string>("");
    let selectOpen = ref<boolean>(false);
    let usersData = ref<MemberDto[]>([]);

    let selectValue = computed({
      get: () => {
        return props.modelValue;
      },
      set: (newValue) => {
        emit("update:modelValue", newValue);
      },
    });
    // 模糊查询用户
    let userList  = computed(()=>{
      if(filterKey){
        return usersData.value.filter((item)=>{
          return item.name!.indexOf(filterKey.value) >= 0;
        })
      }
      return usersData;
    });

    // 鼠标失焦
    const blurEvent = ()=>{
        let f = 'inputSearch';
        let classes = document.activeElement?.getAttribute('class');
       
        if (!classes) {
            selectOpen.value = false;
            return;
        }
        if (classes != 'ant-input') {
            selectOpen.value = false;
        }
    }

    onMounted(()=>{
      getMembers()
    });

    // 获取组织人员信息
    const getMembers = ()=>{
      apiClient.member.query({
        'fields':'Regions',
      }).then((res)=>{
        usersData.value = res.data as MemberDto[];
        
      }).catch((err)=>{
      })
    }
    
    return {
        filterKey,
        selectValue,
        selectOpen,
        userList,
        blurEvent,
    };
  },
};
