//java -jar cli.jar generate -i http://localhost:9001/docs/v2/api.json -o api -l typescript-angular -t ./ng2
import  joinInstance,{ JoinService }  from './join.service';
import  memberInstance,{ MemberService }  from './member.service';
import  orgInstance,{ OrgService }  from './org.service';
import  partnerInstance,{ PartnerService }  from './partner.service';
import  regionInstance,{ RegionService }  from './region.service';
import { ApiClient } from '../../apiService';

declare module '../../apiService' {
  export interface ApiClient{

    join:JoinService,

    member:MemberService,

    org:OrgService,

    partner:PartnerService,

    region:RegionService,
  }
}


const apiClient={

  join:joinInstance,

  member:memberInstance,

  org:orgInstance,

  partner:partnerInstance,

  region:regionInstance,
}
export default apiClient

