import { DictionaryDtoRicResponse } from '../model/dictionaryDtoRicResponse';
import { DictionaryPatchInput } from '../model/dictionaryPatchInput';
import { DictionaryPostInput } from '../model/dictionaryPostInput';
import { DictionaryQueryDtoListRicResponse } from '../model/dictionaryQueryDtoListRicResponse';
import { RicNullRicResponse } from '../model/ricNullRicResponse';
import { SortInput } from '../model/sortInput';
import { Api } from '../../api';
import { HttpOption} from "@/core/http";

/* tslint:disable:no-unused-variable member-ordering */



export class DictionaryService  extends Api  {
  constructor() {
    super();
  }
    /**
     * 获取单条字典|get
     * 
     * @param id 
     
     **/
    public get(
        id:string,
        
        options:HttpOption={},
   ): Promise<DictionaryDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/dictionary/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 删除字典|patch
     * 
     * @param id 
     
     **/
    public patch(
        id:string,
        
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/dictionary/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 修改字典|patch
     * 
     * @param id 
     * @param body 
     **/
    public patch_1(
        id:string,
        body?:DictionaryPatchInput,
        options:HttpOption={},
   ): Promise<DictionaryDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/dictionary/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 新增字典|post
     * 
     * @param body 
     **/
    public post(
        body?:DictionaryPostInput,
        options:HttpOption={},
   ): Promise<DictionaryDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/dictionary`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 字典查询|query
     * 
     * @param queryParams:{
          * @param name 名称
          * @param parentId 父级Id
          * @param type 类型
          * @param state data state
          * @param tree 树型展开
       },
     **/
    public query(
        queryParams?:{
          name?: string,
          parentId?: string,
          type?: number,
          state?: string,
          tree?: boolean,
        },
        options:HttpOption={},
   ): Promise<DictionaryQueryDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['name'])) {
                params['Name']=<any>queryParams['name']
        }
        if (queryParams&&!this.ce(queryParams['parentId'])) {
                params['ParentId']=<any>queryParams['parentId']
        }
        if (queryParams&&!this.ce(queryParams['type'])) {
                params['Type']=<any>queryParams['type']
        }
        if (queryParams&&!this.ce(queryParams['state'])) {
                params['State']=<any>queryParams['state']
        }
        if (queryParams&&!this.ce(queryParams['tree'])) {
                params['Tree']=<any>queryParams['tree']
        }
        let path = `/common/api/v5/dictionary`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 更新排序|sort
     * 
     * @param body 
     **/
    public sort(
        body?:Array<SortInput>,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/dictionary/sort`;
	    path = this.url(path);
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

}

const dictionaryInstance= new DictionaryService()
export default dictionaryInstance
