
import { Options } from "vue-class-component";

import { Vue } from "vue-class-component";
import {
  CloseOutlined,
  InboxOutlined,
  MinusSquareOutlined,
  ExpandOutlined,
  PaperClipOutlined,
  CheckCircleOutlined,
  CheckCircleFilled,
  Loading3QuartersOutlined,
} from "@ant-design/icons-vue";
import { ricentBus } from "@/core/bus";
import {
  TASK_COMPLETE,
  TASK_QUEUEN_COMPLETE,
  TASK_QUEUEN_START,
  TASK_START,
} from "@/core/task";
import { FILESTATUSCHANGE, FileTask } from "@/core/fileTask";

@Options({
  components: {
    CloseOutlined,
    InboxOutlined,
    MinusSquareOutlined,
    ExpandOutlined,
    PaperClipOutlined,
    CheckCircleOutlined,
    CheckCircleFilled,
    Loading3QuartersOutlined,
  },
})
export default class RcUploadDialogTip extends Vue {
  minimized: boolean = false;
  visible: boolean = false;
  public tasks: any[] = [];

  initTasks() {
    let task = this.$fileTask.queuen.filter((_) => {
      return _.status != "done";
    });
    this.tasks = task;
  }

  created() {
    ricentBus.on(
      TASK_QUEUEN_START,
      (arg) => {
        if (arg.popTip) {
          this.initTasks();
          this.open();
        }
      },
      this
    );
    ricentBus.on(
      TASK_QUEUEN_COMPLETE,
      (arg) => {
        setTimeout(() => {
          this.initTasks();
          this.$fileTask.queuen
            .filter((_) => _.status == "done")
            .forEach((e) => {
              this.$fileTask.removeTask(e);
            });
          if (this.$fileTask.queuen.every((_) => _.status == "done")) {
            this.close();
          }
        }, 1000);
      },
      this
    );

    ricentBus.on(
      FILESTATUSCHANGE,
      (arg) => {
        this.initTasks();
      },
      this
    );
    ricentBus.on(
      TASK_COMPLETE,
      (arg) => {
        setTimeout(() => {
          this.initTasks();
        }, 1000);
      },
      this
    );
  }

  remove(task: FileTask) {
    this.$fileTask.removeTask(task);
  }

  retry(task: FileTask) {
    task.retry();
  }

  progressStatus(item: any) {
    return item.status == "done"
      ? "success"
      : item.status == "error"
      ? "exception"
      : "active";
  }

  mounted() {
    setTimeout(() => {
      document.body.appendChild(this.$el);
      this.$el.style.cssText = "position: absolute;bottom:12px;right:12px;";
      console.log(this.$el);
    }, 0);
  }

  unmounted() {
    try {
      document.body.removeChild(this.$el);
    } catch (ex) {}
  }
  open() {
    this.visible = true;
    this.minimized = false;
  }

  close() {
    this.visible = false;
  }
}
