
import { SearchOutlined } from "@ant-design/icons-vue";
import { apiClient } from "@/api/src/apis";
import store from "@/store";
import { pinyin } from "@/core/pinyin";
import DrawingItem from "./DrawingItem.vue";
import { ProjectDto } from "@/api/src/org/model/projectDto";
import { FileContainerDto } from "@/api/src/files/model/fileContainerDto";
import moment from "moment";
import { Options, Vue } from "vue-class-component";
import { Ref } from "vue-property-decorator";
import RcUploadDialog from "@/components/RcUploadDialog/RcUploadDialog.vue";
import DraingList from "./DrawingList.vue";
import DraingItem from "./DrawingItem.vue";
import { message, Modal } from "ant-design-vue";
import { Ricent } from "@/core/ricent";
import RcFileUploadButton from "@/components/RcUploadDialog/RcFileUploadButton.vue";
import { FileTask } from "@/core/fileTask";
import RcFileUploadDrag from "@/components/RcUploadDialog/RcFileUploadDrag.vue";
import { ricentBus } from "@/core/bus";

@Options({
  components: {
    SearchOutlined,
    DrawingItem,
    RcUploadDialog,
    DraingList,
    DraingItem,
    RcFileUploadButton,
    RcFileUploadDrag,
  },
})
export default class DrawingManagement extends Vue {
  public keyword: string = "";
  public actived: string = "list";
  public renameVisible: boolean = false;
  public rcdialog!: RcUploadDialog;
  public loading: boolean = false;
  public renameLoading: boolean = false;
  public groupId: string = "";
  public renameForm: {
    id: string;
    name: string;
    tags: any;
    ext: string;
  } = {
      id: "",
      name: "",
      ext: "",
      tags: null,
    };

  rules = [
    {
      required: true,
      message: "文件名称不能为空",
      trigger: "blur",
    },
    {
      validator: (rule: any, value: string) => {
        if (value && value.indexOf(".") > -1) {
          return Promise.reject("不能修改文件后缀");
        }
        return Promise.resolve();
      },
      trigger: "change",
    },
  ];

  public checkedState = {
    indeterminate: false,
    checkAll: false,
    checkedList: <any>[],
  };
  @Ref("formRef")
  public formRef: any;
  //数据
  public data: any = [];
  public all: any = [];
  created() {
    const projectDto: ProjectDto = store.getters["authStore/getProject"];
    this.groupId = "drawings-" + projectDto.id;

    this.query();
  }

  attachAtter(_: any) {
    let sp = _.name?.split(".") || [];
    if (sp.length > 1) {
      _.ext = sp.pop();
    }
    _.shortName = sp.join("");
    _.sizeMb = this.formatSizeUnits(_.size);
    _.date = this.formatDate(_.lastOperatorTime);
    _.thumbUrl = Ricent.thumb(_.uniqueId, 300);
    _.url = Ricent.thumb(_.uniqueId);
    _.checked = this.checkedState.checkAll;
    return _;
  }

  query() {
    this.loading = true;
    apiClient.files
      .query({ group: this.groupId, startWith: false })
      .then((res) => {
        this.loading = false;
        this.data = this.all = res.data?.map((_: any) => {
          return this.attachAtter(_);
        }) as FileContainerDto[];
        this.doFilter();
      })
      .catch((err) => {
        this.loading = false;
      });
  }

  doFilter() {
    if (!this.all) this.all = [];
    this.data = this.all.filter((_: any) => {
      return (
        !this.keyword ||
        pinyin.isIn(_.name, this.keyword) ||
        _.name.indexOf(this.keyword) > -1
      );
    });
  }

  changeModal(modal: string) {
    this.actived = modal;
  }

  handleSelected(obj: any) { }

  removeFile(file: any) {
    this.loading = true;
    this.$api.files
      ._delete(file.id)
      .then((_) => {
        this.loading = false;
        message.success("删除成功");
        this.query();
      })
      .catch((_) => {
        this.loading = false;
      });
  }

  get selectRows(): any[] {
    return this.data?.filter((_: any) => _.checked) ?? [];
  }


  downloadUrlFile(file:any) {
   var  url = file.url.replace(/\\/g, '/');
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    //xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
    xhr.onload = () => {
      if (xhr.status === 200) {
        // 获取文件blob数据并保存 
       this.saveAs(xhr.response, file.name);
      }
    };

    xhr.send();
  }


  /** * URL方式保存文件到本地 * @param data 文件的blob数据 * @param name 文件名 */
  saveAs(data: any, name: string) {
    var urlObject = window.URL || window.webkitURL || window;
    var export_blob = new Blob([data]);
    var save_link = document.createElement('a');
    save_link.href = urlObject.createObjectURL(export_blob);
    save_link.download = name;
    save_link.click();
  }


  batchDown() {
    let selected: any[] = this.selectRows;
    if (!selected.length) {
      message.info("请选择文件");
      return;
    }
    if (selected.length == 1) {
      this.downloadUrlFile(selected[0]);
      return;
    }
    var date = new Date().getTime();
    this.loading = true;
    var name =  Ricent.dateToYMD(date).replaceAll("-", "") + "_" + Ricent.uuid()+".zip";
    this.$api.files.zipExport(<any>{
      'name':name,
      'downloadName':name,
      'files': selected.map((e) => {
        return {
          'uniqueId':e.id,
          // 'groupId':e.group
        };
      }),
    }).then((_) => {
      this.loading = false; 
      Ricent.downLoadFile(process.env.VUE_APP_SERVER_URL as string+_.data?.url);
       
    }).catch((_) => {
      this.loading = false;
    });
  }

  batchDelete() {
    let selected: any[] = this.selectRows;
    if (!selected.length) {
      message.info("请选择文件");
      return;
    }
    Modal.confirm({
      title: "提示",
      content: "您确定要删除吗？",
      onOk: () => {
        this.loading = true;
        this.$api.files
          .deleteBatch(selected.map((_) => _.id))
          .then((_) => {
            this.loading = false;
            message.success("删除成功");
            this.query();
          })
          .catch((_) => {
            this.loading = false;
          });
      },
      onCancel() { },
    });
  }

  handleCheckedChange(checkedValue: any) {
    this.data?.forEach((_: any) => {
      _.checked = this.checkedState.checkAll;
    });
  }

  doUpload(files: any[]) {
    if (!files || !files.length) return;
    let me = this;
    let tasks = files.map((_) => {
      return FileTask.from(_, (data, contex) => {
        me.query();
        return Promise.resolve(true);
      });
    });

    this.$fileTask.appendAndrun(tasks);
  }

  formatFile(fileName: string) {
    return fileName.slice(fileName.indexOf("."));
  }
  // 格式化文件大小 size/1024 保留两位小数
  formatSizeUnits(bytes: number, dm: number = 1): string {
    if (bytes === 0) {
      return "0 B";
    }
    const k = 1000,
      sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  // 格式化时间 YYYY/MM/DD hh:mm:ss
  formatDate(d: any) {
    return moment(d).format("YYYY/MM/DD HH:mm");
  }

  formatData() { }

  handleRename(record: ProjectDto & { shortName: string; ext: string }) {
    this.renameForm.name = record.shortName!;
    this.renameForm.ext = record.ext ? `.${record.ext}` : "";
    this.renameForm.id = record.id as string;
    this.renameForm.tags = record;
    this.renameVisible = true;
  }

  handleOk(e: any) {
    let me = this;
    this.formRef
      .validate()
      .then(() => {
        let tags = this.renameForm.tags;
        let index = this.data.findIndex((_: any) => _.id == tags.id);
        let name = this.renameForm.name;
        this.renameLoading = true;
        console.log(tags);
        apiClient.files
          .patch(this.renameForm.id, Object.assign({}, tags, { name: name }))
          .then((_) => {
            me.renameLoading = false;
            me.renameVisible = false;
            this.data[index] = this.attachAtter(_.data);
          })
          .catch((_) => {
            message.error("重命名失败");
            me.renameLoading = false;
          });
      })
      .catch((err: any) => { });
  }

  viewer(it: any) {
    ricentBus.emit("viewjs", {
      file: it,
      files: this.data,
      remove: this.removeFile,
    });
    // let viewer = this.$refs.viewer as ViewerJS;
    // viewer.view(it);
  }
}

function dayjs() {
  throw new Error("Function not implemented.");
}
