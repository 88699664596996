import { authService } from "@/auth/authService";
import { ricentBus } from "@/core/bus";
import { UNAUTHORIZED_401 } from "@/core/globalEvent";
import { Http, HttpOption } from "@/core/http";
import { notification } from "ant-design-vue";
import { Method } from "axios";

const pathEx: RegExp = /\{\w+\}/gi

// 异常拦截处理器
const errorHandler = (error: any) => {
    if (error.response) {
        const data = error.response.data
        // 从 localstorage 获取 token
        // const token = storage.get(ACCESS_TOKEN)
        if (error.response.status === 403) {
            notification.error({
                message: '认证错误',
                description: data.message
            })
        }
        if (error.response.status === 401) {
            ricentBus.emit(UNAUTHORIZED_401, error)
        }

        if(error.response.status === 500){
             // 统一接口异常提示
            notification.error({
                message: '接口错误',
                description: data.message
            })
        }
    }
   
    return Promise.reject(error)
}

export class Api {

    protected cp(method: string, name: string, value: any): void {
        if (value === null || value === undefined) {
            throw new Error(`当请求${method}参数时${name}为null或undefined`);
        }
    }

    protected ce(value: any): boolean {
        if (value === null || value === undefined) {
            return true;
        }
        return false;
    }

    private format(path: string, route: any): string {
        if (!route) return path;
        const matchs = path.match(pathEx);
        if (matchs && matchs.length) {
            matchs.forEach(function (m) {
                const p = m.substring(1, m.length - 1);
                path = path.replace(m, route[p]);
            });
        }
        return path;
    }

    protected getToekn(): string {
        return authService?.tokenInfo?.token! || "";
    }

    /**
  * 格式化URL地址
  * @param path
  * @param query
  * @param route
  * @returns {string}
  */
    public url(path: string, route?: any): string {
        return this.format(path, route);
    }

    public request<R>(
        method: Method,
        uri: string,
        options: HttpOption,
    ): Promise<R> {
        //非匿名
        const token: string = this.getToekn();
        const orgId = authService.orgId;
        const org = authService.getCurrentOrg();
        let memberId = org?.members && org.members[0] && org.members[0].id || null
        const http = Http();
        http.interceptors.response.use((response) => {
            return response;
        }, errorHandler)

        if (!options) {
            options = {};
        }
        const headers:Record<string,any> = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        };
        if(orgId){
            headers["OrgId"]=orgId;
        }
       
        if(memberId){
            headers["MemberId"]=memberId;
        }
        if (options.headers) {
            options.headers = Object.assign(headers, options.headers);

        } else {
            options.headers = headers;
        }
        options = Object.assign(options, {
            method: method,
            url: uri,
        })
        return http.request<R & { error: any }>(options).then(function (respose) {
            return respose.data;
        }).catch(function (err) {
            return Promise.reject(err);
        });
    }

}