import antdEnUS from 'ant-design-vue/es/locale-provider/en_US'

const antdEn: any = antdEnUS;

const components= {
  antLocale: antdEn,
  momentName: 'eu',
}

export default {
  message: '-',
  'menu.account.logout': 'logout',
  'layouts.usermenu.dialog.title': 'Message',
  'layouts.usermenu.dialog.content': 'Are you sure you would like to logout?',
  'layouts.userLayout.title': 'Ant Design is the most influential web design specification in Xihu district',
  ...components,
}
