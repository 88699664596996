
import { MoreOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Options({
  components: {
    MoreOutlined,
  },
})
export default class DraingItem extends Vue {
  @Prop()
  item!: any;
  url: string =
    "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png";
  handleChange(checkedValue: any) {
    const checked = checkedValue.target.checked;
    this.item.checked = checked;
    const obj = {
      item: this.item,
      checked: checked,
    };
    this.$emit("selected", obj);
  }

  deleteItem(it: any) {
    Modal.confirm({
      title: "提示",
      content: "您确定要删除吗？",
      onOk: () => {
        this.$emit("file:remove", it);
      },
      onCancel() {},
    });
  }
  rename(it: any) {
    this.$emit("file:rename", it);
  }

  viewer(item:any){
     this.$emit("file:view", item);
  }
}
