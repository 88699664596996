
import "zone.js";
import { microAppEnvConfig } from "@/config/microAppSettings";
import microApp, { removeDomScope, unmountApp } from "@micro-zoe/micro-app";
import { Vue } from "vue-class-component";
import { ricentBus } from "@/core/bus";

declare var window: any;
export default class VueApp extends Vue {
  public lastUrl!: string;
  public margin: any;
  public get appName(): any {
    return microAppEnvConfig.appName;
  }

  public get appUrl() {
    return process.env.VUE_APP_MiCRO_APPURL || "";
  }

  created() {}

  unmounted() {}
  beforeUnmount() {
    this.$el.parentElement.style.margin = this.margin;
  }

  createApp(appName: string, appUrl: string) {
    if (appUrl == this.lastUrl) return;
    setTimeout(() => {
      let dom = document.createElement("micro-app");
      dom.setAttribute("name", appName);
      dom.setAttribute("url", appUrl);
      dom.setAttribute("destroy", "true");
      dom.style.height = "100%";
      dom.style.width = "100%";
      document.getElementById("_microApp")?.appendChild(dom);
      dom.addEventListener("created", () => {
        ricentBus.emit("micro:app:created");
      });
      dom.addEventListener("mounted", () => {
        ricentBus.emit("micro:app:mounted");
      });

      microApp.addDataListener(
        this.appName,
        (params: { type: string; data: any }) => {
          if (params.type) {
            this.$bus.emit(params.type, params);
          }
        }
      );
      // 向子应用推送全局数据
      // microApp.setGlobalData({ type: "全局数据" });
    }, 0);
  }

  mounted() {
    let appName: any = this.$route.meta.appName || this.appName;
    let appUrl: any = this.$route.meta.appUrl || this.appUrl;

    // 因为设置了destroy=true, 如果是基应用，这里需要重新初始化micro服务
    if (!window["__MICRO_APP_ENVIRONMENT__"]) {
      microApp.start({
        inline: location.origin.indexOf("localhost") != -1,
      });
    }

    this.createApp(appName, appUrl);
    let styles = window.getComputedStyle(this.$el.parentElement);
    this.margin = styles.getPropertyValue("margin");
    this.$el.parentElement.style.margin = "0px 0px 0px 0px";
  }
}
