import { OrgDto } from "@/api/src/org/model/models";
import { ProfileDto } from "@/api/src/userCenter/model/models";
import { ProjectDto } from "@/api/src/org/model/projectDto";
import storeService from "@/core/storage";
import { Enterprise, TokenModel, UserState } from "./dto";



const initFactory = {
    initToken: function (): TokenModel {
        let tokenStr = storeService.get("micro_rc_token");
        if (!tokenStr) {
            return null!;
        }
        return JSON.parse(tokenStr);
    },
    initUser: function (): ProfileDto {
        let userStr = storeService.get("micro_rc_user");
        if (!userStr) {
            return null!;
        }
        return JSON.parse(userStr);
    },
    initOrgs: function (): OrgDto[] {
        let orgStr = storeService.get("micro_rc_orgs");
        if (!orgStr) {
            return null!;
        }
        return JSON.parse(orgStr);
    },
    initProject: function (): ProjectDto {
        let projectStr = storeService.get("micro_rc_project");
        if (!projectStr) {
            return null!;
        }
        return JSON.parse(projectStr);
    },
    initEnterprise: function (): Enterprise {
        let enterpriseStr = storeService.get("micro_rc_enterprise");
        if (!enterpriseStr) {
            return null!;
        }
        return JSON.parse(enterpriseStr);
    },

}

const initToken = initFactory.initToken();
const initUser = initFactory.initUser();
const initOrgs = initFactory.initOrgs();
const initProject = initFactory.initProject();
const initEnterprise=initFactory.initEnterprise();
const state: UserState = {
    userInfo: initUser,
    tokenInfo: initToken,
    orgs: initOrgs,
    projectInfo: initProject,
    enterprise: initEnterprise,
    orgId: storeService.get("micro_rc_orgId") || "",
};

export default {
    namespaced: true,
    state,
    getters: {
        getToken: (state: UserState): TokenModel => {
            return state.tokenInfo;
        },
        getUser: (state: UserState): ProfileDto => {
            return state.userInfo;
        },
        getOrgs: (state: UserState): OrgDto[] => {
            return state.orgs;
        },
        getProject: (state: UserState): ProjectDto => {
            return state.projectInfo;
        },
        getOrgId: (): string => {
            return state.orgId;
        },
        getEnterprise: (state: UserState): Enterprise => {
            return state.enterprise;
        },
    },
    mutations: {
        setToken(state: UserState, payload: TokenModel): void {
            state.tokenInfo = payload;
        },
        setUser(state: UserState, payload: ProfileDto): void {
            state.userInfo = payload;
        },
        setOrgs(state: UserState, payload: OrgDto[]): void {
            state.orgs = payload;
        },
        setProject(state: UserState, payload: ProjectDto): void {
            state.projectInfo = payload;
        },
        setOrgId(state: UserState, payload: string): void {
            state.orgId = payload;
        },
        setEnterprise(state: UserState, payload: Enterprise): void {
            state.enterprise = payload;
        },
        clear(state: UserState) {
            state.userInfo = null!;
            state.tokenInfo = null!;
            state.projectInfo = null!;
            state.orgs = null!;
            state.orgId = null!;
            localStorage.clear();
        }
    },
};
