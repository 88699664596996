import { OrgDto } from "@/api/src/org/model/orgDto";
import { ProjectDto } from "@/api/src/org/model/projectDto";
import { ProfileDto } from "@/api/src/userCenter/model/models";

export const SIDEBAR_TYPE = 'sidebar_type'
export const TOGGLE_MOBILE_TYPE = 'is_mobile'
export const TOGGLE_NAV_THEME = 'nav_theme'
export const TOGGLE_LAYOUT = 'layout'
export const TOGGLE_FIXED_HEADER = 'fixed_header'
export const TOGGLE_FIXED_SIDEBAR = 'fixed_sidebar'
export const TOGGLE_CONTENT_WIDTH = 'content_width'
export const TOGGLE_HIDE_HEADER = 'auto_hide_header'
export const TOGGLE_COLOR = 'color'
export const TOGGLE_WEAK = 'weak'
export const TOGGLE_MULTI_TAB = 'multi_tab'
export const APP_LANGUAGE = 'app_language'
export const CONTENT_WIDTH_TYPE = {
    Fluid: 'Fluid',
    Fixed: 'Fixed'
}
export const NAV_THEME = {
    LIGHT: 'light',
    DARK: 'dark'
}

export interface TokenModel {
    [key: string]: any;
    token: string | null | undefined;
}

export type AppState = {
    sideCollapsed: boolean,
    isMobile: boolean,
    theme: string,
    layout: string,
    contentWidth: string,
    fixedHeader: boolean,
    fixedSidebar: boolean,
    autoHideHeader: boolean,
    color: string,
    weak: boolean,
    multiTab: boolean,
    lang: string,
    _antLocale: Record<string, any>
}

export type Enterprise={
    id:string,
    name:string,
    enterpriseSource:number,
    type:number,
    enterpriseType:number,
    memberId:string,
    memberName:string,
}

export type UserState = {
    userInfo: ProfileDto,
    tokenInfo: TokenModel,
    orgs: OrgDto[]
    projectInfo: ProjectDto,
    orgId:string,
    enterprise:Enterprise,
};

export type PermissionState = {
    routers: any[],
    addRouters: any[] | null,
}
