import { ShortLinkDtoRicResponse } from '../model/shortLinkDtoRicResponse';
import { ShortLinkPostInput } from '../model/shortLinkPostInput';
import { Api } from '../../api';
import { HttpOption} from "@/core/http";

/* tslint:disable:no-unused-variable member-ordering */



export class ShortLinkService  extends Api  {
  constructor() {
    super();
  }
    /**
     * 获取短链接内容|getByshortLink
     * 
     * @param shortLink 
     
     **/
    public getByshortLink(
        shortLink:string,
        
        options:HttpOption={},
   ): Promise<ShortLinkDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/short-link/${encodeURIComponent(String(shortLink))}`;
	    path = this.url(path, { shortLink:shortLink });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 进入短链接内容|gotoShortLink
     * 
     * @param shortLink 
     * @param queryParams:{
          * @param isRedirect 
       },
     **/
    public gotoShortLink(
        shortLink:string,
        queryParams?:{
          isRedirect?: boolean,
        },
        options:HttpOption={},
   ): Promise<unknown> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['isRedirect'])) {
                params['isRedirect']=<any>queryParams['isRedirect']
        }
        let path = `/common/api/v5/g/${encodeURIComponent(String(shortLink))}`;
	    path = this.url(path, { shortLink:shortLink });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 创建短链接|post
     * 
     * @param body 
     **/
    public post(
        body?:ShortLinkPostInput,
        options:HttpOption={},
   ): Promise<ShortLinkDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/short-link`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

}

const shortLinkInstance= new ShortLinkService()
export default shortLinkInstance
