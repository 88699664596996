
import { Options, Vue } from "vue-class-component";
import RcUploadDialogTip from "./components/RcUploadDialog/RcFileUploadTip.vue";
import ViewerJS from "@/components/viewer/viewer.vue";
import { microAppEnvConfig } from "@/config/microAppSettings";

@Options({
  components: {
    RcUploadDialogTip,
    ViewerJS,
  },
})
export default class AppIndex extends Vue {
  public get locale() {
    let lang = this.$store.getters["appStore/app_language"];
    let i18n: any = this.$i18n;
    return i18n.getLocaleMessage(lang).antLocale;
  }

  mounted() {
    if (microAppEnvConfig.__MICRO_APP_ENVIRONMENT__) {
      let styles = window.getComputedStyle(this.$el.parentElement);
      this.$el.parentElement.style.padding = "12px 12px 0px 12px";
    }
  }
}
