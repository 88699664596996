import Vuex, { createStore, Store } from 'vuex'
import { StoreHooks } from './hooks'
import modules from "./modules"
import authPersistence from './plugins/authPersistence';
import appPersistence from './plugins/appPersistence';
import { AppState, UserState,PermissionState } from './modules/dto';

export interface State {
  auth: UserState
  app: AppState,
  permission: PermissionState
}
const store: StoreHooks = createStore<State>({
  mutations: {},
  actions: {},
  plugins: [
    authPersistence,
    appPersistence,
  ],
  modules,
});

export default store;
