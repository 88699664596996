
type UploadFileStatus = "error" | "success" | "done" | "uploading" | "removed";

export interface UploadFile {
  [x: string]: any;
  uid: string;
  name?: string;
  status: UploadFileStatus;
  percent?: number;
  thumbUrl?: string;
  url?: string;
}

import { Options, Vue } from "vue-class-component";
import {
  CloseOutlined,
  InboxOutlined,
  MinusSquareOutlined,
  ExpandOutlined,
  PaperClipOutlined,
  CheckCircleOutlined,
  CheckCircleFilled,
  Loading3QuartersOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { Ricent } from "@/core/ricent";
import { Prop } from "vue-property-decorator";
@Options({
  components: {
    CloseOutlined,
    InboxOutlined,
    MinusSquareOutlined,
    ExpandOutlined,
    PaperClipOutlined,
    CheckCircleOutlined,
    CheckCircleFilled,
    Loading3QuartersOutlined,
  },
})
export default class RcUploadDialog extends Vue {
  minimized: boolean = false;
  visible: boolean = false;
  fileList: UploadFile[] = [];

  @Prop()
  groupId!: string;

  mounted() {
    setTimeout(() => {
      document.body.appendChild(this.$el);
      this.$el.style.cssText = "position: absolute;bottom:12px;right:12px;";
      console.log(this.$el);
      let sp = this.$el.querySelector(".ant-upload-btn");
      sp.style.padding = "48px 0px";
    }, 0);
  }

  handleChange(info: any) {
    const status = info.file.status;
    let me = this;
    if (status === "done") {
      setTimeout(() => {
        let f: any = me.fileList.find((_) => _.id == info.file.id);
        f && (f.hide = true);
      }, 1200);
      this.$emit("file:add", info.file);
    } else if (status == "removed") {
      this.$emit("file:remove", info.file);
    } else if (status === "error") {
      message.error(`${info.file.name} 上传失败.`);
    }
  }

  handleDrop(e: DragEvent) {
    console.log(e);
  }

  customRequest(args: any) {
    let me = this;
    setTimeout(() => {
      console.log(me);
      let baseUrl: string = process.env.VUE_APP_SERVER_URL || "";
      let url = baseUrl + "/file/api/v5/files";
      const xhr = new XMLHttpRequest();
      let file: any = me.fileList.find((_) => _.uid == args.file.uid);
      if (!file.id) {
        file.id = Ricent.uuid();
      }
      file.group = this.groupId;
      xhr.upload.addEventListener(
        "progress",
        (e: ProgressEvent) => {
          if (e.lengthComputable) {
            args.onProgress(
              {
                loaded: this.formatSizeUnits(e.loaded),
                total: this.formatSizeUnits(e.total),
                percent: Math.round((e.loaded / e.total) * 100),
              },
              args.file
            );
          }
        },
        false
      );

      xhr.addEventListener(
        "load",
        (e: Event) => {
          const result = JSON.parse(xhr.responseText);
          args.onSuccess(result, args.file);
        },
        false
      );
      xhr.addEventListener(
        "error",
        (e) => {
          args.onError(e, args.file);
          me.fileList = me.fileList.map((_) => _);
        },
        false
      );
      xhr.addEventListener(
        "abort",
        (e) => {
          args.onError(e, args.file);
          me.fileList = me.fileList.map((_) => _);
        },
        false
      );

      let formData = new FormData();
      if (file.id) formData.append("id", file.id);
      formData.append("group", file.group);
      formData.append(file.name, args.file);
      xhr.open("POST", url);
      xhr.send(formData);
    }, 0);
  }

  remove(item: any) {
    let index = this.fileList.findIndex((_) => _ == item);
    this.fileList.splice(index, 1);
    item.status = "removed";
    this.handleChange({
      file: item,
    });
  }

  retry(item: any) {
    item.percent = 0;
    item.status = "uploading";
    this.customRequest({
      file: item.originFileObj,
      onProgress: (data: any, file: any) => {
        item.percent = data.percent;
      },
      onSuccess: (data: any, file: any) => {
        item.percent = 100;
        item.status = "done";
        this.handleChange({
          file: item,
        });
      },
      onError: (data: any, file: any) => {
        item.status = "error";
      },
    });
  }

  formatSizeUnits(bytes: number, dm: number = 1): string {
    if (!bytes || bytes === 0) {
      return "0 B";
    }
    const k = 1000,
      sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  progressStatus(item: any) {
    return item.status == "done"
      ? "success"
      : item.status == "error"
      ? "exception"
      : "active";
  }

  unmounted() {
    try {
      document.body.removeChild(this.$el);
    } catch (ex) {}
  }

  open() {
    this.visible = true;
    this.minimized = false;
  }

  close() {
    this.visible = false;
  }
}
