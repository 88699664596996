import { App } from "vue";
import { apiClient } from "./apis";

export interface ApiClient {

}

const apiPlus = {
    install(app: App) {
        app.config.globalProperties.$api = apiClient;
    }
};


export default apiPlus;

