import Storage from "@/core/storage";


const appPersistence = (store: any) => {
    // 当 store 初始化后调用
    store.subscribe((mutation: { type: string, payload: any }, state: any) => {
        // 每次 mutation 之后调用
        switch (mutation.type) {
            case "appStore/sidebar_type":
                if (mutation.payload) {
                    Storage.set("sidebar_type", state);
                } else {
                    Storage.remove("sidebar_type");
                }
                break;
            case "authStore/nav_theme":
                if (mutation.payload) {
                    Storage.set("nav_theme", state);
                } else {
                    Storage.remove("nav_theme");
                }
                break;
            case "authStore/layout":
                if (mutation.payload) {
                    Storage.set("layout", state);
                } else {
                    Storage.remove("layout");
                }
                break;
            case "authStore/fixed_header":
                if (mutation.payload) {
                    Storage.set("fixed_header", state);
                } else {
                    Storage.remove("fixed_header");
                }
                break;
            case "authStore/fixed_sidebar":
                if (mutation.payload) {
                    Storage.set("fixed_sidebar", state);
                } else {
                    Storage.remove("fixed_sidebar");
                }
                break;
            case "authStore/content_width":
                if (mutation.payload) {
                    Storage.set("content_width", state);
                } else {
                    Storage.remove("content_width");
                }
                break;

            case "authStore/auto_hide_header":
                if (mutation.payload) {
                    Storage.set("auto_hide_header", state);
                } else {
                    Storage.remove("auto_hide_header");
                }
                break;

            case "authStore/color":
                if (mutation.payload) {
                    Storage.set("color", state);
                } else {
                    Storage.remove("color");
                }
                break;

            case "authStore/weak":
                if (mutation.payload) {
                    Storage.set("weak", state);
                } else {
                    Storage.remove("weak");
                }
                break;
            case "authStore/app_language":
                if (mutation.payload) {
                    Storage.set("app_language", state);
                } else {
                    Storage.remove("app_language");
                }
                break;
        }

    })
}

export default appPersistence;