//java -jar cli.jar generate -i http://localhost:9001/docs/v2/api.json -o api -l typescript-angular -t ./ng2
import  cloudLiveInstance,{ CloudLiveService }  from './cloudLive.service';
import  dictionaryInstance,{ DictionaryService }  from './dictionary.service';
import  parametersInstance,{ ParametersService }  from './parameters.service';
import  professionInstance,{ ProfessionService }  from './profession.service';
import  qrCodeInstance,{ QrCodeService }  from './qrCode.service';
import  shortLinkInstance,{ ShortLinkService }  from './shortLink.service';
import { ApiClient } from '../../apiService';

declare module '../../apiService' {
  export interface ApiClient{

    cloudLive:CloudLiveService,

    dictionary:DictionaryService,

    parameters:ParametersService,

    profession:ProfessionService,

    qrCode:QrCodeService,

    shortLink:ShortLinkService,
  }
}


const apiClient={

  cloudLive:cloudLiveInstance,

  dictionary:dictionaryInstance,

  parameters:parametersInstance,

  profession:professionInstance,

  qrCode:qrCodeInstance,

  shortLink:shortLinkInstance,
}
export default apiClient

