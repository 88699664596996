/* eslint-disable no-case-declarations */
import { OrgDto } from "@/api/src/org/model/orgDto";
import { authService } from "@/auth/authService";
import BeaverRouterPlusgenerater from "./beaverRouterPlus";
import szcRouterPlus from "./czcRouterPlus";
import { registerMicroRouter } from "@/router";

interface OrgDtoEx extends OrgDto {
    meuns: any;
}

export const routerPlus = function (org: OrgDtoEx, from?: string) {
    // if (org.meuns) {
    //     return Promise.resolve(org.meuns);
    // }
    let orgs = authService.orgs || [];
    if (orgs && orgs!.length) {
        let czc: any = orgs.find(_ => _.type == 131072);
        if (czc) {
            // czc.meuns = org.meuns;
            org = czc;
        }
    }
    switch (org.type) {
        case 131072:
            let token = authService?.tokenInfo?.token! || "";
            let returnUrl = "";
            if (authService.orgs && authService.orgs!.length) {
                let unCzc = authService.orgs.find(_ => _.type != 131072);
                if (unCzc) {
                    returnUrl = encodeURIComponent(window.location.protocol + "//" + window.location.host + `/organize/${unCzc?.id}/home`);
                }
            }
            let sassOrgs = JSON.stringify(orgs.map(_ => {
                return {
                    id: _.id,
                    type: _.type,
                }
            }));
            const cloudHostMap: any = {
                'cloud.ricent.com': 'https://pg.cloud.ricent.com',
                'uat.cloud.ricent.com': 'http://connect.ricent.com:8893',
            };
            window.location.href = `${cloudHostMap[location.href] || 'https://pg.cloud.ricent.com'}/index.html?token=${token}&returnUrl=${returnUrl}&sassOrgs=${sassOrgs}`
            return new Promise((resove, reject) => {
                registerMicroRouter()
                resove([]);
            })
        default:
            let targetUri = process.env.VUE_APP_PC_V4;
            if (targetUri) {
                let token = authService?.tokenInfo?.token! || "";
                let host = encodeURIComponent(
                    window.location.protocol + "//" + window.location.host
                );
                let path = encodeURIComponent(`/organize/${org.id}/home`);
                window.location.href = `${process.env.VUE_APP_PC_V4}?sassToken=${token}&host=${host}&path=${path}`;
            }
            // window.location.href = `https://pg.cloud.ricent.com/index.html?token=${token}&returnUrl=${returnUrl}`;
            return BeaverRouterPlusgenerater.generator();
    }
}