import { JoinDtoListRicResponse } from '../model/joinDtoListRicResponse';
import { JoinDtoRicResponse } from '../model/joinDtoRicResponse';
import { StringRicResponse } from '../model/stringRicResponse';
import { Api } from '../../api';
import { HttpOption} from "@/core/http";

/* tslint:disable:no-unused-variable member-ordering */



export class JoinService  extends Api  {
  constructor() {
    super();
  }
    /**
     * 获取加入对象的记录|get
     * 
     * @param id 
     
     **/
    public get(
        id:string,
        
        options:HttpOption={},
   ): Promise<JoinDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/joins/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 获取联系方式|getUserPhone
     * 
     * @param id 
     
     **/
    public getUserPhone(
        id:string,
        
        options:HttpOption={},
   ): Promise<StringRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/joins/${encodeURIComponent(String(id))}/user-phone`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 查询加入对象的记录|queryObjectJoinAsync
     * 
     * @param queryParams:{
          * @param joinType 加入类型  1：邀请 2：申请
          * @param objectType 被加入对象类型 Org&#x3D;1 ,Project &#x3D;2 ,ProjectPartner&#x3D;4  加入对象类型 Person&#x3D;1 Org&#x3D;2
          * @param objectId 对象Id
          * @param status 申请审核状态 1:待处理  2:审核通过 4:审核退回
          * @param skip 
          * @param limit 
       },
     **/
    public queryObjectJoinAsync(
        queryParams?:{
          joinType: number,
          objectType: number,
          objectId: string,
          status?: number,
          skip?: number,
          limit?: number,
        },
        options:HttpOption={},
   ): Promise<JoinDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['joinType'])) {
                params['JoinType']=<any>queryParams['joinType']
        }
        if (queryParams&&!this.ce(queryParams['objectType'])) {
                params['ObjectType']=<any>queryParams['objectType']
        }
        if (queryParams&&!this.ce(queryParams['objectId'])) {
                params['ObjectId']=<any>queryParams['objectId']
        }
        if (queryParams&&!this.ce(queryParams['status'])) {
                params['Status']=<any>queryParams['status']
        }
        if (queryParams&&!this.ce(queryParams['skip'])) {
                params['Skip']=<any>queryParams['skip']
        }
        if (queryParams&&!this.ce(queryParams['limit'])) {
                params['Limit']=<any>queryParams['limit']
        }
        let path = `/org/api/v5/joins/object`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 查询被加入对象的记录|queryToObjectJoinAsync
     * 
     * @param queryParams:{
          * @param joinType 加入类型  1：邀请 2：申请
          * @param objectType 被加入对象类型 Org&#x3D;1 ,Project &#x3D;2 ,ProjectPartner&#x3D;4  加入对象类型 Person&#x3D;1 Org&#x3D;2
          * @param objectId 对象Id
          * @param status 申请审核状态 1:待处理  2:审核通过 4:审核退回
          * @param skip 
          * @param limit 
       },
     **/
    public queryToObjectJoinAsync(
        queryParams?:{
          joinType: number,
          objectType: number,
          objectId: string,
          status?: number,
          skip?: number,
          limit?: number,
        },
        options:HttpOption={},
   ): Promise<JoinDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['joinType'])) {
                params['JoinType']=<any>queryParams['joinType']
        }
        if (queryParams&&!this.ce(queryParams['objectType'])) {
                params['ObjectType']=<any>queryParams['objectType']
        }
        if (queryParams&&!this.ce(queryParams['objectId'])) {
                params['ObjectId']=<any>queryParams['objectId']
        }
        if (queryParams&&!this.ce(queryParams['status'])) {
                params['Status']=<any>queryParams['status']
        }
        if (queryParams&&!this.ce(queryParams['skip'])) {
                params['Skip']=<any>queryParams['skip']
        }
        if (queryParams&&!this.ce(queryParams['limit'])) {
                params['Limit']=<any>queryParams['limit']
        }
        let path = `/org/api/v5/joins/to-object`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

}

const joinInstance= new JoinService()
export default joinInstance
