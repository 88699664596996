import { JoinApprovalInput } from '../model/joinApprovalInput';
import { JoinDtoListRicResponse } from '../model/joinDtoListRicResponse';
import { JoinDtoRicResponse } from '../model/joinDtoRicResponse';
import { JoinOrgInput } from '../model/joinOrgInput';
import { OpcodeInput } from '../model/opcodeInput';
import { OrgDeleteInput } from '../model/orgDeleteInput';
import { OrgDtoListRicResponse } from '../model/orgDtoListRicResponse';
import { OrgDtoRicResponse } from '../model/orgDtoRicResponse';
import { OrgInviteByPhoneNumberInput } from '../model/orgInviteByPhoneNumberInput';
import { OrgJoinApprovalByUserInput } from '../model/orgJoinApprovalByUserInput';
import { OrgPatchInput } from '../model/orgPatchInput';
import { OrgPostInput } from '../model/orgPostInput';
import { OrgTransferInput } from '../model/orgTransferInput';
import { RicNullRicResponse } from '../model/ricNullRicResponse';
import { StringListRicResponse } from '../model/stringListRicResponse';
import { Api } from '../../api';
import { HttpOption} from "@/core/http";

/* tslint:disable:no-unused-variable member-ordering */



export class OrgService  extends Api  {
  constructor() {
    super();
  }
    /**
     * 删除组织|delete
     * 
     * @param id 
     * @param body 
     **/
    public _delete(
        id:string,
        body?:OrgDeleteInput,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/orgs/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 组织审批用户加入|approvalJoin
     * 
     * @param orgId 组织Id
     * @param body 
     **/
    public approvalJoin(
        orgId:string,
        body?:JoinApprovalInput,
        options:HttpOption={},
   ): Promise<JoinDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/orgs/${encodeURIComponent(String(orgId))}/joins-approval`;
	    path = this.url(path, { orgId:orgId });
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 用户审核 邀请记录|approvalJoinForUser
     * 
     * @param joinId 记录id
     * @param body 
     **/
    public approvalJoinForUser(
        joinId:string,
        body?:OrgJoinApprovalByUserInput,
        options:HttpOption={},
   ): Promise<JoinDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/orgs/joins/${encodeURIComponent(String(joinId))}/user-approval`;
	    path = this.url(path, { joinId:joinId });
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 删除组织校验|deleteCheck
     * 
     * @param id 
     
     **/
    public deleteCheck(
        id:string,
        
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/orgs/${encodeURIComponent(String(id))}/check`;
	    path = this.url(path, { id:id });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 查询组织列表|get
     * 
     * @param id 
     
     **/
    public get(
        id:string,
        
        options:HttpOption={},
   ): Promise<OrgDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/orgs/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 邀请手机号加入组织|inviteByPhoneNumber
     * 
     * @param body 
     **/
    public inviteByPhoneNumber(
        body?:OrgInviteByPhoneNumberInput,
        options:HttpOption={},
   ): Promise<JoinDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/orgs/invite/phone-number`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 当前用户申请加入组织|joinOrg
     * 
     * @param orgId 
     * @param body 
     **/
    public joinOrg(
        orgId:string,
        body?:JoinOrgInput,
        options:HttpOption={},
   ): Promise<JoinDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/orgs/${encodeURIComponent(String(orgId))}/joins`;
	    path = this.url(path, { orgId:orgId });
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 更新组织内容|patch
     * 
     * @param id 
     * @param body 
     **/
    public patch(
        id:string,
        body?:OrgPatchInput,
        options:HttpOption={},
   ): Promise<OrgDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/orgs/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 创建组织,并附加当前用户至组织中|post
     * 
     * @param body 
     **/
    public post(
        body?:OrgPostInput,
        options:HttpOption={},
   ): Promise<OrgDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/orgs`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 查询组织列表|query
     * 
     * @param queryParams:{
          * @param key 查询
          * @param type 企业类型 1.监理 2.施工方 4.第三方 8.甲方  32:评估 64：设计 128: 物业
          * @param state 数据状态
       },
     **/
    public query(
        queryParams?:{
          key?: string,
          type?: number,
          state?: number,
        },
        options:HttpOption={},
   ): Promise<OrgDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['key'])) {
                params['Key']=<any>queryParams['key']
        }
        if (queryParams&&!this.ce(queryParams['type'])) {
                params['Type']=<any>queryParams['type']
        }
        if (queryParams&&!this.ce(queryParams['state'])) {
                params['State']=<any>queryParams['state']
        }
        let path = `/org/api/v5/orgs`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 查询组织加入记录|queryJoin
     * 
     * @param orgId 组织Id
     * @param queryParams:{
          * @param joinType 加入类型  1：邀请 2：申请
          * @param status 申请审核状态 1:待处理  2:审核通过 4:审核退回
          * @param skip 
          * @param limit 
       },
     **/
    public queryJoin(
        orgId:string,
        queryParams?:{
          joinType: number,
          status?: number,
          skip?: number,
          limit?: number,
        },
        options:HttpOption={},
   ): Promise<JoinDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['joinType'])) {
                params['JoinType']=<any>queryParams['joinType']
        }
        if (queryParams&&!this.ce(queryParams['status'])) {
                params['Status']=<any>queryParams['status']
        }
        if (queryParams&&!this.ce(queryParams['skip'])) {
                params['Skip']=<any>queryParams['skip']
        }
        if (queryParams&&!this.ce(queryParams['limit'])) {
                params['Limit']=<any>queryParams['limit']
        }
        let path = `/org/api/v5/orgs/${encodeURIComponent(String(orgId))}/joins`;
	    path = this.url(path, { orgId:orgId });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 查询我的加入记录|queryMeJoin
     * 
     * @param queryParams:{
          * @param joinType 加入类型  1：邀请 2：申请
          * @param status 申请审核状态 1:待处理  2:审核通过 4:审核退回
          * @param orgId 组织Id
       },
     **/
    public queryMeJoin(
        queryParams?:{
          joinType?: number,
          status?: number,
          orgId?: string,
        },
        options:HttpOption={},
   ): Promise<JoinDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['joinType'])) {
                params['joinType']=<any>queryParams['joinType']
        }
        if (queryParams&&!this.ce(queryParams['status'])) {
                params['status']=<any>queryParams['status']
        }
        if (queryParams&&!this.ce(queryParams['orgId'])) {
                params['orgId']=<any>queryParams['orgId']
        }
        let path = `/org/api/v5/me/joins`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 查询当前用户的组织|queryMeOrg
     * 
     
     **/
    public queryMeOrg(
        
        options:HttpOption={},
   ): Promise<OrgDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/me/orgs`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 查询当前用户的标签|queryMeTags
     * 
     * @param queryParams:{
          * @param orgId 
       },
     **/
    public queryMeTags(
        queryParams?:{
          orgId?: string,
        },
        options:HttpOption={},
   ): Promise<StringListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['orgId'])) {
                params['orgId']=<any>queryParams['orgId']
        }
        let path = `/org/api/v5/me/tags`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 用户退出组织|quitOrg
     * 
     * @param orgId 
     * @param body 
     **/
    public quitOrg(
        orgId:string,
        body?:OpcodeInput,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/orgs/${encodeURIComponent(String(orgId))}/quit`;
	    path = this.url(path, { orgId:orgId });
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 转让组织|transfer
     * 
     * @param id 
     * @param body 
     **/
    public transfer(
        id:string,
        body?:OrgTransferInput,
        options:HttpOption={},
   ): Promise<OrgDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/orgs/${encodeURIComponent(String(id))}/transfer`;
	    path = this.url(path, { id:id });
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

}

const orgInstance= new OrgService()
export default orgInstance
