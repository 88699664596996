import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL;

export interface HttpOption extends AxiosRequestConfig {
    isAnonymous?: boolean
}


const axiosInstance: AxiosInstance = axios.create();

export function Http(config?: HttpOption | undefined) {
    const instance = axios.create(config);
    return instance;
};

export default axiosInstance;