import { CloudLiveDto } from '../model/cloudLiveDto';
import { CloudLiveDtoRicResponse } from '../model/cloudLiveDtoRicResponse';
import { GetCloudLiveOutputListRicResponse } from '../model/getCloudLiveOutputListRicResponse';
import { TencentCloudRecordingBackInput } from '../model/tencentCloudRecordingBackInput';
import { TencentCloudSigRicResponse } from '../model/tencentCloudSigRicResponse';
import { Api } from '../../api';
import { HttpOption} from "@/core/http";

/* tslint:disable:no-unused-variable member-ordering */



export class CloudLiveService  extends Api  {
  constructor() {
    super();
  }
    /**
     * 删除云直播|delete
     * 
     * @param id 
     
     **/
    public _delete(
        id:string,
        
        options:HttpOption={},
   ): Promise<CloudLiveDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/cloud-live/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 腾讯云回调|
     * 
     * @param body 
     **/
    public commonApiV5CloudLiveCloudRecordingBackPost(
        body?:TencentCloudRecordingBackInput,
        options:HttpOption={},
   ): Promise<unknown> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/cloud-live/cloud-recording-back`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 获取或设置直播人|getOrSetLive
     * 
     * @param roomId 
     
     **/
    public getOrSetLive(
        roomId:string,
        
        options:HttpOption={},
   ): Promise<CloudLiveDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/cloud-live/room/${encodeURIComponent(String(roomId))}/live-user`;
	    path = this.url(path, { roomId:roomId });
        return this.request(
            'put',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 添加云直播|post
     * 
     * @param body 
     **/
    public post(
        body?:CloudLiveDto,
        options:HttpOption={},
   ): Promise<CloudLiveDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/cloud-live`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 更新云直播|put
     * 
     * @param id 
     * @param body 
     **/
    public put(
        id:string,
        body?:CloudLiveDto,
        options:HttpOption={},
   ): Promise<CloudLiveDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/cloud-live/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'put',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 获取云直播列表|query
     * 
     * @param queryParams:{
          * @param orgId 组织Id
          * @param businessId 业务ID
          * @param businessType 业务类型
          * @param cloudRoomId 云房间ID
          * @param liveUserId 直播人Id
          * @param creatorUserId 创建人Id
          * @param liveStatus 直播状态 0-未开始 1-直播中 2-已结束 3-已关闭
          * @param isFilterNotStarted 是否过滤未开始
       },
     **/
    public query(
        queryParams?:{
          orgId?: string,
          businessId?: string,
          businessType?: string,
          cloudRoomId?: string,
          liveUserId?: string,
          creatorUserId?: string,
          liveStatus?: number,
          isFilterNotStarted?: boolean,
        },
        options:HttpOption={},
   ): Promise<GetCloudLiveOutputListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['orgId'])) {
                params['OrgId']=<any>queryParams['orgId']
        }
        if (queryParams&&!this.ce(queryParams['businessId'])) {
                params['BusinessId']=<any>queryParams['businessId']
        }
        if (queryParams&&!this.ce(queryParams['businessType'])) {
                params['BusinessType']=<any>queryParams['businessType']
        }
        if (queryParams&&!this.ce(queryParams['cloudRoomId'])) {
                params['CloudRoomId']=<any>queryParams['cloudRoomId']
        }
        if (queryParams&&!this.ce(queryParams['liveUserId'])) {
                params['LiveUserId']=<any>queryParams['liveUserId']
        }
        if (queryParams&&!this.ce(queryParams['creatorUserId'])) {
                params['CreatorUserId']=<any>queryParams['creatorUserId']
        }
        if (queryParams&&!this.ce(queryParams['liveStatus'])) {
                params['LiveStatus']=<any>queryParams['liveStatus']
        }
        if (queryParams&&!this.ce(queryParams['isFilterNotStarted'])) {
                params['IsFilterNotStarted']=<any>queryParams['isFilterNotStarted']
        }
        let path = `/common/api/v5/cloud-live`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 获取用户sig|sig
     * 
     * @param userId 
     * @param sdkAppId 
     
     **/
    public sig(
        userId:string,
        sdkAppId:number,
        
        options:HttpOption={},
   ): Promise<TencentCloudSigRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/common/api/v5/cloud-live/sig/user/${encodeURIComponent(String(userId))}/app/${encodeURIComponent(String(sdkAppId))}`;
	    path = this.url(path, { userId:userId,sdkAppId:sdkAppId });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

}

const cloudLiveInstance= new CloudLiveService()
export default cloudLiveInstance
