import { ReplaySubject, Observable, of, observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { App } from 'vue';
declare var XMLHttpRequest: any;
declare var R: any;

export class LazyLoadService {
  _loadedLibraries: { [url: string]: ReplaySubject<any> } = {};
  _loadedStyle: any = {};

  constructor() { }

  loadStyle(styleName: string) {
    if (!this._loadedStyle[styleName]) {
      this._loadedStyle[styleName] = true;
      const head = document.getElementsByTagName('head')[0];
      const themeLink = document.getElementById(
        styleName
      ) as HTMLLinkElement;
      if (themeLink) {
        themeLink.href = styleName;
      } else {
        const style = document.createElement('link');
        style.id = styleName;
        style.rel = 'stylesheet';
        style.type = 'text/css';
        style.href = `${styleName}`;
        head.appendChild(style);
      }
    }
  }

  loadStyleItem(url: string): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }
    this._loadedLibraries[url] = new ReplaySubject();
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.onload = () => {
      this._loadedLibraries[url].next();
      this._loadedLibraries[url].complete();
    };
    link.href = url;
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(link);
    return this._loadedLibraries[url].asObservable();
  }

  // 增加样式加载支持回调
  loadStyleWithCallback(urls: string | string[]) {
    let u: string[] = [];
    if (typeof urls === 'string') {
      u = [urls];
    } else {
      u = urls;
    }
    let url: string;
    let out: Observable<any>;
    // tslint:disable-next-line:no-conditional-assignment
    while (!!(url = u.shift()!)) {
      const next = this.loadStyleItem(url);
      if (out!) {
        out = out.pipe(switchMap(() => next));
      } else {
        out = next;
      }
    }
    return out!;
  }

  loadScript(url: string): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }
    this._loadedLibraries[url] = new ReplaySubject();

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = () => {
      setTimeout(() => {
        this._loadedLibraries[url].next();
        this._loadedLibraries[url].complete();
      }, 0);
    };
    document.body.appendChild(script);
    return this._loadedLibraries[url].asObservable();
  }
  loadScripts(urls: string[]): Observable<any> {
    let url: string;
    let out: Observable<any>;
    // tslint:disable-next-line:no-conditional-assignment
    while (!!(url = urls.shift()!)) {
      const next = this.loadScript(url);
      if (out!) {
        out = out.pipe(switchMap(() => next));
      } else {
        out = next;
      }
    }
    return out!;
  }
  loadPbf(url: string = '/js/res.pbf'): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }
    this._loadedLibraries[url] = new ReplaySubject();
    return this.loadScripts(['/js/gzip.js', '/js/pbf.js']).pipe(
      switchMap(() => this._loadArraybuffer(url)),
      tap((buf) => {
        const data: any = {};
        const pbf = R.Pbf.pbf(buf);
        pbf.readFields((tag: any, obj: any, _pbf: any) => {
          if (tag === 1) {
            obj.id = _pbf.readVarint();
          } else if (tag === 2) {
            obj.bytes = _pbf.readBytes();
          }
        }, data);
        const js = R.Pbf.buffer(R.Gzip.unzip(data.bytes, null)).toString(
          'UTF-8'
        );
        window.eval(js);
        this._loadedLibraries[url].next();
        this._loadedLibraries[url].complete();
      }),
      switchMap(() => this._loadedLibraries[url].asObservable())
    );
  }
  _loadArraybuffer(uri: string) {
    return new Observable((observe) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', uri, true);
      xhr.responseType = 'arraybuffer';
      xhr.onload = () => {
        observe.next(new Uint8Array(xhr.response));
        observe.complete();
      };
      xhr.onerror = (ex: any) => observe.error(ex);
      xhr.send();
    });
  }
  loadJquery(): Observable<any> {
    return this.loadScript('/js/jquery-3.1.1.min.js');
  }
}

export const lazyLoad = new LazyLoadService();

const lazyLoadPlus = {
  install(app: App) {
    app.config.globalProperties.$lazy = lazyLoad;
  }
};

export default lazyLoadPlus;