import antd from 'ant-design-vue/es/locale-provider/zh_CN'


const antdUs: any = antd;

const components = {
  antLocale: antdUs,
  momentName: 'zh-cn',
}

export default {
  message: '-',
  'menu.account.logout':'安全退出',
  'layouts.usermenu.dialog.title': '信息',
  'layouts.usermenu.dialog.content': '您确定要注销吗？',
  'layouts.userLayout.title': 'Ant Design 是西湖区最具影响力的 Web 设计规范',
  ...components,

}
