import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f89577c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slider-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, { id: "project-detail" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_sider, { class: "sider" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, _toDisplayString($setup.projectName), 1)
          ]),
          _createVNode(_component_a_menu, {
            selectedKeys: $setup.selectedKeys,
            onSelect: $setup.handleSelect
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menuList, (item) => {
                return (_openBlock(), _createBlock(_component_a_menu_item, {
                  key: item.id
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["selectedKeys", "onSelect"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_layout_content, { class: "content" }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent($setup.currentCmp)))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}