import { PartnerDtoListRicResponse } from '../model/partnerDtoListRicResponse';
import { PartnerDtoRicResponse } from '../model/partnerDtoRicResponse';
import { PartnerPatchInput } from '../model/partnerPatchInput';
import { PartnerPostInput } from '../model/partnerPostInput';
import { RicNullRicResponse } from '../model/ricNullRicResponse';
import { Api } from '../../api';
import { HttpOption} from "@/core/http";

/* tslint:disable:no-unused-variable member-ordering */



export class PartnerService  extends Api  {
  constructor() {
    super();
  }
    /**
     * 删除数据|delete
     * 
     * @param id 
     
     **/
    public _delete(
        id:string,
        
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/partners/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 获取合作伙伴|get
     * 
     * @param id 
     
     **/
    public get(
        id:string,
        
        options:HttpOption={},
   ): Promise<PartnerDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/partners/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 修改合作伙伴|patch
     * 
     * @param id 
     * @param body 
     **/
    public patch(
        id:string,
        body?:PartnerPatchInput,
        options:HttpOption={},
   ): Promise<PartnerDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/partners/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 创建合作伙伴|post
     * 
     * @param body 
     **/
    public post(
        body?:PartnerPostInput,
        options:HttpOption={},
   ): Promise<PartnerDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/partners`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 分页查询合作伙伴列表|query
     * 
     * @param queryParams:{
          * @param key 关键字
          * @param type 类型
          * @param skip 跳过数量
          * @param limit 限制数量
          * @param fields Members
          * @param regionId 区域Id
       },
     **/
    public query(
        queryParams?:{
          key?: string,
          type?: number,
          skip?: number,
          limit?: number,
          fields?: string,
          regionId?: string,
        },
        options:HttpOption={},
   ): Promise<PartnerDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['key'])) {
                params['Key']=<any>queryParams['key']
        }
        if (queryParams&&!this.ce(queryParams['type'])) {
                params['Type']=<any>queryParams['type']
        }
        if (queryParams&&!this.ce(queryParams['skip'])) {
                params['Skip']=<any>queryParams['skip']
        }
        if (queryParams&&!this.ce(queryParams['limit'])) {
                params['Limit']=<any>queryParams['limit']
        }
        if (queryParams&&!this.ce(queryParams['fields'])) {
                params['Fields']=<any>queryParams['fields']
        }
        if (queryParams&&!this.ce(queryParams['regionId'])) {
                params['RegionId']=<any>queryParams['regionId']
        }
        let path = `/org/api/v5/partners`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

}

const partnerInstance= new PartnerService()
export default partnerInstance
