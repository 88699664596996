
import { lazyLoad } from "@/core/lazyload";
import { Ricent } from "@/core/ricent";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
declare var Viewer: any;
declare var $: any;
declare var window: any;
import { DeleteOutlined, CloudDownloadOutlined } from "@ant-design/icons-vue";
import emptyBase64 from "./empty";
import { ricentBus } from "@/core/bus";
export type ViewerFile = {
  id?: string;
  name?: string;
  group?: string;
  uniqueId?: string;
  creationTime?: Date;
  creationUserName?: string;
  size?: number;
  description?: string;
  displayName?: string;
  thumbUrl?: string;
  url?: string;
  alt?: string;
};
@Options({
  components: {
    DeleteOutlined,
    CloudDownloadOutlined,
  },
})
export default class ViewerJS extends Vue {
  files: ViewerFile[] = [];
  spacer!: number;

  getAlt(it: ViewerFile) {
    return it.alt || it.name;
  }

  listens: any = [];

  mounted() {
    this.listens = [
      ricentBus.on(
        "viewjs",
        (args) => {
          this.files = args.files;
          this.view(args.file, args.remove);
        },
        this
      ),
    ];
  }

  unmounted() {
    this.listens.forEach((_: any) => _());
  }

  view(file: ViewerFile, removeCall?: any) {
    lazyLoad
      .loadScripts(["/js/jquery-3.1.1.min.js", "/viewerjs/viewer.min.js"])
      .subscribe((_) => {
        let defaultIdx = 0;
        if (file) {
          defaultIdx = this?.files.findIndex((_) => _ == file) || 0;
        }
        let me = this;
        let viewer = new Viewer(this.$refs.imgs, {
          url: (img: any) => {
            return emptyBase64;
          },
          toolbar: {
            zoomIn: 1,
            zoomOut: 1,
            oneToOne: 0,
            reset: 0,
            prev: 1,
            next: 1,
            rotateLeft: 1,
            rotateRight: 1,
            flipHorizontal: 0,
            flipVertical: 0,
          },
          button: true,
          navbar: false,
          /**CSS及JS已修改源码 */
          hide: function () {
            viewer.destroy();
          },
          initialViewIndex: defaultIdx,
          build: function () {},
          view: function (args: any) {
            let uniqueid = args.detail.originalImage.dataset.uniqueid;
            let format = "png";
            args.detail.image.src = Ricent.thumb(uniqueid, null, format);
          },
          ready: function () {},
        });
        viewer.show();
        setTimeout(() => {
          let rm: any = me.$refs.remove;
          let load: any = me.$refs.download;
          let toolbar = viewer.viewer.querySelector(".viewer-toolbar ul");
          load.onclick = function () {
            let index = viewer.index;
            let file: any = me.files.find((data, i) => {
              return i == index;
            });
            if (file) {
              const xhr = new XMLHttpRequest();
              let uri = Ricent.thumb(file.uniqueId || "");
              xhr.open("GET", uri, true);
              xhr.responseType = "arraybuffer";
              xhr.onload = () => {
                let blob = new Blob([new Uint8Array(xhr.response)]);
                let url = window.URL.createObjectURL(blob);
                var element = document.createElement("a");
                element.setAttribute("href", url);
                element.setAttribute("download", file.name || "");
                element.click();
              };
              xhr.onerror = () => {};
              xhr.send();
            }
          };
          rm.onclick = function () {
            let index = viewer.index;
            let file = me.files.find((data, i) => {
              return i == index;
            });
            if (file) {
              if (removeCall) {
                removeCall(file);
              }
              // me.$emit("file:remove", file);
              viewer.hide();
            }
          };
          toolbar?.appendChild(rm);
          toolbar?.appendChild(load);
        }, 50);
      });
  }
}
