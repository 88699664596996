import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a92f754"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  src: "/img/home-bg.jpg"
}
const _hoisted_2 = {
  key: 1,
  src: "/img/home-bg-czc.png"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.org && _ctx.org.type != 131072)
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.org && _ctx.org.type == 131072)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true)
  ], 64))
}