<template>
   <router-view />
</template>

<script>

export default {
  name: 'BlackLayout'
}
</script>

<style scoped>

</style>
