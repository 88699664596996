import { App } from "vue";
import store from "@/store";
import { Ricent } from "@/core/ricent";
import { RouteRecordRaw } from "vue-router";
import { asyncRouterMap } from "@/config/routerSettings"
import { ricentBus } from "@/core/bus";
import { registerMicroRouter } from "@/router";
import { OGRCHANGE } from "@/core/globalEvent";
//动态切换路由
ricentBus.on(OGRCHANGE, (org: any) => {
    registerMicroRouter(org.meuns)
    permissionService.generateMenus();
}, "globle");



export class Permission {

    public get enableRouters() {
        return store.getters["permissionStore/getAddRouters"];
    }

    dynamicRouter(routers: RouteRecordRaw[]): RouteRecordRaw[] {
        return routers.filter(_router => {
            if (_router.children) {
                _router.children = this.dynamicRouter(_router.children);
            }
            return _router;
        });
    }


    generateMenus(routers?: RouteRecordRaw[]) {
        if (!routers) routers = asyncRouterMap;
        return new Promise(resolve => {
            let cp = Ricent.copy(routers, true);
            cp = this.dynamicRouter(cp);
            store.commit("permissionStore/SET_ROUTERS", cp);
            resolve(cp);
        })
    }
}

export const permissionService = new Permission();

const permission = {
    install(app: App) {
        app.config.globalProperties.$permission = permissionService;
    }
};

export default permission;
