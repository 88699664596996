//java -jar cli.jar generate -i http://localhost:9001/docs/v2/api.json -o api -l typescript-angular -t ./ng2
import  authInstance,{ AuthService }  from './auth.service';
import { ApiClient } from '../../apiService';

declare module '../../apiService' {
  export interface ApiClient{

    auth:AuthService,
  }
}


const apiClient={

  auth:authInstance,
}
export default apiClient

