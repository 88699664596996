import { JoinApprovalInput } from '../model/joinApprovalInput';
import { JoinDtoListRicResponse } from '../model/joinDtoListRicResponse';
import { JoinDtoRicResponse } from '../model/joinDtoRicResponse';
import { PartnerDtoRicResponse } from '../model/partnerDtoRicResponse';
import { ProjectDeleteInput } from '../model/projectDeleteInput';
import { ProjectDtoListRicResponse } from '../model/projectDtoListRicResponse';
import { ProjectDtoRicResponse } from '../model/projectDtoRicResponse';
import { ProjectPatchInput } from '../model/projectPatchInput';
import { ProjectPostInput } from '../model/projectPostInput';
import { RegionDtoListRicResponse } from '../model/regionDtoListRicResponse';
import { RegionDtoRicResponse } from '../model/regionDtoRicResponse';
import { RegionJoinApprovalInput } from '../model/regionJoinApprovalInput';
import { RegionJoinInput } from '../model/regionJoinInput';
import { RegionMemberCheckDtoRicResponse } from '../model/regionMemberCheckDtoRicResponse';
import { RegionPartnerDtoListRicResponse } from '../model/regionPartnerDtoListRicResponse';
import { RegionPartnerDtoRicResponse } from '../model/regionPartnerDtoRicResponse';
import { RegionPartnerMemberDeleteInput } from '../model/regionPartnerMemberDeleteInput';
import { RegionPartnerMemberInviteInput } from '../model/regionPartnerMemberInviteInput';
import { RegionPartnerMemberPatchInput } from '../model/regionPartnerMemberPatchInput';
import { RegionPartnerMemberQueryDtoRicResponse } from '../model/regionPartnerMemberQueryDtoRicResponse';
import { RegionPartnerMemberRolePatchInput } from '../model/regionPartnerMemberRolePatchInput';
import { RegionPartnerPatchInput } from '../model/regionPartnerPatchInput';
import { RegionPartnerPostInput } from '../model/regionPartnerPostInput';
import { RegionPatchInput } from '../model/regionPatchInput';
import { RegionPostInput } from '../model/regionPostInput';
import { RegionPutInput } from '../model/regionPutInput';
import { RegionQueryByIdsInput } from '../model/regionQueryByIdsInput';
import { RegionTreeDto } from '../model/regionTreeDto';
import { RegionTreeDtoListRicResponse } from '../model/regionTreeDtoListRicResponse';
import { RicNullRicResponse } from '../model/ricNullRicResponse';
import { StringListRicResponse } from '../model/stringListRicResponse';
import { Api } from '../../api';
import { HttpOption} from "@/core/http";

/* tslint:disable:no-unused-variable member-ordering */



export class RegionService  extends Api  {
  constructor() {
    super();
  }
    /**
     * 删除区域Id|delete
     * 
     * @param id 
     
     **/
    public _delete(
        id:string,
        
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 管理员审批用户申请记录|approvalJoin
     * 
     * @param id 项目id
     * @param body 
     **/
    public approvalJoin(
        id:string,
        body?:JoinApprovalInput,
        options:HttpOption={},
   ): Promise<JoinDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}/joins-approval`;
	    path = this.url(path, { id:id });
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 区域管理员审核单个用户d的申请记录，并支持修改合作伙伴|approvalSingleJoin
     * 
     * @param id 项目id
     * @param joinId 记录id
     * @param body 
     **/
    public approvalSingleJoin(
        id:string,
        joinId:string,
        body?:RegionJoinApprovalInput,
        options:HttpOption={},
   ): Promise<JoinDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}/joins/${encodeURIComponent(String(joinId))}/approval`;
	    path = this.url(path, { id:id,joinId:joinId });
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 批量重置某区域下所有子区域|batchs
     * 
     * @param id 区域Id
     * @param queryParams:{
          * @param regionType 需要重置的区域类型
       },* @param body 需要重置的区域数据对象集合
     **/
    public batchs(
        id:string,
        queryParams?:{
          regionType?: number,
        },body?:Array<RegionTreeDto>,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['regionType'])) {
                params['regionType']=<any>queryParams['regionType']
        }
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}/batchs`;
	    path = this.url(path, { id:id });
        return this.request(
            'put',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 当前用户加入项目区域|currentUserJoin
     * 
     * @param id 区域Id
     * @param body 当前用户加入项目区域入参
     **/
    public currentUserJoin(
        id:string,
        body?:RegionJoinInput,
        options:HttpOption={},
   ): Promise<JoinDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}/joins`;
	    path = this.url(path, { id:id });
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 删除项目|deleteProject
     * 
     * @param id 
     * @param body 
     **/
    public deleteProject(
        id:string,
        body?:ProjectDeleteInput,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/projects/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 删除项目校验|deleteProjectCheck
     * 
     * @param id 
     
     **/
    public deleteProjectCheck(
        id:string,
        
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/projects/${encodeURIComponent(String(id))}/check`;
	    path = this.url(path, { id:id });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 删除项目下的合作伙伴|deleteRegionPartner
     * 
     * @param id 区域Id
     * @param regionPartnerId 区域合作单位关系Id
     
     **/
    public deleteRegionPartner(
        id:string,
        regionPartnerId:string,
        
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}/partners/${encodeURIComponent(String(regionPartnerId))}`;
	    path = this.url(path, { id:id,regionPartnerId:regionPartnerId });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 删除区域下合作伙伴用户|deleteRegionPartnerMember
     * 
     * @param id 区域Id
     * @param regionPartnerId 区域合作伙伴关系Id
     * @param body 
     **/
    public deleteRegionPartnerMember(
        id:string,
        regionPartnerId:string,
        body?:RegionPartnerMemberDeleteInput,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}/partners/${encodeURIComponent(String(regionPartnerId))}/members`;
	    path = this.url(path, { id:id,regionPartnerId:regionPartnerId });
        return this.request(
            'delete',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 根据Id获取区域|get
     * 
     * @param id 
     
     **/
    public get(
        id:string,
        
        options:HttpOption={},
   ): Promise<RegionDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 根据id获取项目|getProject
     * 
     * @param id 
     
     **/
    public getProject(
        id:string,
        
        options:HttpOption={},
   ): Promise<ProjectDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/projects/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 邀请区域合作伙伴人员|inviteRegionPartnerMember
     * 
     * @param id 
     * @param regionPartnerId 
     * @param body 
     **/
    public inviteRegionPartnerMember(
        id:string,
        regionPartnerId:string,
        body?:RegionPartnerMemberInviteInput,
        options:HttpOption={},
   ): Promise<JoinDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}/partners/${encodeURIComponent(String(regionPartnerId))}/joins-member`;
	    path = this.url(path, { id:id,regionPartnerId:regionPartnerId });
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 局部修改 区域信息|patch
     * 
     * @param id 
     * @param body 
     **/
    public patch(
        id:string,
        body?:RegionPatchInput,
        options:HttpOption={},
   ): Promise<RegionDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 修改项目|patchProject
     * 
     * @param id 
     * @param body 
     **/
    public patchProject(
        id:string,
        body?:ProjectPatchInput,
        options:HttpOption={},
   ): Promise<ProjectDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/projects/${encodeURIComponent(String(id))}`;
	    path = this.url(path, { id:id });
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 编辑项目下的合作伙伴|patchRegionPartner
     * 
     * @param id 区域Id
     * @param regionPartnerId 区域合作单位关系Id
     * @param body 合作单位实体局部修改入参
     **/
    public patchRegionPartner(
        id:string,
        regionPartnerId:string,
        body?:RegionPartnerPatchInput,
        options:HttpOption={},
   ): Promise<PartnerDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}/partners/${encodeURIComponent(String(regionPartnerId))}`;
	    path = this.url(path, { id:id,regionPartnerId:regionPartnerId });
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 修改区域下合作伙伴用户|patchRegionPartnerMember
     * 
     * @param id 区域Id
     * @param regionPartnerId 区域合作伙伴关系Id
     * @param memberId 人员Id
     * @param body 
     **/
    public patchRegionPartnerMember(
        id:string,
        regionPartnerId:string,
        memberId:string,
        body?:RegionPartnerMemberPatchInput,
        options:HttpOption={},
   ): Promise<RegionPartnerMemberQueryDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}/partners/${encodeURIComponent(String(regionPartnerId))}/members/${encodeURIComponent(String(memberId))}`;
	    path = this.url(path, { id:id,regionPartnerId:regionPartnerId,memberId:memberId });
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 批量修改人员权限|patchRegionPartnerMemberRole
     * 
     * @param id 区域Id
     * @param regionPartnerId 区域合作伙伴关系Id
     * @param body 
     **/
    public patchRegionPartnerMemberRole(
        id:string,
        regionPartnerId:string,
        body?:RegionPartnerMemberRolePatchInput,
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}/partners/${encodeURIComponent(String(regionPartnerId))}/members/role`;
	    path = this.url(path, { id:id,regionPartnerId:regionPartnerId });
        return this.request(
            'patch',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 新增区域|post
     * 
     * @param body 
     **/
    public post(
        body?:RegionPostInput,
        options:HttpOption={},
   ): Promise<RegionDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 创建项目|postProject
     * 
     * @param body 
     **/
    public postProject(
        body?:ProjectPostInput,
        options:HttpOption={},
   ): Promise<ProjectDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/projects`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 查询当前用户相关的项目列表|postRegionPartner
     * 
     * @param id 区域Id
     * @param body 合作单位实体新增入参
     **/
    public postRegionPartner(
        id:string,
        body?:RegionPartnerPostInput,
        options:HttpOption={},
   ): Promise<RegionPartnerDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}/partners`;
	    path = this.url(path, { id:id });
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 全局覆盖区域  Id为null或者查询不到数据 则做新增 否则做修改|put
     * 
     * @param body 
     **/
    public put(
        body?:RegionPutInput,
        options:HttpOption={},
   ): Promise<RegionDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions`;
	    path = this.url(path);
        return this.request(
            'put',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 查询区域列表|query
     * 
     * @param queryParams:{
          * @param parentId 上级区域Id
          * @param regionType 区域类型，1:项目 2:分期 4:楼栋 8:楼层 16：房间
          * @param key 关键字
          * @param status 状态，1:未动工 2:在建 4:已交付
          * @param isGetChild 是否获取子集
       },
     **/
    public query(
        queryParams?:{
          parentId?: string,
          regionType?: number,
          key?: string,
          status?: number,
          isGetChild?: boolean,
        },
        options:HttpOption={},
   ): Promise<RegionTreeDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['parentId'])) {
                params['ParentId']=<any>queryParams['parentId']
        }
        if (queryParams&&!this.ce(queryParams['regionType'])) {
                params['RegionType']=<any>queryParams['regionType']
        }
        if (queryParams&&!this.ce(queryParams['key'])) {
                params['Key']=<any>queryParams['key']
        }
        if (queryParams&&!this.ce(queryParams['status'])) {
                params['Status']=<any>queryParams['status']
        }
        if (queryParams&&!this.ce(queryParams['isGetChild'])) {
                params['IsGetChild']=<any>queryParams['isGetChild']
        }
        let path = `/org/api/v5/regions`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 查询项目加入记录|queryJoin
     * 
     * @param id 区域Id
     * @param queryParams:{
          * @param joinType 加入类型  1：邀请 2：申请
          * @param status 申请审核状态 1:待处理  2:审核通过 4:审核退回
          * @param regionPartnerId 区域合作关系Id
          * @param skip 
          * @param limit 
       },
     **/
    public queryJoin(
        id:string,
        queryParams?:{
          joinType: number,
          status?: number,
          regionPartnerId?: string,
          skip?: number,
          limit?: number,
        },
        options:HttpOption={},
   ): Promise<JoinDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['joinType'])) {
                params['JoinType']=<any>queryParams['joinType']
        }
        if (queryParams&&!this.ce(queryParams['status'])) {
                params['Status']=<any>queryParams['status']
        }
        if (queryParams&&!this.ce(queryParams['regionPartnerId'])) {
                params['RegionPartnerId']=<any>queryParams['regionPartnerId']
        }
        if (queryParams&&!this.ce(queryParams['skip'])) {
                params['Skip']=<any>queryParams['skip']
        }
        if (queryParams&&!this.ce(queryParams['limit'])) {
                params['Limit']=<any>queryParams['limit']
        }
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}/joins`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 查询当前用户相关的项目列表|queryMeProject
     * 
     * @param queryParams:{
          * @param key 关键字查询
          * @param parentId 父级Id
          * @param isFocus 是否重点关注项目
          * @param status 项目状态 1：未动工 2：进行中 4：已完工
          * @param skip 跳过数量
          * @param limit 取值数量
          * @param isQueryAll 是否查询全部
          * @param fields Member 人员
       },
     **/
    public queryMeProject(
        queryParams?:{
          key?: string,
          parentId?: string,
          isFocus?: boolean,
          status?: number,
          skip?: number,
          limit?: number,
          isQueryAll?: boolean,
          fields?: string,
        },
        options:HttpOption={},
   ): Promise<ProjectDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['key'])) {
                params['Key']=<any>queryParams['key']
        }
        if (queryParams&&!this.ce(queryParams['parentId'])) {
                params['ParentId']=<any>queryParams['parentId']
        }
        if (queryParams&&!this.ce(queryParams['isFocus'])) {
                params['IsFocus']=<any>queryParams['isFocus']
        }
        if (queryParams&&!this.ce(queryParams['status'])) {
                params['Status']=<any>queryParams['status']
        }
        if (queryParams&&!this.ce(queryParams['skip'])) {
                params['Skip']=<any>queryParams['skip']
        }
        if (queryParams&&!this.ce(queryParams['limit'])) {
                params['Limit']=<any>queryParams['limit']
        }
        if (queryParams&&!this.ce(queryParams['isQueryAll'])) {
                params['IsQueryAll']=<any>queryParams['isQueryAll']
        }
        if (queryParams&&!this.ce(queryParams['fields'])) {
                params['Fields']=<any>queryParams['fields']
        }
        let path = `/org/api/v5/me/projects`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 查询当前用户相关区域Id,可传入筛选范围区域 以及层级等等|queryMeRelationId
     * 
     * @param body 
     **/
    public queryMeRelationId(
        body?:RegionQueryByIdsInput,
        options:HttpOption={},
   ): Promise<StringListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/me-relation-ids`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 查询项目列表|queryProject
     * 
     * @param queryParams:{
          * @param key 关键字查询
          * @param parentId 父级Id
          * @param isFocus 是否重点关注项目
          * @param status 项目状态 1：未动工 2：进行中 4：已完工
          * @param skip 跳过数量
          * @param limit 取值数量
          * @param isQueryAll 是否查询全部
          * @param fields Member 人员
       },
     **/
    public queryProject(
        queryParams?:{
          key?: string,
          parentId?: string,
          isFocus?: boolean,
          status?: number,
          skip?: number,
          limit?: number,
          isQueryAll?: boolean,
          fields?: string,
        },
        options:HttpOption={},
   ): Promise<ProjectDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['key'])) {
                params['Key']=<any>queryParams['key']
        }
        if (queryParams&&!this.ce(queryParams['parentId'])) {
                params['ParentId']=<any>queryParams['parentId']
        }
        if (queryParams&&!this.ce(queryParams['isFocus'])) {
                params['IsFocus']=<any>queryParams['isFocus']
        }
        if (queryParams&&!this.ce(queryParams['status'])) {
                params['Status']=<any>queryParams['status']
        }
        if (queryParams&&!this.ce(queryParams['skip'])) {
                params['Skip']=<any>queryParams['skip']
        }
        if (queryParams&&!this.ce(queryParams['limit'])) {
                params['Limit']=<any>queryParams['limit']
        }
        if (queryParams&&!this.ce(queryParams['isQueryAll'])) {
                params['IsQueryAll']=<any>queryParams['isQueryAll']
        }
        if (queryParams&&!this.ce(queryParams['fields'])) {
                params['Fields']=<any>queryParams['fields']
        }
        let path = `/org/api/v5/projects`;
	    path = this.url(path);
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 根据Id集合查询区域集合|queryRegionByIds
     * 
     * @param body 
     **/
    public queryRegionByIds(
        body?:RegionQueryByIdsInput,
        options:HttpOption={},
   ): Promise<RegionDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/ids/query`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 查询当前用户相关的项目列表|queryRegionPartner
     * 
     * @param id 区域Id
     
     **/
    public queryRegionPartner(
        id:string,
        
        options:HttpOption={},
   ): Promise<RegionPartnerDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}/partners`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 根据Id集合查询区域集合|queryRelationIdByIds
     * 
     * @param body 
     **/
    public queryRelationIdByIds(
        body?:RegionQueryByIdsInput,
        options:HttpOption={},
   ): Promise<StringListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/ids/relation-ids`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 根据Id集合查询区域集合|queryRelationRegionByIds
     * 
     * @param body 
     **/
    public queryRelationRegionByIds(
        body?:RegionQueryByIdsInput,
        options:HttpOption={},
   ): Promise<RegionDtoListRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/ids/relations`;
	    path = this.url(path);
        return this.request(
            'post',
            path,
           Object.assign(options, {
                data: body,
                headers,
                params
           }),
        );
    }

    /**
     * 退出当前项目|quitRegion
     * 
     * @param id 
     * @param queryParams:{
          * @param regionPartnerId 
       },
     **/
    public quitRegion(
        id:string,
        queryParams?:{
          regionPartnerId?: string,
        },
        options:HttpOption={},
   ): Promise<RicNullRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        if (queryParams&&!this.ce(queryParams['regionPartnerId'])) {
                params['regionPartnerId']=<any>queryParams['regionPartnerId']
        }
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}/quit`;
	    path = this.url(path, { id:id });
        return this.request(
            'post',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

    /**
     * 检查当前用户在项目的关系|rcUserRegionCheck
     * 
     * @param id 
     
     **/
    public rcUserRegionCheck(
        id:string,
        
        options:HttpOption={},
   ): Promise<RegionMemberCheckDtoRicResponse> {
        const params:Record<string,any> = {};
        const headers:Record<string,any> = {};
        let path = `/org/api/v5/regions/${encodeURIComponent(String(id))}/rcuser-check`;
	    path = this.url(path, { id:id });
        return this.request(
            'get',
            path,
           Object.assign(options, {
                headers,
                params
           }),
        );
    }

}

const regionInstance= new RegionService()
export default regionInstance
